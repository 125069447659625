import React, { useContext, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import UserContext from "../../../context/UserContext";
import { DataTable } from "mha-ui-kit-react-library/dist/index";
import "mha-ui-kit-react-library/dist/styles.css";
import StatusModal from "../../../StatusModal";
import useStatusModal from "../../../../hooks/useStatusModal";
import RebateStatusForm from "./RebateStatusForm";
import RebateCheckForm from "./RebateCheckForm";
import StatusDetailsModal from "./StatusDetailsModal";
import { saveAs } from 'file-saver';
import { parse } from "date-fns/fp";
import { Object } from "core-js";

const RebateReport = (props) => {
  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;

  const locId = currentUser.Location.LOCID;
  const locType = currentUser.Location.Type;
  const shortType = locType === "ParentCompany" ? "PC" : locType[0];
  const locationName = currentUser.Location.Name;

  const {
    statusProps,
    handleErrorStatus,
    handleAlertStatus,
  } = useStatusModal();

    const [isReportLoaded, setIsReportLoaded] = useState(false);
    const [hasBeenFormatted, setHasBeenFormatted] = useState(false);
    const [formattedData, setFormattedData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [reportDataExport, setReportDataExport] = useState([]);
  const [columns, setColumns] = useState(null);
  const [exportcolumns, setColumnsExport] = useState(null);
  const [subcolumns, setSubColumns] = useState(null);
  const [detailcolumns, setDetailColumns] = useState(null);
  const [detailcolumnsexport, setDetailColumnsExport] = useState(null);
  const [detailsubcolumns, setSubDetailColumns] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalUri, setModalUri] = useState(null);
  const [modalUriExport, setModalUriExport] = useState(null);
  const [isDataAvailable, setDataAvailable] = useState(false);
  const { reportType, setLoading } = props;

  const statusColumns = [
    {
      title: "Action",
      accessor: "Action",
    },
    {
      title: "Quarter",
      accessor: "Quarter",
    },
    {
      title: "Sales",
      accessor: "Sales",
      showTotal: true,
      type: "money",
    },
    {
      title: "Rebate",
      accessor: "Amount",
      showTotal: true,
      type: "money",
    },
  ];

  const subStatusColumns = [
    {
      title: "Quarter",
      accessor: "Quarter",
    },
    {
      title: "Business Name",
      accessor: "Name",
    },
    {
      title: "DEA",
      accessor: "DEA",
   },
    {
      title: "Vendor",
      accessor: "Vendor",
    },
    {
      title: "Product",
      accessor: "Product",
    },
    {
      title: "Sales",
      accessor: "Sales",
      showTotal: true,
      type: "money",
    },
    {
      title: "Rebate",
      accessor: "Amount",
      showTotal: true,
      type: "money",
    },
    {
      title: "Check Number",
      accessor: "CheckNumber",
    },
    {
      title: "Check Date",
      accessor: "CheckDate",
      transform: (value) => {
        return value == null
          ? null
          : new Date(Date.parse(value)).toString("MMM dd, yyyy");
      },
    },
  ];

  const subStatusColumnsUnpaid = [
    {
      title: "Quarter",
      accessor: "Quarter",
    },
    //{
    //    title: "DEA",
    //    accessor: "DEA",
    //},
    {
      title: "Business Name",
      accessor: "Name",
    },
    {
      title: "Vendor",
      accessor: "Vendor",
    },
    {
      title: "Product",
      accessor: "Product",
    },
    {
      title: "Sales",
      accessor: "Sales",
      type: "money",
      showTotal: true,
    },
    {
      title: "Rebate",
      accessor: "Amount",
      type: "money",
      showTotal: true,
    },
  ];

  const statusColumnsExport = [
    {
      title: "Quarter",
      accessor: "Quarter",
    },
    {
      title: "Business Name",
      accessor: "Name",
    },
    {
      title: "Vendor",
      accessor: "Vendor",
    },
    {
      title: "Product",
      accessor: "Product",
    },
    {
      title: "Sales",
      accessor: "Sales",
      type: "money",
    },
    {
      title: "Rebate",
      accessor: "Amount",
      type: "money",
    },
    {
      title: "Check Number",
      accessor: "CheckNumber",
    },
    {
      title: "Check Date",
      accessor: "CheckDate",
    },
  ];

  const statusColumnsUnpaidExport = [
    {
      title: "Quarter",
      accessor: "Quarter",
    },
    {
      title: "Business Name",
      accessor: "Name",
    },
    {
      title: "Vendor",
      accessor: "Vendor",
    },
    {
      title: "Product",
      accessor: "Product",
    },
    {
      title: "Sales",
      accessor: "Sales",
      type: "money",
    },
    {
      title: "Rebate",
      accessor: "Amount",
      type: "money",
    },
  ];

  const statusDetailColumns = [
    {
      title: "Action",
      accessor: "Action",
    },
    {
      title: "Quarter",
      accessor: "Quarter",
    },
    {
      title: "DEA",
      accessor: "DEA",
    },
    {
      title: "Sales",
      accessor: "Sales",
      showTotal: true,
      type: "money",
    },
    {
      title: "Rebate",
      accessor: "Amount",
      showTotal: true,
      type: "money",
    },
  ];

  const subStatusDetailColumns = [
    {
      title: "DEA",
      accessor: "DEA",
    },
    {
      title: "Business Name",
      accessor: "Name",
    },
    {
      title: "City",
      accessor: "City",
    },
    {
      title: "State",
      accessor: "State",
    },
    {
      title: "Vendor",
      accessor: "Vendor",
    },
    {
      title: "Product",
      accessor: "Product",
    },
    {
      title: "Quarter",
      accessor: "Quarter",
    },
    {
      title: "Sales",
      accessor: "Sales",
      showTotal: true,
      type: "money",
    },
    {
      title: "Rebate",
      accessor: "Amount",
      showTotal: true,
      type: "money",
    },
    {
      title: "Rebate %",
      accessor: "Percentage",
      type: "number",
      transform: (value) => {
        return `${value.toFixed(2)}%`;
      },
    },
    {
      title: "Facility MS",
      accessor: "FacilityMS",
      type: "number",
      transform: (value) => {
        return `${value.toFixed(2)}%`;
      },
    },
    {
      title: "Corporate MS",
      accessor: "CorporateMS",
      type: "number",
      transform: (value) => {
        return `${value.toFixed(2)}%`;
      },
    },
    {
      title: "Check Number",
      accessor: "CheckNumber",
    },
    {
      title: "Check Date",
      accessor: "CheckDate",
      transform: (value) => {
        return value == null
          ? null
          : new Date(Date.parse(value)).toString("MMM dd, yyyy");
      },
    },
  ];

  const subStatusDetailColumnsUnpaid = [
    {
      title: "DEA",
      accessor: "DEA",
    },
    {
      title: "Business Name",
      accessor: "Name",
    },
    {
      title: "City",
      accessor: "City",
    },
    {
      title: "State",
      accessor: "State",
    },
    {
      title: "Vendor",
      accessor: "Vendor",
    },
    {
      title: "Product",
      accessor: "Product",
    },
    {
      title: "Quarter",
      accessor: "Quarter",
    },
    {
      title: "Sales",
      accessor: "Sales",
      type: "money",
      showTotal: true,
    },
    {
      title: "Rebate",
      accessor: "Amount",
      type: "money",
      showTotal: true,
    },
    {
      title: "Rebate %",
      accessor: "Percentage",
      type: "number",
      transform: (value) => {
        return `${value.toFixed(2)}%`;
      },
    },
    {
      title: "Facility MS",
      accessor: "FacilityMS",
      type: "number",
      transform: (value) => {
        return `${value.toFixed(2)}%`;
      },
    },
    {
      title: "Corporate MS",
      accessor: "CorporateMS",
      type: "number",
      transform: (value) => {
        return `${value.toFixed(2)}%`;
      },
    },
  ];

  const statusDetailColumnsExport = [
    {
      title: "DEA",
      accessor: "DEA",
    },
    {
      title: "Business Name",
      accessor: "Name",
    },
    {
      title: "City",
      accessor: "City",
    },
    {
      title: "State",
      accessor: "State",
    },
    {
      title: "Vendor",
      accessor: "Vendor",
    },
    {
      title: "Product",
      accessor: "Product",
    },
    {
      title: "Quarter",
      accessor: "Quarter",
    },
    {
      title: "Sales",
      accessor: "Sales",
      type: "money",
    },
    {
      title: "Rebate",
      accessor: "Amount",
      type: "money",
    },
    {
      title: "Rebate %",
      accessor: "Percentage",
      type: "number",
    },
    {
      title: "Facility MS",
      accessor: "FacilityMS",
      type: "number",
    },
    {
      title: "Corporate MS",
      accessor: "CorporateMS",
      type: "number",
    },
    {
      title: "Check Number",
      accessor: "CheckNumber",
    },
    {
      title: "Check Date",
      accessor: "CheckDate",
    },
  ];

  const statusDetailColumnsUnpaidExport = [
    {
      title: "DEA",
      accessor: "DEA",
    },
    {
      title: "Business Name",
      accessor: "Name",
    },
    {
      title: "City",
      accessor: "City",
    },
    {
      title: "State",
      accessor: "State",
    },
    {
      title: "Vendor",
      accessor: "Vendor",
    },
    {
      title: "Product",
      accessor: "Product",
    },
    {
      title: "Quarter",
      accessor: "Quarter",
    },
    {
      title: "Sales",
      accessor: "Sales",
      type: "money",
    },
    {
      title: "Rebate",
      accessor: "Amount",
      type: "money",
    },
    {
      title: "Rebate %",
      accessor: "Percentage",
      type: "number",
    },
    {
      title: "Facility MS",
      accessor: "FacilityMS",
      type: "number",
    },
    {
      title: "Corporate MS",
      accessor: "CorporateMS",
      type: "number",
    },
  ];

  const checkColumns = [
    {
      title: "Action",
      accessor: "Action",
    },
    {
      title: "Check Number",
      accessor: "CheckNumber",
    },
    {
      title: "Check Date",
      accessor: "CheckDate",
      transform: (value) => {
        return value == null
          ? null
          : new Date(Date.parse(value)).toString("MMM dd, yyyy");
      },
    },
    {
      title: "Sales",
      accessor: "Sales",
      showTotal: true,
      type: "money",
    },
    {
      title: "Rebate",
      accessor: "Amount",
      showTotal: true,
      type: "money",
    },
  ];

  const subCheckColumns = [
    {
      title: "DEA",
      accessor: "DEA",
    },
    {
      title: "Business Name",
      accessor: "Name",
    },
    {
      title: "City",
      accessor: "City",
    },
    {
      title: "State",
      accessor: "State",
    },
    {
      title: "Vendor",
      accessor: "Vendor",
    },
    {
      title: "Product",
      accessor: "Product",
    },
    {
      title: "Quarter",
      accessor: "Quarter",
    },
    {
      title: "Sales",
      accessor: "Sales",
      type: "money",
      showTotal: true,
    },
    {
      title: "Rebate",
      accessor: "Amount",
      type: "money",
      showTotal: true,
    },
    {
      title: "Rebate %",
      accessor: "Percentage",
      type: "number",
      transform: (value) => {
        return `${value.toFixed(2)}%`;
      },
    },
    {
      title: "Facility MS",
      accessor: "FacilityMS",
      type: "number",
      transform: (value) => {
        return `${value.toFixed(2)}%`;
      },
    },
    {
      title: "Corporate MS",
      accessor: "CorporateMS",
      type: "number",
      transform: (value) => {
        return `${value.toFixed(2)}%`;
      },
    },
    {
      title: "Check Number",
      accessor: "CheckNumber",
    },
    {
      title: "Check Date",
      accessor: "CheckDate",
      transform: (value) => {
        return value == null
          ? null
          : new Date(Date.parse(value)).toString("MMM dd, yyyy");
      },
    },
  ];

  const checkColumnsExport = [
    {
      title: "DEA",
      accessor: "DEA",
    },
    {
      title: "Business Name",
      accessor: "Name",
    },
    {
      title: "City",
      accessor: "City",
    },
    {
      title: "State",
      accessor: "State",
    },
    {
      title: "Vendor",
      accessor: "Vendor",
    },
    {
      title: "Product",
      accessor: "Product",
    },
    {
      title: "Quarter",
      accessor: "Quarter",
    },
    {
      title: "Sales",
      accessor: "Sales",
      type: "money",
    },
    {
      title: "Rebate",
      accessor: "Amount",
      type: "money",
    },
    {
      title: "Rebate %",
      accessor: "Percentage",
      type: "number",
    },
    {
      title: "Facility MS",
      accessor: "FacilityMS",
      type: "number",
    },
    {
      title: "Corporate MS",
      accessor: "CorporateMS",
      type: "number",
    },
    {
      title: "Check Number",
      accessor: "CheckNumber",
    },
    {
      title: "Check Date",
      accessor: "CheckDate",
    },
  ];

  let ReportForm, handleLoadGrid;

  switch (reportType) {
    case "status":
      ReportForm = RebateStatusForm;
      handleLoadGrid = loadStatusGrid;
      break;
    case "check":
      ReportForm = RebateCheckForm;
      handleLoadGrid = loadCheckGrid;
      break;
    default:
      console.error("Invalid Rebate Report Type");
  }

  function loadStatusGrid(quarterStart, quarterEnd, paidUnpaid) {
    const uri = `api/report/rebate/status?locId=${locId}&locType=${shortType}&quarterStart=${quarterStart}&quarterEnd=${quarterEnd}&paidUnpaid=${paidUnpaid}`;

    setColumns(statusColumns);
    setDetailColumns(statusDetailColumns);

    if (paidUnpaid === "unpaid") {
      //setColumns(statusColumnsUnpaid);
      setSubColumns(subStatusColumnsUnpaid);
      setSubDetailColumns(subStatusDetailColumnsUnpaid);
    } else {
      //setColumns(statusColumns);
      setSubColumns(subStatusColumns);
      setSubDetailColumns(subStatusDetailColumns);
    }

    console.log("Rebate Report - status - api call", uri);
    loadGrid(uri);
    loadStatusExport(quarterStart, quarterEnd, paidUnpaid);
  }

  function loadStatusExport(quarterStart, quarterEnd, paidUnpaid) {
    const uri = `api/report/rebate/statusexport?locId=${locId}&locType=${shortType}&quarterStart=${quarterStart}&quarterEnd=${quarterEnd}&paidUnpaid=${paidUnpaid}`;

    if (paidUnpaid === "unpaid") {
      setColumnsExport(statusColumnsUnpaidExport);
      setDetailColumnsExport(statusDetailColumnsUnpaidExport);
    } else {
      setColumnsExport(statusColumnsExport);
      setDetailColumnsExport(statusDetailColumnsExport);
    }

    loadExportData(uri);
  }

  function loadCheckGrid(startDate, endDate, checkNumber) {
    let uri = `api/report/rebate/check?locId=${locId}&locType=${shortType}`;

    if (checkNumber !== "") {
      uri += `&checkNumber=${checkNumber}`;
    } else {
      uri += `&startDate=${startDate}&endDate=${endDate}`;
    }

    setSubColumns(subCheckColumns);
    setColumns(checkColumns);

    console.log("Rebate Report - status - api call", uri);
    loadGrid(uri);
    loadCheckExport(startDate, endDate, checkNumber);
  }

  function loadCheckExport(startDate, endDate, checkNumber) {
    let uri = `api/report/rebate/checkexport?locId=${locId}&locType=${shortType}`;

    if (checkNumber !== "") {
      uri += `&checkNumber=${checkNumber}`;
    } else {
      uri += `&startDate=${startDate}&endDate=${endDate}`;
    }

    setColumnsExport(checkColumnsExport);
    loadExportData(uri);
  }

  function loadGrid(uri) {
    setLoading(true);
    axios.get(uri).then(
      (result) => {
            setIsReportLoaded(true);
            setReportData(result.data);
            setFormattedData(result.data)
            setLoading(false);
            setDataAvailable(true);
            if (result.data == null || result.data.length === 0) {
                setDataAvailable(false);
                handleAlertStatus("No records were found for these report criteria");
            }
      },
      (error) => {
        console.error(error.message);
        handleErrorStatus("An error occurred retrieving the report data.");
        setLoading(false);
        setIsReportLoaded(true);
      }
    );
  }

  function loadExportData(uri) {
    setLoading(true);
    axios.get(uri).then(
      (result) => {
        setIsReportLoaded(true);
        setReportDataExport(result.data);
        setLoading(false);

        if (result.data == null || result.data.length === 0) {
          handleAlertStatus("No records were found for these report criteria.");
        }
      },
      (error) => {
        console.error(error.message);
        handleErrorStatus(
          "An error occurred retrieving the report data for export."
        );
        setLoading(false);
        setIsReportLoaded(true);
      }
    );
    }


    function sendDataToApi(e) {

        let a = formattedData;
        let b = JSON.parse(JSON.stringify(a));

        let reformattedData = formatArrayForRebateReportExport([b], hasBeenFormatted);

        const uri = `api/report/generateRebateOutputReport`;
        axios.post(uri, {
            method: "post",
            responseType: "arraybuffer",
            data: reformattedData,
            Name: locationName,
            reportType: reportType //"rebateSummary"

        }).then((response) => {
            var FileSaver = require('file-saver');
            let blob = base64toBlob(response.data, 'application/vnd.ms-excel;');
            FileSaver.saveAs(blob, locationName + '_RebateReport.xls');
        })
            .catch((error) => {
                console.error(error.message);
                alert("An error occurred downloading the file.");
            });

    }


    function formatArrayForRebateReportExport([ArrayData], hasBeenFormatted) {

        let ArrayDataLength = ArrayData.length;
        if (!hasBeenFormatted) {
            for (let i = 0; i < ArrayDataLength; i++) {

                ArrayData[i].Record[0].Sales = (JSON.stringify(ArrayData[i].Record[0].Sales));
            }
        }
        setHasBeenFormatted(true);
        return [ArrayData];
    }

    function base64toBlob(base64Data, contentType) {
        contentType = contentType || '';
        var sliceSize = 1024;
        var byteCharacters = atob(base64Data);
        var bytesLength = byteCharacters.length;
        var slicesCount = Math.ceil(bytesLength / sliceSize);
        var byteArrays = new Array(slicesCount);

        for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            var begin = sliceIndex * sliceSize;
            var end = Math.min(begin + sliceSize, bytesLength);

            var bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    }


  function handleClose() {
    setShowModal(false);
    setModalUri(null);
    setModalUriExport(null);
  }

  const ReportTable = () => {
    return isReportLoaded ? (
      <React.Fragment>
        <StatusDetailsModal
          show={showModal}
          handleClose={handleClose}
          uri={modalUri}
          uriexport={modalUriExport}
          columns={detailcolumns}
          subcolumns={detailsubcolumns}
          exportable
          totalFooter
          stopheaderColumnSort
          exportcolumns={detailcolumnsexport}
          exportrows={reportDataExport}
        />
        <DataTable
          id="tblRebateReport"
          rows={reportData}
          columns={columns}
          subcolumns={subcolumns}
          pagination
          filterable
          // exportable
          handleCellClick={() => {}}
          totalFooter
          stopheaderColumnSort
          expandAll
          exportcolumns={exportcolumns}
          exportrows={reportDataExport}
        />
      </React.Fragment>
    ) : null;
  };

  return (
    <React.Fragment>
      <StatusModal statusProps={statusProps} />
      <ReportForm
        handleSubmit={handleLoadGrid}
        isGridLoaded={isReportLoaded}
        setModalUri={setModalUri}
        setModalUriExport={setModalUriExport}
        setShowModal={setShowModal}
          />
          <Row>
              {isDataAvailable  ? (
                  <Col className='col-sm-2'>
                  <Button type='submit' className='w100' onClick={sendDataToApi}>
                          <i class="fa fa-download"></i> Download .xls 
                </Button>
                      </Col>
              ) : null

              }

          </Row>
      <ReportTable />
    </React.Fragment>
  );
};

export default RebateReport;
