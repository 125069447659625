
import React, { useEffect, useState, useRef, useContext } from "react";
import UserContext from "../context/UserContext";
import useStatusModal from "../../hooks/useStatusModal";
import StatusModal from "../StatusModal";
import axios from "axios";
import { Form, Row, Col,  Button, InputGroup, Modal} from "react-bootstrap";


 export function ContactUsModal  (props) {
    const {
      statusProps,
      handleErrorStatus,
      handleAlertStatus,
      handleSuccessStatus,
    } = useStatusModal();
  
    const [contactEmails,setContactEmails] = useState([])
    const userContext = useContext(UserContext);
    const currentUser = userContext.currentUser;  
    const reasons = [
      "reason 1",
      "reason 2",
      "reason 3",
      "reason 4",
    ];
    const av = currentUser.Location && currentUser.Location.AV ? currentUser.Location.AV.map(x=>x.Email) : [];
    const pars = currentUser.Location && currentUser.Location.PARCount ? currentUser.Location.PARCount.map(x=>x.Email) : currentUser.Location ? [currentUser.Location.PAR] : [];
    //set removes duplicates
    const emails = [...new Set([...av,...pars])].filter( x=>x != "");
   
    const getContactInfo = ()=>{
      if(contactEmails.length < 1) {
        axios.get("/api/user/contacts?emails="+emails.join(',')).then(
          (response) => {
            const ret = response.data;
            if(ret.length > 0){
              const retEmails = ret.map((v)=>{
                return {name:v.Name, email:v.Email, cc:v.CC}
              })
              setContactEmails(retEmails);
              setForm({ ...form, emailTo: retEmails[0].email});
            }
          },
          (error) => {
            console.error("Error Getting Global GER: ", error.message);
          }
        );
      }
    };
  
    useEffect(()=>{
        getContactInfo();
    },[])
  
    const [form,setForm] = useState({
      Comment:'',
      Reason: reasons[0],
    });
  
    const closemodal = () =>{
      setForm({});
      props.handleClose();
    }
    
    const handleChange = (event) => {
      let fieldName = event.target.name;
      let fieldVal = event.target.value;
        setForm({ ...form, [fieldName]: fieldVal});
    };
    
    const submit = () => {
      if(currentUser.Location){
        if(!form.Comment){
          handleErrorStatus("Please add a comment.")
          return false;
        }
  
        if(!form.emailTo){
          handleErrorStatus("No representatives found for this location.")
          return false;
        }
      
        const uri = "api/contact";
        form.Emails = emails;
        form.User = {Email: currentUser.Email, Name: currentUser.Name};
        if(contactEmails.length > 0 ){
          const emailObject = contactEmails.find(x=> x.email === form.emailTo);
          if(emailObject.cc){
            form.cc = emailObject.cc;
          }
        }
        axios.post(uri,form)
          .then((response)=>{
            handleSuccessStatus("An email has been send to your account representative")
            closemodal();
          })
          .catch(()=>{
            handleErrorStatus("An error occurred contacting the account representative.");
          })
      }else{
        handleErrorStatus("Please choose a location first.");
      }
     
    };
  
    return (
      <React.Fragment>
        <StatusModal statusProps={statusProps} />
        <Modal show={props.show} onHide={() => props.handleClose()} centered>
          <Modal.Header >
            <Modal.Title>
              <div className="row">
                <div className="col-11">
                  Contact Us
                </div>
                <div className="col-1">
                <i className ="fa fa-times modal-x" onClick={()=>props.handleClose()} ></i>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form  className="contact-us-form">
            <Form.Row>
              {contactEmails.length > 0 && 
                <Form.Group as={Col} sm="8">
                  <Form.Label>Email To: </Form.Label>
                  <Form.Control as="select" name="emailTo"  value={form.emailTo} onChange={handleChange}>
                    {contactEmails.map((val,indx)=><option key={indx} value={val.email}>{val.name}</option>)}
                  </Form.Control>
                </Form.Group>
              }
            
              {/* <Form.Group as={Col} sm="8">
                <Form.Label>Reason</Form.Label>
                <Form.Control
                  as="select"
                  name="Reason"
                  value={form.reason}
                  // defaultValue={reasons[0]}
                  onChange={handleChange}
                >
                  {reasons.map((val, indx) => {
                    return <option key={indx}>{val}</option>;
                  })}
                </Form.Control>
              </Form.Group> */}
              <Form.Group as={Col} sm="10">
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  as="textarea" rows={3}
                  name="Comment"
                  value={form.comment}
                  onChange={handleChange}
                  
                />
              </Form.Group>
            </Form.Row>
          </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => closemodal()}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                submit();
              }}
            >
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  };

//   {
//     name: "Contact Us",
//     // link: "https://www.mhainc.com/ContactUs/",
//     icon: "fa fa-envelope-o",
//     link:"#",
//   },


//   const [show, setShow] = useState(false)
    
//     const handleClose = () => setShow(false);

//     const handleClick = ()=> setShow(true);


//     <span className="fake-link" onClick ={()=>handleClick()}>
//             <i className={item.icon} />
//             {item.name}
//           </span>

