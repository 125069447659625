import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import UserContext from "../../context/UserContext";
import { DataTable } from "mha-ui-kit-react-library/dist/index";
import { LoadingSpinner } from "./../../layout/LoadingSpinner";
import "mha-ui-kit-react-library/dist/styles.css";
import { OverlayTrigger, Popover } from "react-bootstrap";
import ContactsModal from "./ContactsModal";
import ChildLocationModal from "./ChildLocationsModal";
import StatusModal from "../../StatusModal";
import useStatusModal from "../../../hooks/useStatusModal";

const List = () => {
  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;
  const [impersonateEmail, setImpersonateEmail] = useState(null);
  const [impersonateName, setImpersonateName] = useState(null);
  const [emailUpdated, setEmailUpdated] = useState(false);
  const history = useHistory();
  const { statusProps, handleErrorStatus } = useStatusModal();

  const [isLoaded, setIsLoaded] = useState(false);
  const [locations, setLocations] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(currentUser.Location);

  const [showLocationsModal, setShowLocationsModal] = useState(false);
  const [locationsModalId, setLocationsModalId] = useState(null);
  const [locationsModalType, setLocationsModalType] = useState(null);

  const [showContactsModal, setShowContactsModal] = useState(false);
  const [contactsModalId, setContactsModalId] = useState(null);
  const [contactsModalType, setContactsModalType] = useState(null);

  function handleImpersonateClick(contact) {
    setImpersonateEmail(contact.Email);
    setImpersonateName(`${contact.FirstName} ${contact.LastName}`);
    setEmailUpdated(true);
  }

  async function handleLocationClick(location) {
    const uri =
      location.Type === "ParentCompany"
        ? `api/${location.Type}/${location.Id}`
        : `api/${location.Type}s/${location.Id}`;

    console.log("handleLocationClick - api call", uri);
    const email = currentUser.Email;
    let type = location.Type;
    if (type !== "") {
      type = type === "Member" ? "M" : type === "ParentCompany" ? "PC" : "A";
    }
    const id = location.Id;
    const config = { ID: id, Type: type };
    currentUser.LastLocation = config;
    var cacheUri = `api/cache/userConfig/${email}/location`;
    console.log(`azure cache - api call - ${uri}`);
    try {
      const cacheCall = await axios.post(cacheUri, config);
      console.log("config from member list", cacheCall.data);
    } catch (error) {
      console.error(error.message);
    }

    // axios.post(cacheUri, config).then(
    //     (result) => {
    //         console.log("config from member list", result.data);
    //     },
    //     (error) => {
    //         alert(error.message);
    //         console.error(error.message);
    //     }
    // );
    axios.get(uri).then(
      (result) => {
        setCurrentLocation(result.data);
      },
      (error) => {
        console.error(error.message);
        handleErrorStatus("An error occurred getting the location data.");
      }
    );
  }

  const OptionsPopover = (row) => {
    if (row) {
      return (
        <Popover>
          <Popover.Title as="h3">{row.Name}</Popover.Title>
          <Popover.Content className="p-2">
            <button
              className="btn btn-primary w-100 mb-2"
              onClick={() =>
                handleLocationClick({ Id: row.ID, Type: row.Type })
              }
            >
              {row.Type === "Affiliate"
                ? "Select Affiliate"
                : "Select Corporation"}
            </button>
            <button
              className="btn btn-primary w-100"
              onClick={() => showChildLocationsModal(row.ID, row.Type)}
            >
              View Locations
            </button>
          </Popover.Content>
        </Popover>
      );
    }
  };

  function showContacts(id, locType) {
    setShowContactsModal(true);

    if (id !== contactsModalId) {
      setContactsModalId(id);
      locType = locType === "ParentCompany" ? "PC" : locType[0];
      setContactsModalType(locType);
    }
  }

  function showChildLocationsModal(id, locType) {
    setShowLocationsModal(true);

    if (id !== locationsModalId) {
      setLocationsModalId(id);
      locType = locType === "Affiliate" ? "A" : "PC";
      setLocationsModalType(locType);
    }
    // to close popover
    document.body.click();
  }

  const columns =
    //currentUser.IsTestMode
    (currentUser.Info &&
      currentUser.Info.Roles &&
      currentUser.Info.Roles.includes("Internal Admin")) ||
    (currentUser.Info && currentUser.Info.IsAcctRep)
      ? [
          {
            title: "Name",
            accessor: "Name",
            transform: (value, index, row) => {
              return (
                <React.Fragment>
                  <button
                    className="link-button  no-focus"
                    onClick={() =>
                      handleLocationClick({ Id: row.ID, Type: "Member" })
                    }
                  >
                    {value}
                  </button>
                  {currentUser.Info.Roles.includes("Internal Admin") && (
                    <button
                      className="link-button no-focus ml-2"
                      onClick={() => showContacts(row.ID, "Member")}
                    >
                      [IMP]
                    </button>
                  )}
                </React.Fragment>
              );
            },
          },
          {
            title: "Address",
            accessor: "Address",
          },
          {
            title: "City",
            accessor: "City",
          },
          {
            title: "State",
            accessor: "State",
          },
          {
            title: "NCPDP",
            accessor: "NCPDP",
          },
          {
            title: "NPI",
            accessor: "NPI",
          },
          {
            title: "HIN",
            accessor: "HIN",
          },
          {
            title: "DEA",
            accessor: "DEA",
          },
          {
            title: "ParentCompanyId",
            accessor: "ParentCompanyId",
            hidden: true,
          },
          {
            title: "Corporate",
            accessor: "ParentCompanyName",
            transform: (value, index, row) => {
              return (
                <React.Fragment>
                  <button
                    className="link-button no-focus"
                    onClick={() =>
                      handleLocationClick({
                        Id: row.ParentCompanyId,
                        Type: "ParentCompany",
                      })
                    }
                  >
                    {value}
                  </button>
                  {currentUser.Info.Roles.includes("Internal Admin") && (
                    <button
                      className="link-button no-focus ml-2"
                      onClick={() =>
                        showContacts(row.ParentCompanyId, "ParentCompany")
                      }
                    >
                      [IMP]
                    </button>
                  )}
                </React.Fragment>
              );
            },
          },
          {
            title: "AffiliateId",
            accessor: "AffiliateId",
            hidden: true,
          },
          {
            title: "Affiliate",
            accessor: "AffiliateName",
            transform: (value, index, row) => {
              return (
                <React.Fragment>
                  <button
                    className="link-button no-focus"
                    onClick={() =>
                      handleLocationClick({
                        Id: row.AffiliateId,
                        Type: "Affiliate",
                      })
                    }
                  >
                    {value}
                  </button>
                  {row.AffiliateId &&
                    currentUser.Info.Roles.includes("Internal Admin") && (
                      <button
                        className="link-button no-focus ml-2"
                        onClick={() =>
                          showContacts(row.AffiliateId, "Affiliate")
                        }
                      >
                        [IMP]
                      </button>
                    )}
                </React.Fragment>
              );
            },
          },
        ]
      : [
          {
            title: "Name",
            accessor: "Name",
            transform: (value, index, row) => {
              if (row.Type === "Member") {
                return (
                  <button
                    className="link-button  no-focus"
                    onClick={() =>
                      handleLocationClick({ Id: row.ID, Type: "Member" })
                    }
                  >
                    {value}
                  </button>
                );
              } else {
                return (
                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    overlay={OptionsPopover(row)}
                    rootClose={true}
                  >
                    <button className="link-button no-focus">
                      {row.Type == "Affiliate" ? (
                        <>
                          <i className="fa fa-hospital-o"></i>&nbsp;
                        </>
                      ) : (
                        <>
                          <i className="fa fa-building"></i>&nbsp;
                        </>
                      )}
                      {value}
                    </button>
                  </OverlayTrigger>
                );
              }
            },
          },
          {
            title: "Address",
            accessor: "Address",
          },
          {
            title: "City",
            accessor: "City",
          },
          {
            title: "State",
            accessor: "State",
          },
          {
            title: "NCPDP",
            accessor: "NCPDP",
          },
          {
            title: "NPI",
            accessor: "NPI",
          },
          {
            title: "HIN",
            accessor: "HIN",
          },
          {
            title: "DEA",
            accessor: "DEA",
          },
        ];

  // impersonate
  useEffect(() => {
    async function updateContext() {
      await userContext.onCurrentUserChanged(currentUser);
      return true;
    }

    let done = false;

    if (emailUpdated && impersonateEmail != null && impersonateName != null) {
      console.log("set imperson", currentUser);
      if (!currentUser.AuthEmail) currentUser.AuthEmail = currentUser.Email;
      if (!currentUser.AuthName) currentUser.AuthName = currentUser.Name;
      currentUser.Name = impersonateName;
      currentUser.Email = impersonateEmail;
      currentUser.IsImpersonating = true;
      currentUser.OktaId = null;
      currentUser.Info = null;
      currentUser.Groups = null;
      currentUser.Location = null;
      //currentUser.IsTestMode = false;
      done = updateContext();
    }

    return () => {
      if (done) {
        console.log("impersonate push");
        history.push("/");
      }
    };
  }, [
    emailUpdated,
    impersonateEmail,
    impersonateName,
    currentUser,
    userContext,
    history,
  ]);

  // load member list
  useEffect(() => {
    let uri = "api/members";
    if (
      !(
        currentUser.Info &&
        currentUser.Info.Roles &&
        currentUser.Info.Roles.includes("Internal Admin")
      )
    ) {
      uri = uri + `?email=${currentUser.Email}`;
    }
    console.log("List - useeffect - get member list - api call", uri);
    axios.get(uri).then(
      (result) => {
        setIsLoaded(true);

        if (
          currentUser.Info &&
          currentUser.Info.Roles &&
          (currentUser.Info.Roles.includes("Internal Admin") ||
            currentUser.Info.IsAcctRep)
        ) {
          setLocations(result.data);
        } else {
          result.data.forEach((l) => {
            if (l.Type == "Affiliate") l.TypeRank = 1;
            else if (l.Type == "ParentCompany") l.TypeRank = 2;
            else l.TypeRank = 3;
          });
          setLocations(
            result.data.sort((a, b) => {
              if (a.TypeRank < b.TypeRank) return -1;
              if (a.TypeRank > b.TypeRank) return 1;
              if (a.Name < b.Name) return -1;
              if (a.Name > b.Name) return 1;
              return 0;
            })
          );
        }
      },
      (error) => {
        console.error(error.message);
        handleErrorStatus("An error occurred retrieving the location list.");
        setIsLoaded(true);
      }
    );
  }, [currentUser.Info, currentUser.Email]);

  // update current location and push home
  useEffect(() => {
    if (
      (currentUser.Location ||
        (currentUser.Info && currentUser.Info.IsAcctRep) ||
        // currentUser.IsTestMode
        (currentUser.Info &&
          currentUser.Info.Roles &&
          currentUser.Info.Roles.includes("Internal Admin"))) &&
      currentLocation !== currentUser.Location
    ) {
      console.log("pushing is happening");
      currentUser.Location = currentLocation;
      userContext.onCurrentUserChanged(currentUser);
      history.push("/");
    }
  });

  const handleLocationsModalClose = () => {
    setShowLocationsModal(false);
    setLocationsModalId(null);
    setLocationsModalType(null);
  };

  const handleContactsModalClose = () => {
    setShowContactsModal(false);
    setContactsModalId(null);
    setContactsModalType(null);
  };

  if (!isLoaded) {
    return (
      <React.Fragment>
        <LoadingSpinner isDataLoading="true" />
        <StatusModal statusProps={statusProps} />
        <div className="m-2">
          <DataTable
            columns={columns}
            rows={[]}
            id="tblLocations"
            handleCellClick={() => {}}
          />
        </div>
      </React.Fragment>
    );
  } else {
    //console.log("jon", locations[0]);
    return (
      <React.Fragment>
        <StatusModal statusProps={statusProps} />
        <div className="m-2">
          <ChildLocationModal
            show={showLocationsModal}
            handleClose={handleLocationsModalClose}
            id={locationsModalId}
            type={locationsModalType}
            email={currentUser.Email}
            handleLocationClick={handleLocationClick}
          />
          <ContactsModal
            show={showContactsModal}
            handleClose={handleContactsModalClose}
            id={contactsModalId}
            type={contactsModalType}
            handleImpersonateClick={handleImpersonateClick}
          />
          <DataTable
            columns={columns}
            rows={locations}
            id="tblLocations"
            filterable
            pagination
            handleCellClick={() => {}}
            freezeheader
          />
        </div>
      </React.Fragment>
    );
  }
};

export default List;
