import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import StatusModal from "../StatusModal";
import useStatusModal from "../../hooks/useStatusModal";
import "./reports.css";
import "font-awesome/css/font-awesome.min.css";
import UserContext from "../context/UserContext";


export function NetworkContractReport(props) {
  const [show, setShow] = useState(false);
  let [apiData, setData] = useState([""]);
  const chosenCot = "";
  const handleClose = () => setShow(false);
  let [timesLoaded, setCount] = useState(0);
  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;
  //let [dataCount, setDataCount] = useState(0);
  const { statusProps, handleErrorStatus } = useStatusModal();

  useEffect(() => {});

  const handleShow = () => {
    setShow(true);
    GetRecentDocs(props.reportType, props.memType, props.id);
    //apiData.length === 0 ? DisplayEmpty() : handleLoad();
  };

  //const handleLoad = () => {}; // getting rid of warnings

  function GetRecentDocs(reportType, memType, id) {
    let uri =
      "api/Documents/" +
      reportType +
      "/recent?take=" +
      2 +
      "&memberType=" +
      memType +
      "&id=" +
      id;
    axios.get(uri).then(
      (result) => {
        setCount(1);
        !result.data.length ? DisplayEmpty() : setData(result.data);
        // removing unused to get rid of warnings
        //let SFN = apiData.length;
        //setDataCount(SFN);
      },
      (error) => {
        console.error(error.message);
        handleErrorStatus("An error occurred retrieving the recent documents.");
        setShow(false);
      }
    );
  }

  function downloadReport(reportType, id, memType, year, quarter) {
    console.log(reportType, id, memType, year, quarter);

    let uri =
      "api/Documents/" +
      reportType +
      "?id=" +
      id +
      "&memberType=" +
      memType +
      "&quarter=" +
      year +
      quarter;

    console.log(uri);
    axios
      .get(uri, {
        method: "GET",
        responseType: "blob",
      })
      .then(
        (result) => {
          // props.pageView(`NetworkContract/${reportType}/${year}${quarter}`, currentUser.Location)
          props.pageView('Network Contract')
          window.open(URL.createObjectURL(result.data));
        },
        (error) => {
          console.error(error.message);
          handleErrorStatus("An error occurred downloading the document.");
        }
      );
  }

  function DisplayEmpty() {
    setData([""]);
  }

  return (
    <React.Fragment>
      <button className="link-button" variant="primary" onClick={handleShow}>
        {props.name}
      </button>

      <StatusModal statusProps={statusProps} />

      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header >
          <Modal.Title>
            <div className="row">
              <div className="col-11">
              {props.Title} {chosenCot}
              </div>
              <div className="col-1">
              <i className ="fa fa-times modal-x" onClick={handleClose} ></i>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-12">
              <div id="modalItemList">
                {timesLoaded !== 0 && apiData.length && <div>
                  Click below to download documents to your downloads folder.
                </div>}
                {apiData.map((value, i) => {
                  if (timesLoaded === 0) {
                    return <p key={i}>Checking for files</p>;
                  } else if (value === "" || !apiData.length) {
                    return <p key={i}>There are no files available</p>;
                  } else {
                    let quarter = value.substring(
                      value.indexOf("_Q") + 2,
                      value.length - 4
                    );
                    let year = value.substring(
                      value.indexOf("_Q") - 4,
                      value.length - 7
                    );

                    let nameOfDocHolder = value.substring(
                      value.indexOf("-us-") + 4,
                      value.length - 11
                    );

                    return (
                      <div key={i} className="modal-list-item">
                        <button
                          className="link-button"
                          onClick={() =>
                            downloadReport(
                              props.reportType,
                              props.id,
                              props.memType,
                              year,
                              quarter
                            )
                          }
                        >
                                <i class="fa fa-download backToFolders" ></i> {year} Q{quarter} {nameOfDocHolder} {props.reportType}
                        </button>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
