import React, { useEffect, useState, useRef, useContext } from "react";
//import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Col } from 'react-bootstrap';
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import axios from "axios";
import { Dropdown, NavItem, Modal, Nav, Row } from "react-bootstrap";
import { Profile } from "./../pages/Profiles/Profile";
import { Link, useHistory } from "react-router-dom";
import "./Header.css";
import "font-awesome/css/font-awesome.min.css";
import StatusModal from "../StatusModal";
import UserContext from "../context/UserContext";
import useStatusModal from "../../hooks/useStatusModal";
import { useOktaAuth } from "@okta/okta-react";
import Logo from "../../images/logo-mha.svg";
import {ContactUsModal} from "./ContactUsModal";

import { pageView } from "../../googleAnalyticsHelper";

export function Header(props) {
  // const [notificationCount, setCount] = useState(1);
  // const [hasNotifications, setNotificationBool] = useState(true);
  // const notificationCount = 1;
  // const hasNotifications = false;
  const alerts = [];
  const userContext = useContext(UserContext);
  const { statusProps, handleErrorStatus } = useStatusModal();
  const currentUser = userContext.currentUser;
  const ltc = ["LTC/NURSING HOME PROVIDER", "OMNICARE"];

  const userInitials =
    currentUser && currentUser.Name ? currentUser.Name[0] : "";
  const userName = currentUser ? currentUser.Name ?? "" : "";
  const type =
    currentUser && currentUser.Location && currentUser.Location.Type
      ? currentUser.Location.Type.toLowerCase()
      : "";
  const isImpersonating = currentUser && currentUser.IsImpersonating;

  var typeDisplay =
    currentUser && currentUser.Location && currentUser.Location.Type;
  if (typeDisplay && typeDisplay === "ParentCompany") {
    typeDisplay = "Corporate";
  }
  if (typeDisplay && typeDisplay === "Member") {
    typeDisplay = "Location";
  }

  

  function getLinks() {
    let links = [];
    let hasMany = false;
    if (currentUser.Location) {
      const cots =
        currentUser.Location.Type !== "Member"
          ? currentUser.Location.COTs.map((i) => i.Description)
          : [currentUser.Location.COT];
      //has both
      if (
        currentUser.Info != null &&
        cots.some((r) => ltc.includes(r)) &&
        cots.some((r) => !ltc.includes(r))
      ) {
        hasMany = true;
      }
      if (hasMany) {
        links = [
          { link: "api/Documents/guide?cot=LTC", name: "LTC User Guide",  i:"fa fa-file-pdf-o" },
          { link: "api/Documents/guide?cot=ASCD", name: "ASCD User Guide", i:"fa fa-file-pdf-o" },
        ];
      } else {
        links = !cots.some((r) => ltc.includes(r))
          ? [{ link: "api/Documents/guide?cot=ASCD", name: "ASCD User Guide", i:"fa fa-file-pdf-o"}]
          : [{ link: "api/Documents/guide?cot=LTC", name: "LTC User Guide", i:"fa fa-file-pdf-o"}];
      }
    }
    links.push({link:"",name:"Contact Us", i:"fa fa-envelope-o"})
    return links;
  }

  //const showImpersonate =
  //  currentUser &&
  //  currentUser.Info &&
  //  (currentUser.Info.IsAcctRep ||
  //    (currentUser.Info.Roles &&
  //              currentUser.Info.Roles.includes("Internal Admin")));

  const showImpersonate =
    currentUser &&
    currentUser.Info &&
    currentUser.Info.Roles &&
    currentUser.Info.Roles.includes("Internal Admin");

  const history = useHistory();
  const { oktaAuth, authState } = useOktaAuth();

  const [profileShow, setProfileShow] = useState(false);
  const [stopImpersonating, setStopImpersonating] = useState(false);
  const [contactUsShow, setContactUsShow] = useState(false);

  const handleLogout = () => {
    currentUser.Info = null;
    userContext.onCurrentUserChanged({});
    localStorage.clear();
    oktaAuth.signOut('/');
  };

  const handleProfileClick = () => {
    if (currentUser && currentUser.Location) {
      setProfileShow(true);
    }
  };

  function handleStopImpersonating() {
    console.log("stop impersonating click");
    setStopImpersonating(true);
  }

  const handleHelpClick = (link) => {
    debugger;
    if (link !== ""){
      axios
      .get(link, {
        method: "GET",
        responseType: "blob",
      })
      .then((result) => {
        pageView("UserGuide");
        window.open(URL.createObjectURL(result.data), "blank");
      })
      .catch((error) => {
        console.error(error.message);
        handleErrorStatus("An error occurred downloading the document.");
      });
    }else{
      setContactUsShow(!contactUsShow)
    }
    
  };

  useEffect(() => {
    async function updateContext() {
      await userContext.onCurrentUserChanged(currentUser);
      return true;
    }

    let done = false;

    if (stopImpersonating) {
      // console.log("stop impersonting in context");
      currentUser.Name = currentUser.AuthName;
      currentUser.Email = currentUser.AuthEmail;
      currentUser.IsImpersonating = false;
      currentUser.OktaId = null;
      currentUser.Info = null;
      currentUser.Groups = null;
      currentUser.Location = null;
      done = updateContext();
    }

    return () => {
      if (done) {
        history.push("/");
      }
    };
  }, [stopImpersonating, currentUser, userContext, history]);

  //need to add dropdown for profile
  return (
    <>
      <StatusModal statusProps={statusProps} />
      <ContactUsModal show={contactUsShow} handleClose={setContactUsShow}/>
      <Modal
        show={profileShow}
        onHide={() => setProfileShow(false)}
        dialogClassName={
          type === "member" ? "profile-modal-member" : "profile-modal"
        }
      >
          <Profile close={() => setProfileShow(false)} />
      </Modal>
      <Navbar expand="lg" id="screen-header">
        <Navbar.Brand href="/">
          <img src={Logo} alt="MHA logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Row style={{ width: "100%" }}>
            <Col lg="10" className="no-gutters">
              {currentUser && currentUser.Location && (
                <Nav className="" id="screen-header-location">
                  <Nav.Link onClick={handleProfileClick}>
                    <span className="location-name">
                      {currentUser.Location.Type === "Affiliate" && (
                        <>
                          <i className="fa fa-hospital-o profile-building"></i>
                          &nbsp;
                        </>
                      )}
                      {currentUser.Location.Type === "ParentCompany" && (
                        <>
                          <i className="fa fa-building profile-building"></i>
                          &nbsp;
                        </>
                      )}
                      {currentUser.Location.Name}
                    </span>
                    <span className="location-extra-details">
                      {`${typeDisplay} ID: ${currentUser.Location.LOCID} (${currentUser.Location.City}, ${currentUser.Location.State})`}
                    </span>
                  </Nav.Link>
                </Nav>
              )}
            </Col>
            <Col lg="1" className="no-gutters">
              <HelpCenter
                hasLocation={currentUser && currentUser.Location}
                links={getLinks}
                handleHelpClick={handleHelpClick}
              />
            </Col>
            <Col lg="1" className="no-gutters">
              <UserAvatar
                handleLogout={() => handleLogout()}
                userInitials={userInitials}
                username={userName}
                isImpersonating={isImpersonating}
                handleStopImpersonating={handleStopImpersonating}
                showImpersonate={showImpersonate}
              />
            </Col>
          </Row>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

function HelpCenter({ hasLocation, links, handleHelpClick }) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      {hasLocation && (
        <Nav id="screen-header-help">
          <Nav.Link onClick={() => setShow(!show)} className="help-avatar">
            <span id="help-avatar-mark">?</span>
            <i ref={target} className="help-circle" id="help-avatar-circle" />
            <span className="help">Help Center</span>
          </Nav.Link>
        </Nav>
      )}
      <Overlay
        target={target.current}
        show={show}
        placement="bottom"
        rootClose
        onHide={() => {
          setShow(!show);
        }}
      >
        <Popover className="popover-basic">
          <Popover.Content>
            {links().map((r, i) => {
              return (
                <Dropdown.Item
                  key={i}
                  onClick={() => {
                    handleHelpClick(r.link);
                  }}
                >
                  <i
                    class={r.i}
                    style={{ marginRight: "5px" }}
                  ></i>
                  {r.name}
                </Dropdown.Item>
              );
            })}
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
}

function UserAvatar({
  handleLogout,
  userInitials,
  username,
  isImpersonating,
  handleStopImpersonating,
  showImpersonate,
}) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <Nav id="screen-header-user">
        <Nav.Link onClick={() => setShow(!show)} className="user-avatar">
          <span id="user-avatar-initials">{userInitials}</span>
          <i ref={target} className="user-circle" id="user-avatar" />
          <span className="username">{username}</span>
        </Nav.Link>
      </Nav>
      <Overlay
        target={target.current}
        show={show}
        placement="bottom"
        rootClose
        onHide={() => setShow(!show)}
      >
        <Popover className="popover-basic">
          <Popover.Content>
            {/*
            <Dropdown.Item eventKey="1">
              <i className="fa fa-cogs" />
              User Settings
            </Dropdown.Item>
            <Dropdown.Item eventKey="2">
              <i className="fa fa-lock" />
              Password & Security
            </Dropdown.Item>
                      */}
            {showImpersonate && (
              <Link
                to={{pathname:"/impersonate",
                state:{name:"Impersonate"}}}
                className="dropdown-item"
                style={{ color: "black" }}
              >
                Impersonate
              </Link>
            )}
            {isImpersonating && (
              <React.Fragment>
                {/*
                <Dropdown.Divider />
                              */}
                <Dropdown.Item eventKey="3" onClick={handleStopImpersonating}>
                  <i className="fa fa-sign-out" />
                  Stop Impersonating
                </Dropdown.Item>
              </React.Fragment>
            )}
            <Dropdown.Divider />
            <Dropdown.Item eventKey="3" onClick={handleLogout}>
              <i className="fa fa-sign-out" />
              Logout
            </Dropdown.Item>
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
}

function Alerts(props) {
  const { alerts } = props;
  const [showAlert, setShowAlert] = useState(false);
  const target = useRef(null);

  const alertDropdown = alerts.map((v, i) => {
    return <Dropdown.Item eventKey={i}>{v}</Dropdown.Item>;
  });

  return (
    <NavItem>
      <span
        className="nav-link"
        ref={target}
        onClick={() => setShowAlert(!showAlert)}
      >
        <span className="action-link-with-icon">
          <i className="fa fa-bell-o"></i>
          {alerts.length > 0 && (
            <span className="notification">{alerts.length}</span>
          )}
        </span>
      </span>
      <Overlay
        target={target.current}
        show={showAlert}
        placement="bottom"
        rootClose
        onHide={() => setShowAlert(false)}
      >
        <Popover className="popover-basic">
          <Popover.Content>
            {alerts.length > 0 ? (
              alertDropdown
            ) : (
              <Dropdown.Item eventKey={1}>
                You don't have any notifications.
              </Dropdown.Item>
            )}
          </Popover.Content>
        </Popover>
      </Overlay>
    </NavItem>
  );
}
