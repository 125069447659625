import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import UserContext from "../../../context/UserContext";
import { LoadingSpinner } from "../../../layout/LoadingSpinner";
import StatusModal from "../../../StatusModal";
import useStatusModal from "../../../../hooks/useStatusModal";
import { DataTable } from "mha-ui-kit-react-library/dist/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment"
import { saveAs } from 'file-saver';
require("datejs");


const AWP = () => {
  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;
  const {
    statusProps,
    handleErrorStatus,
    handleAlertStatus,
  } = useStatusModal();

  const id = currentUser.Location.LOCID;
  const locType = currentUser.Location.Type;
  const cotList = currentUser.Location.COTs;
  const locationName = currentUser.Location.Name;
    const emailAddress = currentUser.Info.Email;
    const userOBJ = JSON.stringify(currentUser).toString();
    //const userOBJ = "hello";


  const today = Date.today();
  const threeYearsPrev = Date.today().add({ years: -3 });

  const [startDate, setStartDate] = useState(threeYearsPrev);
  const [endDate, setEndDate] = useState(today);

  const [isReportLoaded, setIsReportLoaded] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [isColumnsLoaded, setIsColumnsLoaded] = useState(false);
  const [columns, setColumns] = useState([]);
  const [cotForm, setCOTForm] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [isDataAvailable, setDataAvailable] = useState(false);

  function handleCOTChange (event){
    let fieldVal = event.target.value;
    setCOTForm(fieldVal);
  }

  function handleSubmit(e) {
    e.preventDefault();

    const form = e.target;
    const data = form.elements;
    const startDate = Date.parse(data.awpStartDate.value).toString(
      "yyyy-MM-dd"
    );
    const endDate = Date.parse(data.awpEndDate.value)
      .add({ months: 1, days: -1 })
      .toString("yyyy-MM-dd");

    if (endDate < startDate) {
      alert("Start date must be before end date");
      return;
    }

    // reset state after each submit
    setReportData(null);
    setColumns([]);
    setIsReportLoaded(false);
    setIsColumnsLoaded(false);
    setIsLoading(true);
      const uri = `api/report/awp?locId=${id}&locType=${locType}&startDate=${startDate}&endDate=${endDate}&cotId=${cotForm}&emailAddress=${emailAddress}`;

    console.log(`AWP - api call`, uri);
      axios.get(uri, {
        
          data: userOBJ

      }).then(
      (result) => {
        setIsReportLoaded(true);
        setReportData(result.data);
        setIsLoading(false);
        setDataAvailable(true);
        if (result.data == null || result.data.length === 0) {
                setDataAvailable(false);
          handleAlertStatus("No records were found for these report criteria");
        }
      },
      (error) => {
        console.error(error.message);
        setIsReportLoaded(true);
        setIsLoading(false);
        handleErrorStatus("An error occurred retrieving the report data.");
      }
    );
  }

  function handleReset(e) {
    setStartDate(threeYearsPrev);
    setEndDate(today);
    }


    function sendDataToApi(e) {

      
        var formattedData = [reportData]
        let value = JSON.stringify(formattedData)
        console.log(formattedData);
        //console.log(JSON.stringify(formattedData));
        const uri = `api/report/generateAWPOutputReport`;
        axios.post(uri, {
            method: "post",
            responseType: "arraybuffer",
            data: formattedData,
            Name: locationName
         
        }).then((response) => {
            var FileSaver = require('file-saver');
            let blob = base64toBlob(response.data, 'application/vnd.ms-excel;');
            FileSaver.saveAs(blob, locationName + '_AWPTrendReport.xls');
        })
            .catch((error) => {
                console.error(error.message);
               alert("An error occurred downloading the file.");
            });

    }

    function base64toBlob(base64Data, contentType) {
        contentType = contentType || '';
        var sliceSize = 1024;
        var byteCharacters = atob(base64Data);
        var bytesLength = byteCharacters.length;
        var slicesCount = Math.ceil(bytesLength / sliceSize);
        var byteArrays = new Array(slicesCount);

        for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            var begin = sliceIndex * sliceSize;
            var end = Math.min(begin + sliceSize, bytesLength);

            var bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    }



  function getDynamicColumnNames(json) {
    let columnNames = [];
    for (let key in json) {
      if (
        !["TradeName", "ExpectedPatentExpiration", "LastQTRSales"].includes(key)
      ) {
        columnNames.push(key);
      }
    }
    return columnNames;
  }

  useEffect(() => {
      function getDynamicHeaders(json) {

         

      const dynamicColumnNames = getDynamicColumnNames(json);

      let dynamicHeaders = [
        {
          title: "Trade Name",
          accessor: "TradeName",
        },
        {
          title: "Expected Patent Expiration",
          accessor: "ExpectedPatentExpiration",
        },
        {
            title: (moment().quarter() == 1 ? ((new Date().getFullYear() - 1) + " " + moment().subtract(1, "Q").quarter() + "Q " + "Sales") : new Date().getFullYear() + " " + moment().subtract(1, "Q").quarter() + "Q " + "Sales"),
          accessor: "LastQTRSales",
          type: "money",
        },
      ];

      dynamicColumnNames.forEach((n) => {
        dynamicHeaders.push({
          title: n,
          accessor: n,
          hidden: !n.includes("Change"),
        });
      });
      return dynamicHeaders;
    }

    if (
      isReportLoaded &&
      reportData &&
      reportData.length > 0 &&
      columns.length === 0
    ) {
      const cols = getDynamicHeaders(reportData[0]);
      setColumns(cols);
      setIsColumnsLoaded(true);
      setIsLoading(false);
    }
  }, [isReportLoaded, reportData, columns.length]);

  const COTSelect = () => {
    return cotList && cotList.length > 1 ? (
      <Form.Group
        as={Col}
        controlId="awpCot"
        md="auto"
        className="d-flex flex-column"
      >
        <Form.Label>COT</Form.Label>
        <Form.Control as="select" value={cotForm} onChange={handleCOTChange}>
          <option key="all" value="">
            All COTs
          </option>
          {cotList.map((c) => (
            <option key={c.TRID} value={c.TRID}>
              {c.Description}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    ) : null;
  };

  const AWPForm = () => {
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Row>
          <Col className="col-lg-9 col-12 form-date">
            <Form.Row>
              <Form.Group
                as={Col}
                controlId="awpStartDate"
                md="auto"
                className="d-flex flex-column"
              >
                <Form.Label>Start Date</Form.Label>
                <span className="date-wrap">
                  <Form.Control
                    as={DatePicker}
                    dateFormat="MM/yyyy"
                    selected={startDate}
                    minDate={threeYearsPrev}
                    maxDate={today}
                    onChange={(date) => setStartDate(date)}
                    showMonthYearPicker
                  />
                </span>
               
              </Form.Group>
              <Form.Group
                as={Col}
                controlId="awpEndDate"
                md="auto"
                className="d-flex flex-column"
              >
                <Form.Label>End Date</Form.Label>
                <span className="date-wrap">
                  <Form.Control
                    as={DatePicker}
                    dateFormat="MM/yyyy"
                    selected={endDate}
                    minDate={threeYearsPrev}
                    maxDate={today}
                    onChange={(date) => setEndDate(date)}
                    showMonthYearPicker
                  />
                </span>
              </Form.Group>
              <COTSelect />
              <Col className="col-lg-1 col-sm-2 col-12 reset-wrap" style={{marginTop:"2.3%"}} >
                {/* <label>&nbsp;</label> */}
                <Button onClick={handleReset} className="reset-btn">
                  <i className="fa fa-undo"></i> Reset
                </Button>
              </Col>
              <Col className="col-lg-2 col-sm-2 col-12" style={{marginTop:"2.3%"}}>
                <Form.Label>&nbsp;</Form.Label>
                <Button type="submit" className="results">
                  Show Results
                </Button>
              </Col>
            </Form.Row>
          </Col>
          {/* <Col className="col-lg-3 col-12">
            <p>
              Please contact your account representative if details for a check
              from more than 3 years ago are required.
            </p>
          </Col> */}
            </Form.Row>


           



        </Form>

       

       

    );
  };

  const AWPTable = () =>
    isColumnsLoaded ? (
      <DataTable
        id="tblAwpReport"
        rows={reportData}
        columns={columns}
        pagination
        //exportable
        filterable
        handleCellClick={() => { }}
        freezeheader
      />
    ) : null;

  return (
    <div className="m-2">
      <LoadingSpinner isDataLoading={isLoading} />
      <StatusModal statusProps={statusProps} />
          <AWPForm />
          <Row>
              {isDataAvailable ? (
                  <Col className='col-sm-2'>
                      <Button type='submit' className='w100' onClick={sendDataToApi}>
                          <i class="fa fa-download" aria='Hidden'/> Download .xls
                      </Button>
                  </Col>
                
            ) : null
             
              }
          </Row>
      <AWPTable />
    </div>
  );
};

export default AWP;
