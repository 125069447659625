import React from "react";
import {Tooltip, OverlayTrigger } from "react-bootstrap";

export default function GrayedButton(props){
    const {title, label, logo, classes, NAM} = props;
    const mailTo = NAM != null ? <a href={`mailto:${NAM}`}><i className="fa fa-envelope"></i> {title}</a> : title;
    const renderTooltip = (props) =>( 
      <Tooltip id="button-tooltip" {...props}>
        {mailTo}
      </Tooltip>
    );
  
    const classArray = classes.concat(["grayed"]);
  
    return (
        <OverlayTrigger  placement="right" overlay={renderTooltip}  delay={{ show: 250, hide: 800 }}>    
          <button className={classArray.join(" ")} >{logo} {label}</button>
        </OverlayTrigger>
      
    )
  }