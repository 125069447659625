import {ContentItem} from '@kentico/kontent-delivery';

export default class Vimeo extends ContentItem{

    constructor(){
        super({
            richTextResolver:(vimeo) =>{
                const url = vimeo.vimeo_url.value;
                const height = vimeo.height.value ? vimeo.height.value  : 360;
                const width = vimeo.width.value ? vimeo.height.value : 640;
                return `<iframe allow="autoplay; fullscreen;" allowfullscreen="" frameborder="0" height=${height} src=${url} width=${width}></iframe>`
            }
        })
    }
}