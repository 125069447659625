import React from "react";
import { Button, Modal } from "react-bootstrap";
import "font-awesome/css/font-awesome.min.css";
import "./Status.css";

const StatusModal = (props) => {
  const { statusProps } = props;
  const { show, reset, message, alert, error, success, onClick } = statusProps;

  function handleClose() {
    reset();
  }

  const Header = () => {
    if (alert)
      return (
        <Modal.Header className="alert-header">
          <i className="fa fa-exclamation-circle"></i> Alert
        </Modal.Header>
      );
    if (error)
      return (
        <Modal.Header className="error-header">
          <i className="fa fa-times-circle"></i> Error
        </Modal.Header>
      );
    if (success)
      return (
        <Modal.Header className="success-header">
          <i className="fa fa-check-circle"></i> Success
        </Modal.Header>
      );

    return null;
  };

  // console.log("StatusModal", props);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Header />
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StatusModal;
