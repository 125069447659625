import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Button, Col, Form, Row } from "react-bootstrap";
import UserContext from "../../../context/UserContext";
import { DataTable } from "mha-ui-kit-react-library/dist/index";
import GERTrendsForm from "./GERTrendsForm";
import GERYearOverYearForm from "./GERYearOverYearForm";
import GERSummaryForm from "./GERSummaryForm";
import StatusModal from "../../../StatusModal";
import useStatusModal from "../../../../hooks/useStatusModal";

const GERReport = (props) => {
  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;

  const locId = currentUser.Location.LOCID;
  const shortType =
    currentUser.Location.Type === "ParentCompany"
      ? "PC"
      : currentUser.Location.Type[0];
  const locationName = currentUser.Location.Name;
  const [measure, setMeasure] = useState(null);
  const [isReportLoaded, setIsReportLoaded] = useState(false);
  const [isColumnsLoaded, setIsColumnsLoaded] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [reportTypeForButton, setReportTypeForButton] = useState(null);
  const [dataForButton, setDataForButton] = useState([]);
  const { statusProps, handleErrorStatus, handleAlertStatus } =
    useStatusModal();

  const {
    reportType,
    getColumnType,
    getTitle,
    getAdditionalHeaders,
    setLoading,
  } = props;

  function loadGrid(quarterStart, quarterEnd, measure, drugType) {
    // reset state after each submit
    setMeasure(measure);
    setReportData(null);
    setColumns([]);
    setIsReportLoaded(false);
    setIsColumnsLoaded(false);
    setLoading(true);

    const uri = `api/report/ger/${reportType}?locId=${locId}&type=${shortType}&measure=${measure}&quarterStart=${quarterStart}&quarterEnd=${quarterEnd}&drugType=${drugType}`;

    console.log(`GERReport - ${reportType} - api call`, uri);
    axios.get(uri).then(
      (result) => {
        setIsReportLoaded(true);
        setReportData(result.data);
        setLoading(false);
        setDataForButton(result.data);
        setReportTypeForButton(reportType);
        if (result.data == null || result.data.length === 0) {
          handleAlertStatus("No records were found for these report criteria.");
        }
      },
      (error) => {
        console.error(error.message);
        setLoading(false);
        setIsReportLoaded(true);
        handleErrorStatus("An error occurred retrieving the report data.");
      }
    );
  }

  function getDynamicColumnNames(json) {
    let columnNames = [];
    for (let key in json) {
      if (!["Payer", "EstimatedPctMed"].includes(key)) {
        columnNames.push(key);
      }
    }
    return columnNames;
  }

  useEffect(() => {
    function getDynamicHeaders(json) {
      const dynamicColumnNames = getDynamicColumnNames(json);
      let dynamicHeaders = [
        {
          title: "Payer",
          accessor: "Payer",
        },
      ];

      let columnType = getColumnType ? getColumnType(measure) : "text";

      if (reportType === "year") {
        dynamicColumnNames.forEach((n) => {
          const colType = n.indexOf("Claim Count") > 0 ? "number" : "money";

          if (
            n !== "ID" &&
            n.match(/^\d{4}$/g) == null &&
            n.match(/^\d{4}-\d{4}$/g) == null
          ) {
            dynamicHeaders.push({
              title: n,
              accessor: n,
              type: colType,
            });
          }
        });
      } else if (columnType === "number") {
        dynamicColumnNames.forEach((n) => {
          let title = n;
          if (getTitle != null) {
            title = getTitle(n);
          }
          dynamicHeaders.push({
            title: title,
            accessor: n,
            type: columnType,
            transform: (value) => value && value.toFixed(2),
          });
        });
      } else {
        dynamicColumnNames.forEach((n) => {
          let title = n;
          if (getTitle != null) {
            title = getTitle(n);
          }
          dynamicHeaders.push({
            title: title,
            accessor: n,
            type: columnType,
          });
        });
      }

      const additionalHeaders =
        getAdditionalHeaders && getAdditionalHeaders(measure, columnType);

      if (additionalHeaders && additionalHeaders.length > 0) {
        additionalHeaders.forEach((h) => {
          dynamicHeaders.push(h);
        });
      }

      return dynamicHeaders;
    }

    if (
      isReportLoaded &&
      reportData &&
      reportData.length > 0 &&
      columns.length === 0
    ) {
      const cols = getDynamicHeaders(reportData[0], measure);
      setColumns(cols);
      setIsColumnsLoaded(true);
      setLoading(false);
    }
  }, [
    isReportLoaded,
    reportData,
    columns.length,
    getColumnType,
    measure,
    reportType,
    getAdditionalHeaders,
    getTitle,
    setLoading,
  ]);

  let ReportForm;

  switch (reportType) {
    case "summary":
      ReportForm = GERSummaryForm;
      break;
    case "trend":
      ReportForm = GERTrendsForm;
      break;
    case "year":
      ReportForm = GERYearOverYearForm;
      break;
    default:
      handleErrorStatus("Invalid GER Report Type");
  }

  function sendDataToApi(e) {
    var formattedData = [reportData];
    //let value = JSON.stringify(formattedData);
    var formattedColumns = [columns];

    // console.log("cols", formattedColumns);
    // console.log("data", formattedData);

    const uri = `api/report/generateGEROutputReport`;
    // console.log(uri);
    axios
      .post(uri, {
        method: "post",
        responseType: "arraybuffer",
        data: formattedData,
        measure: measure,
        Name: locationName,
        type: reportType,
      })
      .then((response) => {
        var FileSaver = require("file-saver");
        let blob = base64toBlob(response.data, "application/vnd.ms-excel;");
        FileSaver.saveAs(blob, locationName + "_GERReport.xls");
      })
      .catch((error) => {
        console.error(error.message);
        alert("An error occurred downloading the file.");
      });
  }

  function base64toBlob(base64Data, contentType) {
    contentType = contentType || "";
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  const ReportTable = () =>
    isColumnsLoaded ? (
      <DataTable
        id="tblGerReport"
        rows={reportData}
        columns={columns}
        pagination
        //exportable
        handleCellClick={() => {}}
        freezeheader
      />
    ) : null;

  return (
    <React.Fragment>
      <StatusModal statusProps={statusProps} />
      <ReportForm handleSubmit={loadGrid} />
      {reportTypeForButton == reportType &&
      (dataForButton != null || dataForButton != 0) ? (
        <Col className="col-sm-2">
          <Button type="submit" className="w100" onClick={sendDataToApi}>
            <i className="fa fa-download" aria="Hidden" /> Download .xls
          </Button>
        </Col>
      ) : null}
      <ReportTable />
    </React.Fragment>
  );
};

export default GERReport;
