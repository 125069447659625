import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { DataTable } from "mha-ui-kit-react-library/dist/index";
import "mha-ui-kit-react-library/dist/styles.css";
import { LoadingSpinner } from "./../../layout/LoadingSpinner";
import ProductDetails from "./ProductDetails";
import "./ProductPortfolio.css";
import UserContext from "../../context/UserContext";
import queryString from "query-string";
import StatusModal from "../../StatusModal";
import useStatusModal from "../../../hooks/useStatusModal";

export function NDC(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const ndcLinkInfo = useLocation().search;
  const [productDetails, setProductDetails] = useState({
    BrandName: "",
    ContractNumber: "",
    DosageForm: "",
    FDARating: "",
    GenericName: "",
    ItemID: "",
    ItemVendorNum: 0,
    NDC: "",
    Name: "",
    RebateEligible: "",
    Size: "",
    Status: "",
    Strength: "",
    UPC: "",
    UnitDose: "",
    Vendor: "",
  });
  const [alternativesList, setAlternativesList] = useState([]);
  const [futurePriceList, setFuturePriceList] = useState([]);
  const [priceHistoryNDCs, setPriceHistoryNDCs] = useState([]);
  const [pricingInformationList, setPricingInformationList] = useState([]);
  const [purchastHistoryList, setPurchaseHistoryList] = useState([]);

  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;
  const {
    statusProps,
    handleErrorStatus,
    handleAlertStatus,
  } = useStatusModal();
    const disclaimerMessage = " **Disclaimer: Alternative products are based on GPI and Total Package Size."
  const dateTransform = (value) => {
    return value == null
      ? null
      : new Date(Date.parse(value)).toString("MMM dd, yyyy");
  };

  useEffect(() => {
    if (!ndcLinkInfo) {
      handleAlertStatus("click from product portfolio");
      // setStatusProps({
      //   message: "click from product portfolio",
      //   attributes: { alert: "alert" },
      // });
      // setError(true);
      return false;
    }
    if (!dataLoaded && ndcLinkInfo) {
      const { NDC, contractNum } = queryString.parse(ndcLinkInfo);
      const MMID = currentUser.Location.ID;
      const uri = `api/products/ndc?ndc=${NDC}&contractNum=${contractNum}&MMID=${MMID}`;
      setIsLoading(true);
      axios.get(uri).then(
        (result) => {
          console.log(result.data);
              if (result.data != null) {
                  setProductDetails(result.data);
                  setAlternativesList(result.data.AlternativesList);
                  setFuturePriceList(result.data.FuturePriceList);
                  setPriceHistoryNDCs(result.data.PriceHistoryNDCs);
                  setPricingInformationList(result.data.PricingInformationList);

                  setDataLoaded(true);
              }
              if (result.data == null) {
                  alert("this product has hit an error, please contact your representative")
              }
          setIsLoading(false);
        },
        (error) => {
          console.error(error.message);
          handleErrorStatus();
          setIsLoading(false);
        }
      );
    }
  }, [dataLoaded, ndcLinkInfo, currentUser.Location.ID]);

  const getPurchaseHistory = (key) => {
    if (key === "purchase-history") {
      const { contractNum } = queryString.parse(ndcLinkInfo);
      const MMID = currentUser.Location.ID;
      let purchaseUri = `api/products/PurchaseHistory?contractNum=${contractNum}&MMID=${MMID}`;
      setIsLoading(true);
      axios.get(purchaseUri).then(
        (result) => {
          setPurchaseHistoryList(result.data);
          setIsLoading(false);
        },
        (error) => {
          console.error(error.message);
          handleErrorStatus(
            "An error occurred retrieving purchase history data."
          );
          setIsLoading(false);
        }
      );
    }
  };

  const priceHistoryColumns = [
    { title: "Contract", accessor: "ContractNum" },
    {
      title: "Contract End Date",
      accessor: "ContractEnd",
      transform: dateTransform,
    },
    { title: "Price", accessor: "Pricing", type: "money" },
    {
      title: "Effective Date",
      accessor: "EffectiveDate",
      transform: dateTransform,
    },
  ];

  const alternativesColumns = [
    { title: "Vendor Name", accessor: "VendorName" },
    { title: "Contract Number", accessor: "ContractNumber" },
    { title: "Contract Price", accessor: "ContractPrice", type: "money" },
    { title: "Unit Price", accessor: "ContractUnitPrice", type: "money" },
    { title: "AWP", accessor: "AWP" },
    { title: "Trade Name", accessor: "TradeName" },
    { title: "Strength", accessor: "Strength" },
    { title: "Form", accessor: "Form" },
    { title: "Participation Status", accessor: "ParticipationStatus" },
  ];

  const futurePriceColumns = [
    { title: "Contract Num", accessor: "ContractNum" },
    { title: "Price", accessor: "Pricing", type: "money" },
    {
      title: "Effective Date",
      accessor: "EffectiveDate",
      transform: dateTransform,
    },
  ];

  const purchaseHistoryColumns = [
    {
      title: "Sales Date",
          accessor: "SalesYearMonth"
          //,
      //transform: dateTransform,
    },
    { title: "Total Sales", accessor: "TotalSales", type: "money" },
    //{ title: "Sales Year", accessor: "SalesYear", type: "number" },
    { title: "Total Units", accessor: "TotalUnits", type: "number" },
    { title: "Average Cost", accessor: "AvgCost", type: "money" },
  ];

  const pricingInformationColumns = [
    { title: "Pricing Information", accessor: "PricingInformation" },
    {
      title: "Effective Date",
      accessor: "EffectiveDate",
      transform: dateTransform,
    },
  ];

  return (
    <React.Fragment>
      <LoadingSpinner isDataLoading={isLoading} controlsName={"priceChange"} />
      <StatusModal statusProps={statusProps} />
      <Row className="product-details justify-content-md-center">
        <Col xs lg="4">
          <ProductDetails productDetails={productDetails} />
        </Col>
        <Col xs lg="4">
          <DataTable
            columns={pricingInformationColumns}
            rows={pricingInformationList}
            id="pricing-information"
            filterable
                      handleCellClick={() => { }}
                      freezeheader
          ></DataTable>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs lg="8">
          <Tabs
            defaultActiveKey="price-history"
            id="tabs-ndc"
            onSelect={getPurchaseHistory}
          >
            <Tab eventKey="price-history" title="Pricing History">
                          <Col>
                              <p>
                                  {disclaimerMessage}
                              </p>
                          </Col>
              <DataTable
                columns={priceHistoryColumns}
                rows={priceHistoryNDCs}
                id="price=history-grid"
                pagination
                              handleCellClick={() => { }}
                              freezeheader
              ></DataTable>
            </Tab>
            <Tab eventKey="alternative" title="Alternatives**">
                          <Col>
                              <p>
                                  {disclaimerMessage}
                              </p>
                          </Col>
              <DataTable
                columns={alternativesColumns}
                rows={alternativesList}
                id="alternative-grid"
                pagination
                              handleCellClick={() => { }}
                              freezeheader
                ></DataTable>      
            </Tab>
            <Tab eventKey="future-price" title="Future Price">
                          <Col>
                              <p>
                                  {disclaimerMessage}
                              </p>
                          </Col>
              <DataTable
                columns={futurePriceColumns}
                rows={futurePriceList}
                id="future-price-grid"
                pagination
                              handleCellClick={() => { }}
                              freezeheader
              ></DataTable>
            </Tab>
            <Tab eventKey="purchase-history" title="Purchase History">
                          <Col>
                              <p>
                                  {disclaimerMessage}
                              </p>
                          </Col>
              <DataTable
                columns={purchaseHistoryColumns}
                rows={purchastHistoryList}
                id="purchase-history-grid"
                pagination
                              handleCellClick={() => { }}
                              freezeheader
              ></DataTable>
                      </Tab>
          </Tabs>
            
              </Col>
          </Row>
          <div className='extra' ></div>
          <div className = 'whiteSpace' ></div>
    </React.Fragment>
  );
}
