import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./rebateReports.css"
require("datejs");

const RebateCheckForm = (props) => {
  const today = Date.today();
  const twoYearsPrev = Date.today().add({ years: -2 });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  function handleSubmit(e) {
    e.preventDefault();

    const form = e.target;
    const data = form.elements;

    const checkNumber = data.checkNumber.value;
    let checkStartDate = data.checkStartDate.value;
    let checkEndDate = data.checkEndDate.value;

    console.log("start", checkStartDate);
    console.log("end", checkEndDate);

    if (checkStartDate !== "") {
      checkStartDate = Date.parse(data.checkStartDate.value).toString(
        "yyyy-MM-dd"
      );
    }

    if (checkEndDate !== "") {
      checkEndDate = Date.parse(data.checkEndDate.value).toString("yyyy-MM-dd");
    }

    if (checkNumber === "" && (checkStartDate === "" || checkEndDate === "")) {
      alert("Please provider either a check number or start and end dates.");
      return;
    }

    if (checkNumber === "" && checkEndDate < checkStartDate) {
      alert("End date must be after start date.");
      return;
    }

    props.handleSubmit(checkStartDate, checkEndDate, checkNumber);
  }

  function handleReset(e) {
    setStartDate(null);
    setEndDate(null);

    const txtCheckNumber = document.getElementById("checkNumber");
    txtCheckNumber.value = null;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Col className="col-lg-12 col-12">
          <Form.Row>
            <Form.Group
              as={Col}
              controlId="checkStartDate"
              md="auto"
                className="d-flex flex-column col-lg-2 col-sm-4 col-6 form-date"
                autoComplete="off"
            >
              <Form.Label>Start Date</Form.Label>
              <span className="date-wrap">
                <Form.Control
                  as={DatePicker}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                  selected={startDate}
                  minDate={twoYearsPrev}
                  maxDate={today}
                  onChange={(date) => setStartDate(date)}
                  popperPlacement={'bottom-start'}
                  autoComplete="off"
                />
              </span>
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="checkEndDate"
              md="auto"
                className="d-flex flex-column col-lg-2 col-sm-4 col-6 form-date"
                autoComplete="off"
            >
              <Form.Label>End Date</Form.Label>
              <span className="date-wrap">
                <Form.Control
                  as={DatePicker}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                  selected={endDate}
                  minDate={twoYearsPrev}
                  maxDate={today}
                  onChange={(date) => setEndDate(date)}
                  popperPlacement={'bottom-start'}
                  autoComplete="off"
                />
              </span>
            </Form.Group>
            <div>
              <Form.Label>&nbsp;</Form.Label>
              <div className="p-2">OR</div>
            </div>
            <Form.Group
              as={Col}
              controlId="checkNumber"
              className="col-lg-2 col-sm-4 col-12"
            >
              <Form.Label>Check Number</Form.Label>
              <Form.Control placeholder="Check Number" />
            </Form.Group>
            <Col className="col-lg-1 col-sm-1 col-12 reset-wrap" style={{marginTop:
          "2%"}}>
              <label>&nbsp;</label>
              <Button onClick={handleReset} className="reset-btn">
                <i className="fa fa-undo"></i> Reset
              </Button>
            </Col>
            <Col className="col-lg-2 col-sm-2 col-12" style={{marginTop:
          "2%"}}>
              <Form.Label>&nbsp;</Form.Label>
              <Button type="submit" className="results">
                Show Results
              </Button>
            </Col>
          </Form.Row>
        </Col>
        <Col className="col-lg-12 col-12">
          <p>
            Please contact your account representative if details for a check
            from more than two years ago are required.
          </p>
        </Col>
      </Form.Row>
    </Form>
  );
};

export default RebateCheckForm;
