import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataTable } from "mha-ui-kit-react-library/dist/index";
import { LoadingSpinner } from "./../../layout/LoadingSpinner";
import { Modal } from "react-bootstrap";
import StatusModal from "../../StatusModal";
import useStatusModal from "../../../hooks/useStatusModal";

const ChildLocationModal = (props) => {
  const { id, type, email, show, handleLocationClick } = props;

  const [childLocationsLoaded, setChildLocationsLoaded] = useState(false);
  const [childLocationsData, setChildLocationsData] = useState([]);

  const { statusProps, handleErrorStatus } = useStatusModal();

  useEffect(() => {
    if (id != null && type != null && !childLocationsLoaded) {
      var uri = `api/members/?email=${email}&id=${id}&locType=${type}`;

      console.log("child location modal - api call", uri);
      axios.get(uri).then(
        (result) => {
          setChildLocationsLoaded(true);
          setChildLocationsData(result.data);
        },
        (error) => {
          console.error(error.message);
          handleErrorStatus(
            "An error occurred retrieving the locations data."
          );
          setChildLocationsLoaded(true);
        }
      );
    }
  }, [id, type, email, childLocationsLoaded]);

  const handleClose = () => {
    setChildLocationsLoaded(false);
    props.handleClose();
  };

  const childColumns = [
    {
      title: "Name",
      accessor: "Name",
      transform: (value, index, row) => {
        return (
          <button
            className="link-button no-focus"
            onClick={() => handleLocationClick({ Id: row.ID, Type: "Member" })}
          >
            {value}
          </button>
        );
      },
    },
    {
      title: "Address",
      accessor: "Address",
    },
    {
      title: "City",
      accessor: "City",
    },
    {
      title: "State",
      accessor: "State",
    },
    {
      title: "NCPDP",
      accessor: "NCPDP",
    },
    {
      title: "NPI",
      accessor: "NPI",
    },
    {
      title: "HIN",
      accessor: "HIN",
    },
    {
      title: "DEA",
      accessor: "DEA",
    },
  ];

  const ChildLocationTable = () => {
    if (!childLocationsLoaded) {
      return (
        <React.Fragment>
          <LoadingSpinner isDataLoading="true" />
          <DataTable
            id="tblChildLocations"
            columns={childColumns}
            handleCellClick={() => {}}
          />
        </React.Fragment>
      );
    } else {
      return (
        <DataTable
          id="tblChildLocations"
          rows={childLocationsData}
          columns={childColumns}
          filterable
          pagination
              handleCellClick={() => { }}
              freezeheader
        />
      );
    }
  };

  return (
    <React.Fragment>
      <StatusModal statusProps={statusProps} />
      <Modal
        show={show}
        onHide={() => handleClose()}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Locations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChildLocationTable />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ChildLocationModal;
