import React, { useContext, useEffect, useState } from "react";
import UserContext from "./context/UserContext";
import { useHistory } from "react-router-dom";
import { Form, Modal, Button } from "react-bootstrap";


export default function Impersonate  () {
    const history = useHistory();
    const userContext = useContext(UserContext);
    const currentUser = userContext.currentUser;

    const [email, setEmail] = useState("");
    const [emailUpdated, setEmailUpdated] = useState(false);
    const [show, setShow] = useState(true);
    const handleClose = () => {
        history.goBack();
    };
    const handleShow= () => setShow(true);
    async function updateContext(){
        await userContext.onCurrentUserChanged(currentUser);
    }
    function handleSubmit(e) {
        e.preventDefault();
        setEmailUpdated(true);
    }

    useEffect(() => {
        async function updateContext() {
            await userContext.onCurrentUserChanged(currentUser);
        }

        if (emailUpdated) {
            if (!currentUser.AuthEmail) currentUser.AuthEmail = currentUser.Email;
            if (!currentUser.AuthName) currentUser.AuthName = currentUser.Name;
            currentUser.Name = email;
            currentUser.Email = email;
            currentUser.IsImpersonating = true;
            currentUser.Info = null;
            currentUser.Groups = null;
            currentUser.Location = null;
            currentUser.OktaId = null;
            updateContext();
        }

        return () => {
            if (emailUpdated) history.push("/");
        };
    });

    return (
        <React.Fragment>
            {/* <button  className="dropdown-item"
                style={{ color: "black" }}  onClick={handleShow}>
                Impersonate
            </button> */}
            <div className="div-login-container">

            </div>
            <Modal show={show}  animation={true}>
                <Modal.Header>
                    <Modal.Title> 
                        <div className="row">
                            <div className="col-11">
                                Impersonate User 
                            </div>
                            <div className="col-1">
                                <i  className="fa fa-times modal-x" onClick={handleClose} ></i>
                            </div>
                        </div>

                    </Modal.Title>
                </Modal.Header> 
                <Modal.Body>
                    <Form>
                        <Form.Row>
                            <div className="col-6">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control name="email" onChange={(e) => setEmail(e.target.value)} value={email}/>
                            </div>
                        </Form.Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>Impersonate</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};
