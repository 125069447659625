import {ContentItem} from '@kentico/kontent-delivery';

export default class Card extends ContentItem{

    constructor(props){
        const{COT, userAccess} = props;
        super({
            richTextResolver:(rawData) =>{
                const primarySelected = rawData.untitled_multiple_choice.value[0]
                const title = userAccess !== "Secondary" || !primarySelected ? "" : "Contact Your Account Representative";
                const cardStyle = userAccess !== "Secondary" || !primarySelected ? "" : "background-color: #f5f5f5; color: gray; pointer-events: none;";
                const colNum = rawData.cardsize.value ?? 6;
                if(rawData.expirationdate.rawData.value){
                    if(new Date() < new Date(rawData.expirationdate.rawData.value)){
                        return `
                        <div class="d-flex align-items col-${colNum}" title="${title}" >
                            <div class="card" style="${cardStyle}">
                                <div class="card-body">
                                    <h5 class="card-title">${rawData.cardtitle.value}</h5>
                                    <div class="card-text">${rawData.bodytext.value}</div>
                                </div>
                            </div>
                        </div>
                    `
                    }else{
                        return '';
                    }
                }else{
                    return `
                    <div class="d-flex align-items col-${colNum}" title="${title}" >
                        <div class="card" style="${cardStyle}">
                            <div class="card-body">
                                <h5 class="card-title">${rawData.cardtitle.value}</h5>
                                <div class="card-text">${rawData.bodytext.value}</div>
                            </div>
                        </div>
                    </div>
                    `
                }
            }
        })
    }
}