import {ContentItem} from '@kentico/kontent-delivery';

export default class PrimaryOnly extends ContentItem{

    constructor(props){
        const{COT, userAccess} = props;
        super({
            richTextResolver:(primaryOnly) =>{
                if(COT === "ASCD" && userAccess==="Secondary"){
                    return `<div class="disabledlink inline" title="Contact Your Account Representative">${primaryOnly.content.value}</div>`
                }else{
                    return primaryOnly.content.value;
                }
            }
        })
    }
}

