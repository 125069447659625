import './ContentHeader.css';
import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import {Row, Col, InputGroup, FormControl, Button} from 'react-bootstrap'

export function ContentHeader(props) {
    return (
        <Row className="no-gutters" id="content-header">
            <Col className="col-sm-6" id="feature-title">
                {props.featureTitle}
            </Col>

            <Col md={{ span: 2, offset: 6 }} className="ml-auto no-gutters" id="content-search">
                <InputGroup>
                    <InputGroup.Prepend>
                    </InputGroup.Prepend>
                    {/* <FormControl type="text" placeholder="Search" />
                    <InputGroup.Append>
                        <Button className="fa fa-search"></Button>
                    </InputGroup.Append> */}
                </InputGroup>
            </Col>
        </Row>
    )
}