import {ContentItem} from '@kentico/kontent-delivery';
export default class ContentLink extends ContentItem{

    constructor(props){
        const {userAccess, COT, pathCodename} = props;
        super({
            richTextResolver:(rawData) =>{
                const codeName = rawData.link.itemCodenames[0];
                const name = rawData.link.value[0].system.name;
                const url = "/content/${{" +
                pathCodename +
                "}}/${{" +
                userAccess +
                "}}/${{" +
                COT +
                "}}#/" +
                codeName;
                return `<a href='${url}'>${name}</a>`
                
                // return "<Link to="/${codeName}">hi</Link>`
                                
            }
        })
    }
}