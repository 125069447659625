import React from "react";
import { ListGroup } from "react-bootstrap";
export default function ProductDetails(props) {
  const { productDetails } = props;
  return (
    <ListGroup>
      <ListGroup.Item>
        <label>Brand Name: {productDetails.BrandName}</label>
      </ListGroup.Item>
      <ListGroup.Item>
        <label>Generic Name: {productDetails.GenericName}</label>
      </ListGroup.Item>
      <ListGroup.Item>
        <label>NDC: {productDetails.NDC}</label>
      </ListGroup.Item>
      <ListGroup.Item>
        <label>UPC: {productDetails.UPC}</label>
      </ListGroup.Item>
      <ListGroup.Item>
        <label>Dosage Form: {productDetails.DosageForm}</label>
      </ListGroup.Item>
      <ListGroup.Item>
        <label>Strength: {productDetails.Strength}</label>
      </ListGroup.Item>
      <ListGroup.Item>
        <label>Size: {productDetails.Size}</label>
      </ListGroup.Item>
      <ListGroup.Item>
        <label>Unit Dose: {productDetails.UnitDose}</label>
      </ListGroup.Item>
      <ListGroup.Item>
        <label>Manufacturer/Vendor: {productDetails.Vendor}</label>
      </ListGroup.Item>
      <ListGroup.Item>
        <label>FDA Rating: {productDetails.FDARating}</label>
      </ListGroup.Item>
      <ListGroup.Item>
        <label>Contract Status: {productDetails.Status}</label>
      </ListGroup.Item>
      <ListGroup.Item>
        <label>Contract Number: {productDetails.ContractNumber}</label>
      </ListGroup.Item>
      <ListGroup.Item>
        <label>
          Rebate Eligible:{" "}
          {productDetails.RebateEligible !== null &&
          productDetails.RebateEligible === true
            ? "Yes"
            : "No"}
        </label>
      </ListGroup.Item>
    </ListGroup>
  );
}
