import React, { useState } from "react";
import { Form, Col, Button } from "react-bootstrap";
import { DataTable } from "mha-ui-kit-react-library/dist/index";
import "mha-ui-kit-react-library/dist/styles.css";

export function NonMemberForm(props) {
  const {
    CurrentLocation: current,
    PreviousLocation: previous,
    AllStates: states,
  } = props.data;
  const { type, handleSubmit, handleCancel } = props;
  const [form, setForm] = useState(current);

  const nonMemberProps = [
    "Name",
    "Address1",
    "Address2",
    "City/State/Zip",
    "Email",
    "Phone",
    "Ext",
    "Fax",
  ];

  const highlight = (key) => {
    return previous && previous[key] !== current[key] ? "highlight" : "";
  };

  const formatPhone = (value) => {
    value = value.replace(/\D/g, "");
    if (value.length > 3 && value.length <= 6)
      value = value.slice(0, 3) + "-" + value.slice(3);
    else if (value.length > 6)
      value =
        value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6);

    return value;
  };

  const disabled = (key) => {
    // if(key==="Ext"){
    //     return {disabled:true};
    // }else{

    //     return (previous && previous[key] !== current[key]) || !canEdit ? {disabled:true} :{disabled:false}
    // }

    return { disabled: true};
  };

  const maxLength = (key) => {
    if (["Phone", "Fax", "Zip"].includes(key)) {
      return { maxLength: 12, type: "tel" };
    } else {
      return { maxLength: 255 };
    }
  };

  const formValue = (attr) => {
    if (["Phone", "Fax"].includes(attr)) {
      return formatPhone(form[attr] ?? "");
    }
    return form[attr] ?? "";
  };

  const handleChange = (event) => {
    let fieldName = event.target.name;
    let fieldVal = event.target.value;
    if (fieldName === "Phone" || fieldName === "Fax") {
      fieldVal = fieldVal.replace(/\D/g, "");
    }
    setForm({ ...form, [fieldName]: fieldVal });
  };
  const submit = (event) => {
    event.preventDefault();
    handleSubmit(form);
  };

  const formType = (v) => {
    if (v === "Email") {
      return "email";
    } else if (v === "Phone" || v === "Fax") {
      return "tel";
    } else {
      return "text";
    }
  };

  const cancel = () => {
    setForm(current);
    handleCancel();
  };
  const formRows = nonMemberProps.map((v, i) => {
    return v === "City/State/Zip" ? (
      <Form.Row key={i}>
        <Form.Group as={Col} sm="4" controlId="locationCity">
          <Form.Label>City</Form.Label>
          <Form.Control
            name="City"
            className={highlight("City")}
            {...disabled("City")}
            value={formValue("City")}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} sm="4" controlId="locationState">
          <Form.Label>State</Form.Label>
          <Form.Control
            as="select"
            name="state"
            className={highlight("State")}
            {...disabled("State")}
            defaultValue={formValue("State")}
            onChange={handleChange}
          >
            {states.map((val, indx) => {
              return <option key={indx}>{val.Abbreviation}</option>;
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} sm="4" controlId="locationZip">
          <Form.Label>Zip</Form.Label>
          <Form.Control
            name="Zip"
            className={highlight("Zip")}
            {...disabled("Zip")}
            value={formValue("Zip")}
            onChange={handleChange}
          />
        </Form.Group>
      </Form.Row>
    ) : (
      <Form.Row key={i}>
        <Form.Group as={Col} controlId={`location${v}`}>
          <Form.Label>{v}</Form.Label>
          <Form.Control
            name={v}
            className={highlight(v)}
            {...disabled(v)}
            value={formValue(v)}
            onChange={handleChange}
            type={formType(v)}
            {...(v === "Fax" || v === "Phone"
              ? { pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}" }
              : null)}
            {...maxLength(v)}
          />
        </Form.Group>
      </Form.Row>
    );
  });
  const serviceHeaders = [
    {
      title: "Service",
      accessor: "Service",
    },
    {
      title: "Members",
      accessor: "Members",
    },
  ];

  return (
    <Form onSubmit={submit}>
      {formRows}
      {type === "affiliate" ? (
        <Form.Row>
          <Form.Group as={Col} controlId="locationAKA">
            <Form.Label>AKA</Form.Label>
            <Form.Control
              name={"AKA"}
              className={highlight("AKA")}
              {...disabled("AKA")}
              value={formValue("AKA")}
              onChange={handleChange}
            />
          </Form.Group>
        </Form.Row>
      ) : null}

      <Form.Row>
        <Form.Group as={Col}>
          <DataTable
            columns={serviceHeaders}
            rows={current.Services}
            id="services-active-members"
                      handleCellClick={() => { }}
                      freezeheader
          />
        </Form.Group>
      </Form.Row>
      <Form.Row className="justify-content-around">
        <Col sm="6">
          <Button onClick={cancel} className="cancel w-100">
            Close
          </Button>
        </Col>
        {/* <Col className="6">
          <Button type="submit" className="w-100">
            Save
          </Button>
        </Col> */}
      </Form.Row>
    </Form>
  );
}
