import { useState } from "react";
import "font-awesome/css/font-awesome.min.css";

const useStatusModal = () => {
  const [statusProps, setStatusProps] = useState({});

  function reset() {
    setStatusProps({});
  }

  const handleErrorStatus = (message = "An unexpected error occurred.") => {
    setStatusProps({
      show: true,
      reset: reset,
      message: message,
      error: true,
    });
  };

  const handleAlertStatus = (message) => {
    setStatusProps({
      show: true,
      reset: reset,
      message: message,
      alert: true,
    });
  };

  const handleSuccessStatus = (message) => {
    setStatusProps({
      show: true,
      reset: reset,
      message: message,
      success: true,
    });
  };

  return {
    statusProps,
    handleErrorStatus,
    handleAlertStatus,
    handleSuccessStatus,
  };
};

export default useStatusModal;
