import React, { useContext, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import UserContext from "./context/UserContext";
import axios from "axios";
import { LoadingSpinner } from "./layout/LoadingSpinner";
import { useHistory, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import StatusModal from "./StatusModal";
import useStatusModal from "../hooks/useStatusModal";

const UserInitializer = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;

  const { statusProps, handleErrorStatus } = useStatusModal();
  const history = useHistory();
  const location = useLocation();

  const [hasOktaUserId, setHasOktaUserId] = useState(false);
  const [hasOktaUserGroups, setHasOktaUserGroups] = useState(false);
  //const [hasUserInfo, setHasUserInfo] = useState(false);

  // console.log("oktaAuth", oktaAuth);
  // console.log("UserInitializer - authState", authState);
  // console.log("UserInitializer - currentUser", currentUser);
  
  useEffect(() => {
    if (authState.isAuthenticated) {
      if (currentUser && currentUser.Name == null) {
        console.log("user init - auth user - GO");
        oktaAuth.getUser().then((info) => {
          // console.log("okta return", info);
          currentUser.Email = info.email;
          currentUser.Name = info.name;
          currentUser.Groups = info.groups;
          userContext.onCurrentUserChanged(currentUser);
        });
      }
    }
  });

  useEffect(() => {
    if (currentUser && currentUser.Email && !hasOktaUserId) {
      if (currentUser.OktaId != null) {
        setHasOktaUserId(true);
      } else {
        console.log("User Init - get okta user id - GO");
        axios
          .get(
              `	${process.env.REACT_APP_OKTA_URL}/api/v1/users/${currentUser.Email}`
          )
          .then(
            (result) => {
              // console.log("okta api get user", result);
              // console.log("okta api get user - data", result.data);
              currentUser.OktaId = result.data.id;
              setHasOktaUserId(true);
            },
            (error) => {
              console.error("Error getting okta user", error.message);
              // bypass to be able to impersonate user without okta account
              currentUser.OktaId = -1;
              setHasOktaUserId(true);
            }
          );
      }
    }
  });

  useEffect(() => {
    if (hasOktaUserId && !hasOktaUserGroups) {
      console.log("user init - get groups - currentUser.Groups", currentUser);
      if (currentUser.Groups != null) {
        setHasOktaUserGroups(true);
      } else {
        console.log("user init - get groups - GO", currentUser);
        axios
          .get(
              `	${process.env.REACT_APP_OKTA_URL}/api/v1/users/${currentUser.OktaId}/groups`
          )
          .then(
            (result) => {
              const groupNames = result.data.map((g) => g.profile.name);
              currentUser.Groups = groupNames;
              setHasOktaUserGroups(true);
            },
            (error) => {
              console.error("Error getting okta groups", error.message);
              // bypass to be able to impersonate user without okta account
              currentUser.Groups = [
                "MHAPortal-React-Dev-AllUsers",
                "MHAPortal-React-ITTest-AllUsers",
                "MHAPortal-React-UAT-AllUsers",
              ];
              setHasOktaUserGroups(true);
            }
          );
      }
    }
  }, [hasOktaUserId]);

  //has groups, set info
  useEffect(() => {
    if (
      currentUser &&
      currentUser.Email &&
      currentUser.Info == null &&
      hasOktaUserGroups
    ) {
      console.log("user init - get info - GO");
      let groupArrayOBJ = Object.values(currentUser.Groups);
      let isInternal = checkingArrayForGroups(groupArrayOBJ);

      console.log(
        "api call - api/user?email=" +
          currentUser.Email +
          "&isInternal=" +
          isInternal
      );
      axios
        .get(
          "api/user?email=" + currentUser.Email + "&isInternal=" + isInternal
        )
        .then(
          (result) => {
            if (typeof (result.data !== "string")) {
              currentUser.Info = result.data;
              userContext.onCurrentUserChanged(currentUser);
            } else {
              console.error("Could not load load user info");
              history.push("/Member/Locations");
            }
          },
          (error) => {
            console.error("Could not load user info", error.message);
            handleErrorStatus("Error occurred loading user information.");
          }
        );
    }
  }, [hasOktaUserGroups]);

  function checkingArrayForGroups(groupArray) {
    let regexExpressionsForGroups = [/internaladmin/];

    for (let i = 0; i < groupArray.length; i++) {
      let groupArrayVal = groupArray[i].toLowerCase();
      if (matchInArray(groupArrayVal, regexExpressionsForGroups)) {
        return true;
      }
    }

    return false;

    //this was only if we are going to be looking for one string vs an array
    //if (regexExpressionsForGroups.test(groupArrayVal)) {
    //    return true;
    //}
    //else { return false}
  }

  function matchInArray(string, expressions) {
    var len = expressions.length,
      i = 0;

    for (; i < len; i++) {
      if (string.match(expressions[i])) {
        return true;
      }
    }

    return false;
  }

  //not internal admin 
  useEffect(() => {
    if (
      currentUser &&
      currentUser.Info &&
      !currentUser.Info.IsAcctRep &&
      //!currentUser.IsTestMode &&
      !(
        currentUser.Info.Roles &&
        currentUser.Info.Roles.includes("Internal Admin")
      ) &&
      currentUser.Location == null &&
      location.pathname !== "/Member/Locations"
    ) {
      const cacheURI = `api/cache/userConfig/${currentUser.Email}`;
      (async () =>{
        const cacheCall = await axios.get(cacheURI);
        if(cacheCall.data){
          const lastLocation = cacheCall.data.LastLocation;
          const dashboard = cacheCall.data.Dashboard
          const type = lastLocation.Type === "A" ? "Affiliates" : lastLocation.Type === "PC" ? "ParentCompany" : "Members";
          const locationUri = `api/${type}/${lastLocation.ID}`;
          const locationCall = await axios.get(locationUri);
      
          if(locationCall.data){
            currentUser.Location = locationCall.data;
            currentUser.LastLocation = lastLocation;
            currentUser.Dashboard = dashboard
            userContext.onCurrentUserChanged(currentUser);
            history.push("/") 
          }
        }else{
          console.log("user init - push to list - GO");
          history.push("/Member/Locations");
        }
      })();
      console.log("user init - get default - GO");
      //console.log("api call - api/members/default?email=" + currentUser.Email);
      axios.get("api/members/default?email=" + currentUser.Email).then(
        (result) => {
          if (result.data == null) {
            console.log("Could not load default location");
            history.push("/Member/Locations");
            ReactGA.event({
              action: "Authorized user",
              LocationData: "There was no data found",
              wasAbleToFindLocation: false,
            });
          } else {
            console.log("found default location");
            currentUser.Location = result.data;
            userContext.onCurrentUserChanged(currentUser);
            ReactGA.event({
              action: "Authorized user",
              LocationData: [result.data],
              wasAbleToFindLocation: true,
            });
              history.push("/");
          }
        },
        (error) => {
          console.error("Could not load default location", error.message);
          history.push("/Member/Locations");
        }
      );
    }
  }, [currentUser.Info]);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.Location == null &&
      currentUser.Info != null &&
      currentUser.Groups != null &&
      //(currentUser.Info.IsAcctRep || currentUser.IsTestMode) &&
      (currentUser.Info.IsAcctRep ||
        (currentUser.Info.Roles &&
          currentUser.Info.Roles.includes("Internal Admin"))) &&
      location.pathname !== "/Member/Locations"
    ) {
      const cacheURI = `api/cache/userConfig/${currentUser.Email}`;
      (async () =>{
        try{
          const cacheCall = await axios.get(cacheURI);
          if(cacheCall.data){
            const lastLocation = cacheCall.data.LastLocation;
            const type = lastLocation.Type === "A" ? "Affiliates" : lastLocation.Type === "PC" ? "ParentCompany" : "Members";
            const locationUri = `api/${type}/${lastLocation.ID}`;
            const locationCall = await axios.get(locationUri);
            const dashboard = cacheCall.data.Dashboard
            if(locationCall.data){
              currentUser.Location = locationCall.data;
              currentUser.LastLocation = lastLocation;
              currentUser.Dashboard = dashboard;
              userContext.onCurrentUserChanged(currentUser);
              history.push("/") 
            }
          }else{
            console.log("user init - push to list - GO");
            history.push("/Member/Locations");
          }
        }catch (error){
          console.error("Error concerning cache or getting location: ", error.message);
        }
      })();
    }
  });

  if (
    !statusProps.error &&
    currentUser.Location == null &&
    // !currentUser.IsTestMode &&
    !(
      currentUser.Info &&
      currentUser.Info.Roles &&
      currentUser.Info.Roles.includes("Internal Admin")
    ) &&
    (!currentUser.Info || !currentUser.Info.IsAcctRep) &&
    location.pathname !== "/Member/Locations"
  ) {
    return <LoadingSpinner isDataLoading="true" />;
  } else {
    return null;
  }
};

export default UserInitializer;
