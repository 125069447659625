import React from "react";
import { Redirect } from "react-router-dom";
import OktaSignInWidget from "./OktaSignInWidget";
import { useOktaAuth } from "@okta/okta-react";
import "../../login.css";
import Logo from "../../images/logo-mha.svg";


const Login = ({ config }) => {
  console.log("login start");

  const { oktaAuth, authState } = useOktaAuth();
  const onSuccess = (tokens) => {
    oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (err) => {
    console.log("Sign in error:", err);
  };

  if (!authState) {
    return <div>Loading ... </div>;
  }

  console.log("authState", authState);

  if(!authState.isAuthenticated){
    console.log("login - clear storage");
    localStorage.clear();
  }

  return authState.isAuthenticated ? (
    <Redirect to={{ pathname: "/" }} />
  ) : (
    <div id="div-login-container" className="text-center">
      <img src={Logo} alt="MHA logo" />
      <p className="m-2">
        Welcome to the MHA Member Portal.
      </p>
      <OktaSignInWidget config={config} onSuccess={onSuccess} onError={onError} />
    </div>
  );
};

export default Login;
