import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import UserContext from "./context/UserContext";

const Reset = () => {
  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;

  const [resetLocation, setResetLocation] = useState(false);
  const history = useHistory();

  useEffect(() => {
    console.log("Reset - useEffect - check location");
    if (currentUser.Location != null) {
      console.log("Reset - useEffect - location not null, reset = true");
      setResetLocation(true);
    } else {
      console.log("Reset - useEffect - location null, redirect home");
      history.push("/");
    }
  }, []);

  useEffect(() => {
    console.log("Reset - useEffect - resetLocation", resetLocation);
    async function updateContext() {
      await userContext.onCurrentUserChanged(currentUser);
      return true;
    }

    let done = false;

    if (resetLocation) {
      console.log("Reset - useEffect - updateContext");
      currentUser.Location = null;
      done = updateContext();
    }

    return () => {
      if (done) {
        console.log("Reset - useEffect - done resetting, redirect home");
        history.push("/");
      }
    };
  }, [resetLocation, currentUser, userContext, history]);

  return null;
};

export default Reset;
