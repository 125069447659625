import React from "react";
import "./UnderMaintenance.css";
import Logo from "../images/logo-mha-white.svg";

const UnderMaintenance = () =>{

    return (
      <div id="maintenance-container" className="text-center">
        
        <img src={Logo} alt="MHA logo" />
        <div >
          <p className="display-3">We'll be back soon!</p>
          <h3 >
            We're performing some maintenance at the moment and should be back shortly.
          </h3>
          <h3>Thanks for your patience!</h3>
        </div>
      </div>
    )
}

export default UnderMaintenance;
