import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { DataTable } from "mha-ui-kit-react-library/dist/index";
import { LoadingSpinner } from "./../../layout/LoadingSpinner";
import StatusModal from "../../StatusModal";
import useStatusModal from "../../../hooks/useStatusModal";
require("datejs");

const StatusDetailsModal = (props) => {
  const [areDetailsLoaded, setAreDetailsLoaded] = useState(false);
  const [statusDetails, setStatusDetails] = useState([]);
  const { statusProps, handleErrorStatus } = useStatusModal();
    const { type } = props;
    useEffect(() => {

        console.log(props);
        console.log(type);


    if (props.uri != null) {
      console.log("Contract status details modal - api call", props.uri);
      axios.get(props.uri).then(
        (result) => {
          setAreDetailsLoaded(true);
          setStatusDetails(result.data);
        },
        (error) => {
          console.error(error.message);
          handleErrorStatus(
            "An error occurred retrieving the contract status details data."
          );
          setAreDetailsLoaded(true);
        }
      );
    }
  }, [props.uri]);

  const handleClose = () => {
    setAreDetailsLoaded(false);
    props.handleClose();
  };

  const columns = [
    {
      title: "Vendor",
      accessor: "Vendor",
    },
    {
      title: "Contract Num",
      accessor: "ContractNum",
    },
    {
      title: "Status",
      accessor: "Status",
    },
    {
      title: "Effective Date",
      accessor: "EffectiveDate",
      //transform: (value) => {
      //  return new Date(Date.parse(value)).toString("yyyy-MM-dd");
      //},
    },
    {
      title: "Reason",
      accessor: "Reason",
    },
    {
      title: "DEA",
      accessor: "DEA",
    },
    {
      title: "HIN",
      accessor: "HIN",
    },
    {
      title: "Name",
      accessor: "Name",
    },
    {
      title: "Address",
      accessor: "Address",
    },
    {
      title: "City",
      accessor: "City",
    },
    {
      title: "State",
      accessor: "State",
    },
    {
      title: "COT",
      accessor: "COT",
    },
  ];

  const DetailsGrid = () => {
    if (!areDetailsLoaded) {
      return (
        <React.Fragment>
          <LoadingSpinner isDataLoading="true" />
          <DataTable
            id="tblDetails"
            columns={columns}
            handleCellClick={() => {}}
          />
        </React.Fragment>
      );
    } else {
      return (
        <DataTable
          id="tblDetails"
          rows={statusDetails}
          columns={columns}
          filterable
          pagination
          exportable
              handleCellClick={() => { }}
              freezeheader
        />
      );
    }
  };

  return (
    <React.Fragment>
      <StatusModal statusProps={statusProps} />
      <Modal
        show={props.show}
        onHide={() => handleClose()}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header >
          <Modal.Title>
            <div className="row">
                <div className="col-11">
                  Status Details
                </div>
                <div className="col-1 modal-x-container">
                  <i className ="fa fa-times modal-x" onClick={props.handleClose} ></i>
                </div>
              </div>
          </Modal.Title>
           
        </Modal.Header>
              <Modal.Body>
                  {(type == 'A' || type == 'C') ?
                      <div>
                          <b>**Please refer to the location level for more information on these contracts**</b>
                      </div>
                      : null}
          <DetailsGrid />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default StatusDetailsModal;
