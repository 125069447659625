import React from "react";
import { Form, Col, Button } from "react-bootstrap";
import PropTypes from "prop-types";

const formCallbacks = {
  onChange: PropTypes.func,
};
const formGroupExact = {
  label: PropTypes.string,
  graphic: PropTypes.string,
  callBacks: PropTypes.exact(formCallbacks),
  formElement: PropTypes.element,
  type: PropTypes.string,
  id: PropTypes.string,
};

const formExact = {
  formGroups: PropTypes.arrayOf(PropTypes.exact(formGroupExact)).isRequired,
};

export function MHAForm(props) {
  const formGroups = props.form.formGroups.map((item, index) => {
    return (
      <Form.Group as={Col} md="auto" className="d-flex flex-column" key={index}>
        <Form.Label>{item.label}</Form.Label>
        {!!item.formElement ? (
          item.formElement
        ) : (
          <Form.Control
            id={item.id}
            type={item.type}
            onChange={!!item.callBacks ? item.callBacks.onChange : null}
            placeholder={item.label}
          />
        )}
      </Form.Group>
    );
  });

  return (
    <Form
      onSubmit={props.handleSubmit}
      autoComplete={!!props.hasAutocomplete ? "off" : "on"}
    >
      <Form.Row className="justify-content-start">{formGroups}</Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="auto" className="d-flex flex-column">
          <Button className="inline-show-result-button" size="sm" type="submit">
            Show Results
          </Button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
}

MHAForm.propTypes = {
  form: PropTypes.exact(formExact).isRequired,
};
