import React, { useContext, useEffect } from "react";
import { SecureRoute } from "@okta/okta-react";
import { Route, Switch, Redirect } from "react-router-dom";
//import CustomAuthCallback from "./auth/CustomAuthCallback";
import Layout from "./layout/Layout";
import Login from "./auth/Login";
import Home from "./Home";
import UnderMaintenance from "./UnderMaintenance";
import Impersonate from "./Impersonate";
import { ProductPortfolio } from "./pages/ProductPortfolio/ProductPortfolio";
import { NDC } from "./pages/ProductPortfolio/NDC";
import MemberList from "./pages/Member/List";
import ContractStatus from "./pages/Contracts/Status";
import GER from "./pages/Reports/GER/GER";
import AWP from "./pages/Reports/AWP/AWP";
import Rebate from "./pages/Reports/Rebate/Rebate";
import DocumentUpload from "./pages/Profiles/DocumentUpload";
import UserContext from "./context/UserContext";
import Unauthorized from "./auth/Unauthorized";
import Content from "./pages/Content/Content";
import PageNotFound from "./PageNotFound";
import ErrorStatusTest from "./pages/Test/ErrorStatusTest";
import Reset from "./Reset";
import ORProjection from "./pages/Reports/ORProjection/ORProjection";
import AzureCacheTest from "./pages/Test/AzureCacheTest";

const Routes = () => {
  return (
    <Switch>
      {/* <Route path="/implicit/callback" component={CustomAuthCallback} /> */}
      <Route
        path="/login"
        render={(props) => (
          <Login baseUrl={process.env.REACT_APP_OKTA_URL} {...props} />
        )}
      />
      <SecureRoute path="/impersonate" component={Impersonate} />
      <SecureRoute path="/reset" component={Reset} />
      <SecureRouteWithLayout path="/" exact component={Home} />
      <SecureRouteWithLayout
        path="/Member/Locations"
        component={MemberList}
        featureTitle="Member List"
      />
      <SecureRouteWithLayout
        path="/ProductPortfolio"
        exact
        component={ProductPortfolio}
        featureTitle="Product Portfolio"
      />
      <SecureRouteWithLayout
        path="/NDC"
        exact
        component={NDC}
        featureTitile="NDC"
      />
      <SecureRouteWithLayout
        path="/Contracts/Status"
        exact
        component={ContractStatus}
        featureTitle="GPO Contract Status"
      />
      <SecureRouteWithLayout
        path="/Reports/GER"
        exact
        component={GER}
        featureTitle="Member GER Report"
      />
      <SecureRouteWithLayout
        path="/Reports/AWP"
        exact
        component={AWP}
        featureTitle="AWP Trend Report"
      />
      <SecureRouteWithLayout
        path="/Reports/Rebate"
        exact
        component={Rebate}
        featureTitle="Vendor Rebate Payment Status"
      />
      <SecureRouteWithLayout
        path="/Reports/ORProjection"
        exact
        component={ORProjection}
        featureTitle="OR Projection Report"
      />
      <SecureRouteWithLayout
        path="/DocumentUpload"
        exact
        component={DocumentUpload}
        featureTitle="Document Upload"
        // requiredRoles={}
      />
      <SecureRouteWithLayout
        path="/Content/:codename/:isSecondaryASCD/:isLTC"
        exact
        component={Content}
        featureTitle="Content"
      />
      <Route path="/unauthorized" component={Unauthorized} />
      <SecureRouteWithLayout
        path="/Test/ErrorStatusTest"
        exact
        component={ErrorStatusTest}
      />
      <SecureRouteWithLayout
        path="/Test/AzureCacheTest"
        exact
        component={AzureCacheTest}
      />
      <Route path="/UnderMaintenance" exact component={UnderMaintenance} />
      {/* this route has to be last */}
      <Route path="*" exact component={PageNotFound} />
    </Switch>
  );
};

const SecureRouteWithLayout = ({
  component: Component,
  featureTitle,
  requiredRoles = [],
  ...rest
}) => {
  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;
  const userInfo = currentUser ? currentUser.Info : null;
  const userRoles = userInfo ? userInfo.Roles : [];
  const userHasRequiredRole = userRoles
    ? requiredRoles.some((r) => userRoles.includes(r))
    : false;
  const message = "You are not authorized for this page.";
  return (
    <SecureRoute
      {...rest}
      component={(routeProps) =>
        (requiredRoles.length > 0 && userHasRequiredRole) ||
        requiredRoles.length === 0 ? (
          <Layout featureTitle={featureTitle}>
            <Component {...routeProps} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: "/unauthorized",
              state: {
                message,
              },
            }}
          />
        )
      }
    />
  );
};

export default Routes;
