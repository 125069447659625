import React from "react";
import "../login.css";
import Logo from "../images/logo-mha.svg";

const PageNotFound = () => {
    function handleHomeClick() {
      window.location = "/";
     
  }

    

    return (
        
        <div id="div-login-container" className="text-center">
           
      <img src={Logo} alt="MHA logo" />
      <div className="text-left">
        <h2 className="mt-3">Page Not Found</h2>
        <p>
          There was an error loading this page. The page for this address was
          not found.
        </p>
        <div className="mt-3">
          <button className="btn btn-primary" onClick={handleHomeClick}>
            Return to Home Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
