import React, { useContext } from "react";
import useStatusModal from "../../../hooks/useStatusModal";
import StatusModal from "../../StatusModal";
import UserContext from "../../context/UserContext";

const ErrorStatusTest = () => {
  const {
    statusProps,
    handleErrorStatus,
    handleAlertStatus,
    handleSuccessStatus,
  } = useStatusModal();

  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;

  currentUser.Location = "some goofy string";
  userContext.onCurrentUserChanged(currentUser);

  return (
    <div>
      <p>This is the error status test page.</p>
      <StatusModal statusProps={statusProps} />
      <button onClick={() => handleErrorStatus("oh noes")}>
        Click to error
      </button>
      <button onClick={() => handleAlertStatus("meh")}>Click to alert</button>
      <button onClick={() => handleSuccessStatus("huzzah")}>
        Click to success
      </button>
    </div>
  );
};

export default ErrorStatusTest;
