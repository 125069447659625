import React from "react";
import axios from "axios";

const AzureCacheTest = () => {
  function getConfig() {
    var email = document.forms[0].email.value;

    var uri = `api/cache/userConfig/${email}`;
    axios.get(uri).then(
      (result) => {
        console.log("config", result.data);
      },
      (error) => {
        alert("error", error.message);
        console.error(error.message);
      }
    );
  }

  function setConfig() {
    var email = document.forms[0].email.value;
    var type = document.forms[0].type.value;
    var id = parseInt(document.forms[0].id.value);
    var dashboard = document.forms[0].dashboard.value;
    var lastLocation = { id, type, dashboard };
    var config = { email, lastLocation };

    console.log("Cache Test - set config", config);

    var uri = `api/cache/userConfig/${email}`;
    console.log(`azure cache - api call - ${uri}`);
    axios.post(uri, config).then(
      (result) => {
        console.log("config", result.data);
      },
      (error) => {
        alert(error.message);
        console.error(error.message);
      }
    );
  }

  return (
    <React.Fragment>
      <form>
        <p>
          <label>email</label>
          <input type="text" name="email"></input>
        </p>
        <p>
          <label>ID</label>
          <input type="text" name="id"></input>
        </p>
        <p>
          <label>Type</label>
          <select name="type">
            <option value="A">Affiliate</option>
            <option value="PC">Corporate</option>
            <option value="M">Member</option>
          </select>
        </p>
        <p>
          <label>Dashboard</label>
          <select name="dashboard">
            <option value="LTC">LTC</option>
            <option value="ASCD">ASCD</option>
          </select>
        </p>
      </form>
      <button onClick={getConfig}>Get Config</button>
      <button onClick={setConfig}>Set Config</button>
    </React.Fragment>
  );
};

export default AzureCacheTest;
