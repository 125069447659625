import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { LoadingSpinner } from "./../../layout/LoadingSpinner";
import { MHAForm } from "./../../MHAForm";
import { Autocomplete } from "./../../Autocomplete";
import { DataTable } from "mha-ui-kit-react-library/dist/index";
import "mha-ui-kit-react-library/dist/styles.css";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import UserContext from "../../context/UserContext";
import StatusModal from "../../StatusModal";
import useStatusModal from "../../../hooks/useStatusModal";
import GrayedButton from "./../../GrayedButton";

export function ProductPortfolio(props) {
  const { statusProps, handleErrorStatus } = useStatusModal();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [isSecondaryASCD, setSecondaryASCD] = useState(false);
  const [isLTC, setLTC] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const url = "/api/products/autocomplete";
  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;
  const locId = currentUser.Location ? currentUser.Location.LOCID : null;
  const userType = currentUser.Location ? currentUser.Location.Type : null;
  const grayedMessage = "Contact your Account Representative.";

  const NAM = currentUser.Location != null ? currentUser.Location.NAM : null;
  const ltc = ["LTC/NURSING HOME PROVIDER", "OMNICARE"];
  const locationType = currentUser.Location ? currentUser.Location.Type : null;

  const termsAndConditions = currentUser.Info
    ? currentUser.Info.TermDetails
    : null;
  const [userHasRead, setHasRead] = useState(false);
  const userEmail = currentUser.Info ? currentUser.Info.Email : null;

  const columns = [
    {
      title: "Item ID",
      accessor: "itemID",
      hidden: true,
    },
    {
      title: "NDC#",
      accessor: "NDC",
      //filterValue: (value, index, row)=> ( `${value}` ),
      transform: (value, index, row) => {
        if (row.NDC && row.ContractID && row.itemID) {
          const url = `/NDC?NDC=${row.NDC}&contractNum=${row.ContractID}|${row.itemID}`;
          return <Link to={url}>{value}</Link>;
          // return <Link to=
          // { {pathname:url,
          //       state:{name:value}}}>{value}</Link>;
        } else {
          return value;
        }
      },
    },
    {
      title: "Vendor Number",
      accessor: "VendorNumber",
    },
    {
      title: "Vendor",
      accessor: "VendorName",
    },
    {
      title: "Brand Name",
      accessor: "BrandName",
    },
    {
      title: "Generic Name",
      accessor: "Genericname",
    },
    {
      title: "Contract #",
      accessor: "ContractID",
    },
    {
      title: "Participation Status",
      accessor: "ParticipationStatus",
    },
    {
      title: "Price",
      accessor: "Price",
      type: "money",
    },
    {
      title: "WAC",
      accessor: "WAC",
      type: "money",
    },
    {
      title: "AWP",
      accessor: "AWP",
      type: "money",
    },
    {
      title: "Form",
      accessor: "Form",
    },
    {
      title: "Size",
      accessor: "Size",
    },
    {
      title: "UM",
      accessor: "UM",
    },
    {
      title: "Strength",
      accessor: "Strength",
    },
    {
      title: "UD",
      accessor: "UD",
    },

    {
      title: "FDA",
      accessor: "FDA",
    },
    {
     title:"Rebate Eligible",
     accessor:"RebateEligible"
    }
  ];

  const handleClose = () => setShowModal(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = {};
    setIsLoading(true);
    for (var i = 0; i < e.target.length - 1; i++) {
      formData[e.target[i].id] =
        e.target[i].value === "" ? null : e.target[i].value;
    }
    if (Object.keys(formData).length > 0) {
      formData["MMID"] = locId;
      axios({
        method: "POST",
        url: "/api/products",
        data: JSON.stringify(formData),
      })
        .then(function (response) {
          setIsLoading(false);
          setRows(response.data.Products);
        })
        .catch((err) => {
          console.log(err.message);
          handleErrorStatus("An error occurred retrieving the product data.");
          setIsLoading(false);
          return false;
        });
    }
    return;
  };

  const productForm = {
    formGroups: [
      {
        label: "Name",
        formElement: (
          <Autocomplete
            id="Name"
            type="Name"
            endpoint={url}
            placeholder="Name"
            minLength="4"
          />
        ),
      },
      {
        label: "NDC/Old NDC",
        // type: "number",
        id: "NDC",
        formElement: (
          <input
            type="text"
            pattern="^[0-9]*$"
            name="NDC"
            className="form-control"
            title="Please do not use dashes"
            placeholder="NDC/Old NDC"
            id="NDC"
          />
        ),
      },
      {
        label: "Contract #",
        type: "text",
        id: "ContractID",
      },
      {
        label: "Vendor Number",
        type: "text",
        id: "VendorNumber",
      },
      {
        label: "Mfg/Vendor Name",
        formElement: (
          <Autocomplete
            id="Vendor"
            type="Vendor"
            endpoint={url}
            placeholder="Mfg/Vendor Name"
            minLength="1"
          />
        ),
      },
      {
        label: "Package Size",
        type: "text",
        id: "PackageSize",
      },
      {
        label: "Strength",
        type: "text",
        id: "Strength",
      },
      {
        label: "GPI",
        type: "text",
        id: "GPI",
      },
      //{
      //  label: "Therapeutic Category",
      //  formElement: (
      //    <Autocomplete
      //      id="TherapeuticCategory"
      //      type="TherapeuticCategory"
      //      endpoint={url}
      //      placeholder="Therapeutic Category"
      //    />
      //  ),
      //},
    ],
  };

  useEffect(() => {
    if (currentUser.Location != null) {
      setSecondaryASCD(currentUser.Location.IsSecondaryGPO);
      setLTC(ltc.includes(currentUser.Location.COT));
    }
  }, [currentUser.Location]);

  const downloadCatalog = (e) => {
    setIsLoading(true);
    axios
      .get("/api/Products/Catalog?mmid=" + locId, {
        method: "GET",
        responseType: "blob",
      })
      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ProductCatalog.xlsx");
        document.body.appendChild(link);
        link.click();
        setIsLoading(false);
        setShowModal(false);
      })
      .catch((err) => {
        console.error(err.message);
        handleErrorStatus(
          "An error occurred retrieving the product catalog data."
        );
        setIsLoading(false);
        setShowModal(false);
      });
  };
  let exportButtons = [];
  if (isLTC) {
    exportButtons.push(
      <button
        key="catalog"
        id="export_catalog"
        className="nav-link"
        onClick={() => {
          setShowModal(true);
        }}
      >
        {" "}
        Export Catalog
      </button>
    );
  }
  if (isSecondaryASCD) {
    exportButtons.push(
      <GrayedButton
        key="xlsx"
        title={grayedMessage}
        label="Download .xlsx"
        classes={["gray-nav-link"]}
        logo={<i className="fa fa-download"></i>}
        NAM={NAM}
      />
    );
  }

  useEffect(() => {
    if (termsAndConditions != null) {
      if (
        termsAndConditions[0].currentTermsVersionDB ===
        termsAndConditions[0].termsVersionUser
      ) {
        setHasRead(true);
      }
    }
  }, [userHasRead]);

  function updateUserTermVersion() {
    axios
      .get("/api/Products/updateUser?email=" + userEmail, {
        method: "GET",
      })
      .then((response) => {
        console.log(response.data);
        currentUser.Info.TermDetails = response.data;
        userContext.onCurrentUserChanged(currentUser);
        //setHasRead(true);
      })
      .catch((err) => {
        console.error(err.message);
        handleErrorStatus(
          "An error occurred retrieving the product catalog data."
        );
        setHasRead(true);
      });

    console.log(currentUser);
    setHasRead(true);
  }

  return userType !== "Member" ? (
    <Redirect to="/" />
  ) : (
    <React.Fragment>
      <LoadingSpinner isDataLoading={isLoading} controlsName={"priceChange"} />

      <Modal show={!userHasRead} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title className="text-center disclaimer">
            <span>&#9888;</span> Disclaimer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          I agree to keep all information exported, in any form, from MHA
          regarding contract prices, programs, price lists, financial data,
          customer and supplier information, product development, business and
          marketing plans, and information contained in catalogs proprietary and
          confidential information of MHA (“Confidential Information”), strictly
          confidential and shall not directly or indirectly use (except pursuant
          to this Agreement) and/or reveal, report, publish, disclose or
          transfer to any party the Confidential Information for any purpose.
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="primary" onClick={updateUserTermVersion}>
            Agree
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title className="text-center disclaimer">
            <span>&#9888;</span> Disclaimer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          I agree to keep all information exported, in any form, from MHA
          regarding contract prices, programs, price lists, financial data,
          customer and supplier information, product development, business and
          marketing plans, and information contained in catalogs proprietary and
          confidential information of MHA ("Confidential Information"), strictly
          confidential and shall not directly or indirectly use (except pursuant
          to this Agreement) and/or reveal, report, publish, disclose or
          transfer to any party the Confidential Information for any purpose.
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={downloadCatalog}>
            Accept and Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="feature product-portfolio">
        <StatusModal statusProps={statusProps} />
        <Row>
          <Col>
            <MHAForm
              form={productForm}
              handleSubmit={handleSubmit}
              hasAutocomplete={true}
            />
          </Col>
        </Row>
        <Row>
          {exportButtons.map((val) => val)}
          <DataTable
            columns={columns}
            rows={rows}
            id="test"
            filterable
            pagination
            {...(!isSecondaryASCD ? { exportable: true } : null)}
            handleCellClick={() => {}}
            freezeheader
          ></DataTable>
        </Row>
        <Row>
          <b>Disclaimer and referenced in the Member agreement: </b>
        </Row>
        <Row>
          Member agrees that it shall keep all information that it receives, in
          any form, from MHA regarding contract prices, programs, price lists,
          financial data, customer and supplier information, product
          development, business and marketing plans, and information contained
          in catalogs proprietary and confidential information of MHA
          ("Confidential Information"), strictly confidential and shall not
          directly or indirectly use (except pursuant to this Agreement) and/or
          reveal, report, publish, disclose or transfer to any party the
          Confidential Information for any purpose.
        </Row>
      </div>
    </React.Fragment>
  );
}
