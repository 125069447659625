import React, { useEffect, useState } from "react";
import { DataTable } from "mha-ui-kit-react-library/dist/index";
import { LoadingSpinner } from "./../../layout/LoadingSpinner";
import PaperworkDetailsModal from "./PaperworkDetailsModal";
import axios from "axios";
import StatusModal from "../../StatusModal";
import useStatusModal from "../../../hooks/useStatusModal";
require("datejs");

const ContractStatusMemberGrid = (props) => {
  const [isContractsLoaded, setIsContractsLoaded] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalUri, setModalUri] = useState(null);
  const { id, locId } = props;

  const { statusProps, handleErrorStatus } = useStatusModal();

  useEffect(() => {
    const uri = `api/contracts/MemberStatus?locationId=${locId}`;
    console.log("member - useeffect - api call ", uri);
    axios
      .get(uri)
      .then((result) => {
        setIsContractsLoaded(true);
        setContracts(result.data);
      })
      .catch((err) => {
        console.error(err.message);
        handleErrorStatus("An error occurred retrieving contract status data.");
        setIsContractsLoaded(true);
      });
  }, [locId]);

  function handleViewClick(contractId) {
    const uri = `api/contracts/PaperworkDetails/${contractId}?mmid=${id}`;
    console.log("handleViewClick - modalUri", uri);
    setShowModal(true);
    setModalUri(uri);
  }

  const handleClose = () => {
    setShowModal(false);
    setModalUri(null);
  };

  const columns = [
    {
      title: "Action",
      accessor: "ContractId",
      filterValue: (value, index, row) => {
        if (
          row["FilePath"] &&
          row["FilePath"] !== "" &&
          ((row["Paperwork"] === "COMMITTED CONTRACT" &&
            row["Status"] === "Pending") ||
            (row["Paperwork"] === "COMMITTED CONTRACT" &&
              row["Status"] === "Denied") ||
            row["Paperwork"] === "AUTO APPROVED W/ REBATE DOCS")
        ) {
          return "View";
        }
      },
      transform: (value, index, row) => {
        if (
          row["FilePath"] &&
          row["FilePath"] !== "" &&
          ((row["Paperwork"] === "COMMITTED CONTRACT" &&
            row["Status"] === "Pending") ||
            (row["Paperwork"] === "COMMITTED CONTRACT" &&
              row["Status"] === "Denied") ||
            row["Paperwork"] === "AUTO APPROVED W/ REBATE DOCS")
        ) {
          return (
            <button
              className="link-button"
              onClick={() => handleViewClick(value)}
            >
              View
            </button>
          );
        } else return null;
      },
    },
    {
      title: "Vendor",
      accessor: "Vendor",
    },
    {
      title: "Description",
      accessor: "Description",
    },
    {
      title: "Contract Number",
      accessor: "ContractNum",
      transform: (value, index, row) => `${value}${!row.Pricing ? "*" : ""}`,
    },
    {
      title: "",
      accessor: "Pricing",
      hidden: true,
    },
    {
      title: "Paperwork",
      accessor: "Paperwork",
    },
    {
      title: "Status",
      accessor: "Status",
    },
    {
      title: "Status Effective Date",
      accessor: "StatusEffDate",
      //transform: (value) => {
      //  return new Date(Date.parse(value)).toString("yyyy-MM-dd");
      //},
    },
    {
      title: "Reason",
      accessor: "Reason",
    },
    {
      title: "Document Date",
      accessor: "DocDate",
      transform: (value) => {
        return value === "0001-01-01T00:00:00"
          ? ""
          : new Date(Date.parse(value)).toString("yyyy-MM-dd");
      },
    },
    {
      title: "Contract Type",
      accessor: "ContractType",
    },
  ];

  const exportColumns = [
    {
      title: "Vendor",
      accessor: "Vendor",
    },
    {
      title: "Description",
      accessor: "Description",
    },
    {
      title: "Contract Number",
      accessor: "ContractNum",
    },
    {
      title: "Paperwork",
      accessor: "Paperwork",
    },
    {
      title: "Status",
      accessor: "Status",
    },
    {
      title: "Status Effective Date",
      accessor: "StatusEffDate",
      transform: (value) => {
        return new Date(Date.parse(value)).toString("yyyy-MM-dd");
      },
    },
    {
      title: "Reason",
      accessor: "Reason",
    },
    {
      title: "Document Date",
      accessor: "DocDate",
      transform: (value) => {
        return value === "0001-01-01T00:00:00"
          ? ""
          : new Date(Date.parse(value)).toString("yyyy-MM-dd");
      },
    },
    {
      title: "Contract Type",
      accessor: "ContractType",
    },
  ];

  if (!isContractsLoaded) {
    return (
      <React.Fragment>
        <LoadingSpinner isDataLoading="true" />
        <DataTable
          id="tblContracts"
          columns={columns}
          handleCellClick={() => {}}
        />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <StatusModal statusProps={statusProps} />
        <PaperworkDetailsModal
          show={showModal}
          handleClose={handleClose}
          uri={modalUri}
          id={id}
        />
        <div className="float-right w-50 text-right">
          <p>
            Contract Access/Eligibility is determined by each manufacturer based
            on their internal parameters.
          </p>
          <p>* = Contact your account representative for more information.</p>
        </div>
        <DataTable
          id="tblContracts"
          rows={contracts}
          columns={columns}
          filterable
          exportable
          pagination
          handleCellClick={() => {}}
          exportcolumns={exportColumns}
          exportrows={contracts}
          freezeheader
        />
      </React.Fragment>
    );
  }
};

export default ContractStatusMemberGrid;
