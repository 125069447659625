import ReactGA from "react-ga";



const getLocationType = (location) => {
    const ltc = ["LTC/NURSING HOME PROVIDER", "OMNICARE"];
    const cots = location.Type !== "Member"
    ? location.COTs.map((i) => i.Description)
    : [location.COT];
  const userType = cots.some((r)=>ltc.includes(r)) ? "LTC" : "ASCD";
  return userType;
}


export function  pageView  (page){
  const user = JSON.parse(localStorage.getItem("currentUser")) ?? {};
  if(user.Location){
    const userType = getLocationType(user.Location)
    ReactGA.ga('set', 'dimension1', userType);
  }
  ReactGA.pageview(page);
}