import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { DataTable } from "mha-ui-kit-react-library/dist/index";
import { LoadingSpinner } from "./../../../layout/LoadingSpinner";
import StatusModal from "../../../StatusModal";
import useStatusModal from "../../../../hooks/useStatusModal";
import { Button, Col, Form, Row } from "react-bootstrap";
import UserContext from "../../../context/UserContext";
import { saveAs } from 'file-saver';

const StatusDetailsModal = (props) => {
  const [areDetailsLoaded, setAreDetailsLoaded] = useState(false);
  const [statusDetails, setStatusDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    statusProps,
    handleErrorStatus,
    handleAlertStatus,
    } = useStatusModal();

    const userContext = useContext(UserContext);
    const currentUser = userContext.currentUser;
    const locationName = currentUser.Location.Name;
    const [reportDataExport, setReportDataExport] = useState([]);
    const [formattedData, setFormattedData] = useState([]);
    const [isDataAvailable, setDataAvailable] = useState(false);
    const [hasBeenFormatted, setHasBeenFormatted] = useState(false);


  const {
    uri,
    columns,
    subcolumns,
    uriexport,
    exportcolumns,
    stopheaderColumnSort,
  } = props;

  useEffect(() => {
    function loadExportData(uri) {
      setIsLoading(true);
      axios.get(uri).then(
        (result) => {
          setReportDataExport(result.data);
          
          setIsLoading(false);

              if (result.data == null || result.data.length === 0) {
                  if (result.data == null || result.data.length === 0) {
                      handleAlertStatus(
                          "No records were found for these report criteria."
                      );
                  }
              } else {
                  //  const [isDataAvailable, setDataAvailable] = useState(false);
                  setDataAvailable(true)
              }
        },
        (error) => {
          console.error(error.message);
          handleErrorStatus(
            "An error occurred retrieving the report data for export."
          );
          setIsLoading(false);
        }
      );
    }

    if (uri != null) {
      console.log("Rebate status details modal - api call", uri);
      setIsLoading(true);
      axios.get(uri).then(
        (result) => {
          setAreDetailsLoaded(true);
          setStatusDetails(result.data);
              setIsLoading(false);
              setFormattedData(result.data);
          loadExportData(uriexport);
        },
        (error) => {
          console.error(error.message);
          handleErrorStatus("An error occurred retrieving the report data.");
          setAreDetailsLoaded(false);
          setIsLoading(false);
        }
      );
    }
  }, [uri, uriexport]);

    function sendDataToApi(e) {

        let a = formattedData;
        let b = JSON.parse(JSON.stringify(a));

        let reformattedData = formatArrayForRebateReportExport([b], hasBeenFormatted);

        const uri = `api/report/generateRebateOutputReport`;
        axios.post(uri, {
            method: "post",
            responseType: "arraybuffer",
            data: reformattedData,
            Name: locationName,
            reportType: "rebateDetails"

        }).then((response) => {
            var FileSaver = require('file-saver');
            let blob = base64toBlob(response.data, 'application/vnd.ms-excel;');
            FileSaver.saveAs(blob, locationName + '_RebateReport.xls');
        })
            .catch((error) => {
                console.error(error.message);
                alert("An error occurred downloading the file.");
            });

    }


    function formatArrayForRebateReportExport([ArrayData], hasBeenFormatted) {

        let ArrayDataLength = ArrayData.length;
        console.log(ArrayData)

        if (!hasBeenFormatted) {
            for (let i = 0; i < ArrayDataLength; i++) {

                ArrayData[i].Record[0].Sales = (JSON.stringify(ArrayData[i].Record[0].Sales));
            }
        }
        setHasBeenFormatted(true);
        return [ArrayData];
    }

    function base64toBlob(base64Data, contentType) {
        contentType = contentType || '';
        var sliceSize = 1024;
        var byteCharacters = atob(base64Data);
        var bytesLength = byteCharacters.length;
        var slicesCount = Math.ceil(bytesLength / sliceSize);
        var byteArrays = new Array(slicesCount);

        for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            var begin = sliceIndex * sliceSize;
            var end = Math.min(begin + sliceSize, bytesLength);

            var bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    }


  const DetailsGrid = () =>
    areDetailsLoaded ? (
      <DataTable
        id="tblDetails"
        rows={statusDetails}
        columns={columns}
        subcolumns={subcolumns}
        filterable
        pagination
        //exportable
        handleCellClick={() => {}}
        totalFooter
        stopheaderColumnSort={stopheaderColumnSort}
        exportcolumns={exportcolumns}
        exportrows={reportDataExport}
      />
    ) : null;

  return (
    <React.Fragment>
      <StatusModal statusProps={statusProps} />
      <Modal
        show={props.show}
        onHide={() => props.handleClose()}
        // dialogClassName="modal-90w"
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <div className="row">
              <div className="col-11">
                Status Details
              </div>
              <div className="col-1 modal-x-container">
                <i className ="fa fa-times modal-x" onClick={props.handleClose} ></i>
              </div>
            </div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
                  <LoadingSpinner controlName="modalRebate" isDataLoading={isLoading} />
                  <Row>
                      {isDataAvailable ? (
                          <Col className='col-sm-4'>
                              <Button type='submit' className='w100' onClick={sendDataToApi}>
                                  <i class="fa fa-download"></i> Download .xls
                </Button>
                          </Col>
                      ) : null

                      }
                      </Row>
          <DetailsGrid />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default StatusDetailsModal;
