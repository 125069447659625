import React, { useEffect } from "react";
import "./LoadingSpinner.css";

export function LoadingSpinner(props) {
  // const [overlayId,setOverlayId] = useState(props.controlName + "-internal-spinner");
  // const [id, setId] = useState(props.controlName + "-internal-overlay");

  const overlayId = props.controlName + "-internal-spinner";
  const id = props.controlName + "-internal-overlay";

  useEffect(() => {
    let overlay = document.getElementById(overlayId);

    if (props.isDataLoading) {
      overlay.style.display = "block";
    } else {
      overlay.style.display = "none";
    }
  });

  return (
    <div className="loading-overlay" id={overlayId}>
      <div className="loading-dot-container" id={id}>
        <div className="loading-dot-wrapper">
          <div className="loading-dot"></div>
        </div>
        <div className="loading-dot-wrapper">
          <div className="loading-dot delayed-animation"></div>
        </div>
        <div className="loading-dot-wrapper">
          <div className="loading-dot slower-delayed-animation"></div>
        </div>
      </div>
    </div>
  );
}
