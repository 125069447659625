import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { LoadingSpinner } from "../../../layout/LoadingSpinner";
import RebateReport from "./RebateReport";

const Rebate = () => {
  const [isLoading, setIsLoading] = useState(false);

  function handleLoading(newValue) {
    setIsLoading(newValue);
  }

  return (
    <React.Fragment>
      <LoadingSpinner controlName="tabsRebate" isDataLoading={isLoading} />
      <Tabs
        defaultActiveKey="status"
        id="tabsRebate"
        className="m-2 border-bottom-0"
      >
        <Tab eventKey="status" title="Payment Status" className="m-2 mt-4">
          <RebateReport reportType="status" setLoading={handleLoading} />
        </Tab>
        <Tab eventKey="check" title="Check Date or Number" className="m-2 mt-4">
          <RebateReport reportType="check" setLoading={handleLoading} />
        </Tab>
      </Tabs>
    </React.Fragment>
  );
};

export default Rebate;
