import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../context/UserContext";
import ContractStatusCorpAffilGrid from "./ContractStatusCorpAffilGrid";
import ContractStatusMemberGrid from "./ContractStatusMemberGrid";

const ContractStatus = () => {
  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;

  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [id, setId] = useState(null);
  const [locId, setLocId] = useState(null);
  const [type, setType] = useState(null);
  const [shortType, setShortType] = useState(null);

  useEffect(() => {
    if (currentUser && currentUser.Location) {
      setId(currentUser.Location.ID);
      setLocId(currentUser.Location.LOCID);
      setType(currentUser.Location.Type);
    }
  }, [currentUser]);

  useEffect(() => {
    if (type != null) {
      let short = currentUser.Location.Type;
      if (short === "Affiliate") {
        short = "A";
      } else if (type === "ParentCompany") {
        short = "PC";
      } else {
        short = "M";
      }
      setShortType(short);
      setIsUserLoaded(true);
    }
  }, [type, currentUser.Location.Type, shortType]);

  if (isUserLoaded) {
    if (type === "Member") {
      return (
        <div className="m-2">
          <ContractStatusMemberGrid id={id} locId={locId} />
        </div>
      );
    } else {
      return (
        <div className="m-2">
          <ContractStatusCorpAffilGrid
            type={type}
            locId={locId}
            shortType={shortType}
          />
        </div>
      );
    }
  } else return null;
};

export default ContractStatus;
