import "bootstrap/dist/css/bootstrap.css";
import React, { useContext } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
//import unregister from "./ServiceWorker";
import axios from "axios";
import UserContext from "./components/context/UserContext";

//const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

// inject authorization header using okta accesstoken to authorize request to api
axios.interceptors.request.use(
  function (config) {
    //console.log("axios interceptor - config", config);

    if (config.url.includes(process.env.REACT_APP_OKTA_URL)) {
      config.headers.Authorization = `${process.env.REACT_APP_CONFIGURE_HEADERS_AUTHORIZATION}`;
    } else {
      const okta = JSON.parse(localStorage.getItem("okta-token-storage"));
      const token = okta && okta.accessToken && okta.accessToken.accessToken;

      if (token != null) {
        config.headers.Authorization = `Bearer ${token}`;
        var currentUser = localStorage.getItem("currentUser")
          ? localStorage.getItem("currentUser")
          : "User has not been set yet";
          
        if (currentUser != "User has not been set yet") {
          let parsedUser = JSON.parse(currentUser);
          let StringifyiedUser = JSON.stringify({
            Info: parsedUser.Info,
            "Last location": parsedUser.lastLocation,
          });
          config.headers["Log-User"] = StringifyiedUser;
        } else {
          var temp = {"Msg": "User has not been set yet"};
          config.headers["Log-User"] = JSON.stringify(temp);
        }
      }
    }

    //config.headers["request-user-email"] = "jspurgeon@mhainc.com";
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

ReactDOM.render(<App />, rootElement);

//registerServiceWorker();
//unregister();
