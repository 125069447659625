import React, { useContext, useState, useEffect } from "react";
import UserContext from "./context/UserContext";
import Dashboard from "./sisense/Dashboard";
import useTrait from "./../hooks/useTrait";
import axios from "axios";
import { loadScript, isLoaded } from "./sisense/SiSenseScriptLoader";
import { LoadingSpinner } from "./layout/LoadingSpinner";

const Home = (props) => {
  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;
  const ltc = ["LTC/NURSING HOME PROVIDER", "OMNICARE"];
  const hasLocation = currentUser.Location ? true : false;
  const [sisenseLink, setSisenseLink] = useState();
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [filters, setFilters] = useState({});
  const [hasWaited, setWaited] = useState(false);
  console.log("Home - currentUser", userContext.currentUser);
  const [toggleable, setToggleable] = useState(false);
  const [ltcToggle, setToggle] = useState(true);
  const [cotList,setCOTs] = useState([])
  const firstClicked = useTrait(false)
  const [loading, setLoading] = useState(false);
  
    //useEffect(() => {
    //  if (isLoaded()) {
    //    setScriptLoaded(true);
    //  } else {
    //    loadScript().then(() => {
    //      setScriptLoaded(true);
    //    });
    //  }


    //}, [])
  function wait5seconds() {
      if (document.getElementById("test") != null) {
          
          document.getElementById("test").style.display = "none";
          setWaited(true);
      }
      else {
          
          pageLoadTimer()
      }
  }

  function pageLoadTimer() {
      if (currentUser.Location) {
          var initialLoadTimer;
          clearTimeout(initialLoadTimer);
          initialLoadTimer = setTimeout(wait5seconds, 9000);
      }
      // 1000 milliseconds = 1 second
  }

  
  useEffect(() => {
          pageLoadTimer();
  }, [])


  // useEffect(() => {

  //   if(currentUser.Location){
  //     let dim = "";
  //     let title = "";
  //     switch (currentUser.Location.Type) {
  //       case "Member":
  //         dim = "[MemberHierarchy_Sisense.MM_MMID]";
  //         title = "MMID";
  //         break;
  //       case "ParentCompany":
  //         dim = "[MemberHierarchy_Sisense.RP_RPID]";
  //         title = "RPID";
  //         break;
  //       case "Affiliate":
  //         dim = "[MemberHierarchy_Sisense.AFFILIATE_ID]";
  //         title = "AFFILIATE_ID";
  //         break;
  //     }
  //     const jaql = [
  //       {
  //         jaql: {
  //           title: title,
  //           dim: dim,
  //           datatype: "text",
  //           filter: {
  //             members: [currentUser.Location.LOCID],
  //           },
  //           t: false,
  //           l: false,
  //           r: false,
  //         },
  //       },
  //     ];
  //     setFilters(jaql);
  //   }
  // }, [currentUser.Location]);





  //first load
  useEffect(()=>{
    if(currentUser.Location != null){
      const cots = currentUser.Location.Type !== "Member" ? currentUser.Location.COTs.map(i=>i.Description)
      : [currentUser.Location.COT];
      setCOTs(cots);
      if(currentUser.Info != null && cots.some(r => ltc.includes(r)) && cots.some(r=> !ltc.includes(r))){
        setToggleable(true);
        
      }
    }

  },[])

  useEffect(()=>{
    if(toggleable){
      let isLTC = true;
        const maxCountCOT = currentUser.Location.COTs.reduce((a,b) => a.ActiveCount > b.ActiveCount ? a : b);
        if(!ltc.includes(maxCountCOT.Description))
        {
          isLTC = false;
        }
        if(currentUser.Dashboard){ 
          if(currentUser.Dashboard !== "LTC"){
            isLTC = false; 
          }else{
            isLTC = true;
          }
        };
  
        if(!isLTC){
          setToggle(false)
          document.getElementById("toggle").checked = true;
        }else{
          setToggle(true);
        }
    }
  },[toggleable])

 

  const settings = {
    showToolbar: false,
    showLeftPane: false,
    showRightPane: false,
  };

  const getFilters = () => {
    let dim = "";
    let title = "";
    if (currentUser.Location) {
      switch (currentUser.Location.Type) {
        case "Member":
          dim = "[MemberHierarchy_Sisense.MM_MMID]";
          title = "MMID";
          break;
        case "ParentCompany":
          dim = "[MemberHierarchy_Sisense.RP_RPID]";
          title = "RPID";
          break;
        case "Affiliate":
          dim = "[MemberHierarchy_Sisense.AFFILIATE_ID]";
          title = "AFFILIATE_ID";
          break;
      }
      const jaql = [
        {
          jaql: {
            title: title,
            dim: dim,
            datatype: "text",
            filter: {
              members: [currentUser.Location.LOCID],
            },
            t: false,
            l: false,
            r: false,
            embed:false
          },
        },
      ];
      return encodeURIComponent(JSON.stringify(jaql));
    } else {
      return [];
    }
  };

  const getDashboardLink = () => {
    let link = "";      
    if (currentUser.Location != null) {
      if (currentUser.Info != null && cotList.length > 0) {
        if (!cotList.some((r) => ltc.includes(r))) {
          switch (currentUser.Location.Type) {
            case "Member":
              link = process.env.REACT_APP_Dashboard_ASCD_M;
              break;
            case "ParentCompany":
              link = process.env.REACT_APP_Dashboard_ASCD_PC;
              break;
            case "Affiliate":
              link = process.env.REACT_APP_Dashboard_ASCD_A;
              break;
          }
        } else {
          if(toggleable){
            switch (currentUser.Location.Type) {
              case "Member":
                link =  ltcToggle ? process.env.REACT_APP_Dashboard_LTC_M :process.env.REACT_APP_Dashboard_ASCD_M;
                break;
              case "ParentCompany":
                link = ltcToggle ? process.env.REACT_APP_Dashboard_LTC_PC :process.env.REACT_APP_Dashboard_ASCD_PC;
                break;
              case "Affiliate":
                link = ltcToggle ? process.env.REACT_APP_Dashboard_LTC_A :process.env.REACT_APP_Dashboard_ASCD_A;
                break;
            } 
          }else{
            switch (currentUser.Location.Type) {
              case "Member":
                link = process.env.REACT_APP_Dashboard_LTC_M;
                break;
              case "ParentCompany":
                link = process.env.REACT_APP_Dashboard_LTC_PC;
                break;
              case "Affiliate":
                link = process.env.REACT_APP_Dashboard_LTC_A;
                break;
            }
          }
          
        }
      }
    }
    return link;
  };

  const toggle = (event) =>{
    event.preventDefault();
    setToggle(!ltcToggle);
    setLoading(true);
    const checked = document.getElementById("toggle").checked;
    document.getElementById("toggle").checked=!checked;
    saveConfigCache(!checked);
    setTimeout(()=>{setLoading(false)},3000);
  }

  const saveConfigCache = (checked) =>{
    const email = currentUser.Email;
    const dashboard = checked ? "ASCD" : "LTC";
    const config = {Dashboard:dashboard};
    currentUser.Dashboard = dashboard;
    userContext.onCurrentUserChanged(currentUser);
    var uri = `api/cache/userConfig/${email}/dashboard`;
    console.log(`azure cache - api call - ${uri}`);
    axios.post(uri, config).then(
      (result) => {
        console.log("config", result.data);
      },
      (error) => {
        console.error(error.message);
      }
    );
  }
    return (
    <React.Fragment>
          {loading && <LoadingSpinner isDataLoading="true"/>}
          {hasLocation ? (
              <div className="loading-dot-container" display={"block"} id="test" >
                  <div className="loading-dot-wrapper"  >
                      <div className="loading-dot"></div>
                  </div>
                  <div className="loading-dot-wrapper">
                      <div className="loading-dot delayed-animation"></div>
                  </div>
                  <div className="loading-dot-wrapper">
                      <div className="loading-dot slower-delayed-animation"></div>
                  </div>
              </div>  
          ) : (<div display={"none"}/>)
          }
            {toggleable && 
              <div className="dashboard-toggle" id="toggle-wrap">
                  <label>LTC</label>
                  <label className="switch" >
                      <input id="toggle" type="checkbox" />
                      <span className="slider round" onClick={toggle}></span>
                  </label>
                  <label>ASCD</label>
              </div>
            }
            <iframe
                width="100%"
                height="100%"
                frameBorder="0"
                src={process.env.REACT_APP_SisenseURI + `/app/main#/dashboards/${getDashboardLink()}?embed=true&filter=${getFilters()}&r=false&l=false&t=false`}
                display={hasWaited ? "block" : "none"}
            ></iframe>
                 

                  

        
          {/*
          <Dashboard
                  scriptLoaded={scriptLoaded}
                  iFrameId='sisense-frame'
                  filters={getFilters()}
                  //dashboard={getDashboardLink()}
                  dashboard={process.env.REACT_APP_SisenseURI + `/app/main#/dashboards/${getDashboardLink()}?embed=true&filter=${getFilters()}&r=false&l=false&t=false`}
                  settings={settings}
                  hidden={false} /> */}

    </React.Fragment>
  );
};

export default Home;
