import React, { useEffect, useState } from "react";
import { DataTable } from "mha-ui-kit-react-library/dist/index";
import { LoadingSpinner } from "./../../layout/LoadingSpinner";
import StatusDetailsModal from "./StatusDetailsModal";
import axios from "axios";
import StatusModal from "../../StatusModal";
import useStatusModal from "../../../hooks/useStatusModal";

const ContractStatusCorpAffilGrid = (props) => {
  const [isContractsLoaded, setIsContractsLoaded] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalUri, setModalUri] = useState(null);
  const { statusProps, handleErrorStatus } = useStatusModal();

  const { type, locId, shortType } = props;

  useEffect(() => {
    const uri = `api/contracts/PAStatus?locationId=${locId}&type=${shortType}`;
    console.log("corpAffil - useeffect - api call ", uri);
    axios.get(uri).then(
      (result) => {
        setIsContractsLoaded(true);
        setContracts(result.data);
      },
      (error) => {
        console.error(error.message);
        handleErrorStatus("An error occurred retrieving contract status data.");
        setIsContractsLoaded(true);
      }
    );
  }, [locId, shortType]);

  function handleStatusClick(contractNum, status) {
    const uri = `api/contracts/StatusDetails/${escape(
      contractNum
    )}?locationId=${locId}&type=${type}&status=${status}`;
    console.log("corpAffil - useeffect - api call ", uri);

    setShowModal(true);
    setModalUri(uri);
  }

  const handleClose = () => {
    setShowModal(false);
    setModalUri(null);
  };

  const columns = [
    {
      title: "Vendor",
      accessor: "Vendor",
    },
    {
      title: "Description",
      accessor: "Description",
    },
    {
      title: "Contract Number",
      accessor: "ContractNum",
      transform: (value, index, row) => {
        return (
          <React.Fragment>
            <button
              className="link-button"
              onClick={() => handleStatusClick(value, "All")}
            >
              {value}
            </button>
            {!row.Pricing && "*"}
          </React.Fragment>
        );
      },
    },
    {
      title: "",
      accessor: "Pricing",
      hidden: true,
    },
    {
      title: "Approved",
      accessor: "AP",
      transform: (value, index, row) => {
        return value > 0 ? (
          <button
            className="link-button"
            onClick={() => handleStatusClick(row["ContractNum"], "Approved")}
          >
            {value}
          </button>
        ) : (
          0
        );
      },
    },
    {
      title: "Pending",
      accessor: "P",
      transform: (value, index, row) => {
        return value > 0 ? (
          <button
            className="link-button"
            onClick={() => handleStatusClick(row["ContractNum"], "Pending")}
          >
            {value}
          </button>
        ) : (
          0
        );
      },
    },
    {
      title: "Denied",
      accessor: "D",
      transform: (value, index, row) => {
        return value > 0 ? (
          <button
            className="link-button"
            onClick={() => handleStatusClick(row["ContractNum"], "Denied")}
          >
            {value}
          </button>
        ) : (
          0
        );
      },
    },
  ];

  if (!isContractsLoaded) {
    return (
      <React.Fragment>
        <LoadingSpinner isDataLoading="true" />
            <StatusModal statusProps={statusProps} />
        <DataTable
          id="tblContracts"
          columns={columns}
          handleCellClick={() => {}}
        />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
            <StatusModal statusProps={statusProps}  />
        <StatusDetailsModal
                show={showModal}
                handleClose={handleClose}
                uri={modalUri}
                type={type}
        />
        <div className="float-right w-50 text-right">
          <p>
            Contract Access/Eligibility is determined by each manufacturer based
            on their internal parameters.
          </p>
          <p>* = Contact your account representative for more information.</p>
        </div>
        <DataTable
          id="tblContracts"
          rows={contracts}
          columns={columns}
          filterable
          exportable
          pagination
          handleCellClick={() => {}}
          freezeheader
        />
      </React.Fragment>
    );
  }
};

export default ContractStatusCorpAffilGrid;
