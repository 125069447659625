import React, { useState, useEffect } from "react";
import { Form, Col, Button } from "react-bootstrap";

export function DocUploadForm(props) {
    const { handleSubmit, docTypes, email } = props;
    const [form, setForm] = useState({
        Email:email,
    });
    const [fileNames, setFileNames] = useState([]);
    const handleChange = (event) => {
        const fieldName = event.target.name;
        let files = [];
        if(event.target.files){
            for(let i=0; i < event.target.files.length; i++){
                files.push(event.target.files[i]);
            }
        }
        const fieldVal =  event.target.files ?  files : event.target.value ;

        if(event.target.files && event.target.files.length > 1){
            let names = [];
            for(let i=0; i < event.target.files.length;i++){
                let name = event.target.files[i].name;
                names.push(name);
                
            }
            setFileNames(names);
        }
        setForm({ ...form, [fieldName]: fieldVal });
    }
    const submit = (event) => {
        event.preventDefault();
        handleSubmit(form);
    }

    useEffect(()=>{
        if(docTypes.length > 0 && form['DocType'] == null){
            setForm({ ...form, 'DocType': docTypes[0] });
        }
    },[docTypes, form])
    
    return (
        <Form onSubmit={submit} className="profile-member">
            <Form.Row>
                <Form.Group>
                    <Form.Label>Document Type</Form.Label>
                    <Form.Control as="select" name="DocType" onChange={handleChange} value={form['DocType']} required>
                        {docTypes.map((val,indx)=>{
                            return <option key={indx}>{val}</option>

                        })}
                    </Form.Control>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group>
                    <Form.File name="Files" onChange={handleChange} label="Upload" multiple required/>
                    {fileNames.length ?  
                            <Form.Label>Files: 
                            {fileNames.map((x, i)=> <span key={i}>{x} </span>)}
                            </Form.Label>

                        :null
                    }
                   
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group>
                    <Form.Label>Optional Text</Form.Label>
                    <Form.Control name="DocText" as="textarea" onChange={handleChange} value={form['DocText'] ?? ''}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Col sm="4">
                    <Button type="submit" className="w-100">Upload</Button>
                </Col>
            </Form.Row>
        </Form>
    )
   

}