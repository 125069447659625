import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";

require("datejs");

const GERSummaryForm = (props) => {
  const currentQuarter = parseInt(Date.today().toString("q"));
  const prevQuarters = [];
  const numQtrs = 8 + currentQuarter;

  let i;
  for (i = 0; i < numQtrs; i++) {
    prevQuarters.push(
      Date.today()
        .add({ months: -3 * i })
        .toString("yyyyq")
    );
  }

  const [beginQuarter, setBeginQuarter] = useState(prevQuarters[5]);

  function handleSubmit(e) {
    e.preventDefault();

    const form = e.target;
    const data = form.elements;

    props.handleSubmit(
      data.summaryBeginQtr.value,
      data.summaryEndQtr.value,
      data.summaryMeasure.value
    );
  }

  function handleReset(e) {
    const bq = prevQuarters[5];
    const endQuarterSelect = document.getElementById("summaryEndQtr");
    const measureSelect = document.getElementById("summaryMeasure");

    setBeginQuarter(bq);
    endQuarterSelect.selectedIndex = 0;
    measureSelect.selectedIndex = 0;

    Array.prototype.forEach.call(endQuarterSelect.options, (o) => {
      o.disabled = o.value < bq;
    });
  }

  function handleQuarterChange(e) {
    const bq = e.target.value;
    const endQuarterSelect = document.getElementById("summaryEndQtr");

    setBeginQuarter(bq);

    if (endQuarterSelect.value < bq) {
      endQuarterSelect.value = bq;
    }

    Array.prototype.forEach.call(endQuarterSelect.options, (o) => {
      o.disabled = o.value < bq;
    });
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Col className="col-lg-9 col-12">
          <Form.Row>
            <Form.Group
              as={Col}
              controlId="summaryBeginQtr"
              className="col-lg-3 col-sm-4 form-date"
            >
              <Form.Label>Beginning Quarter</Form.Label>
              <span className="date-wrap">
                <Form.Control
                  as="select"
                  value={beginQuarter}
                  onChange={handleQuarterChange}
                >
                  {prevQuarters.map((q) => (
                    <option key={q} value={q}>
                      {q.substring(0, 4)} Qtr {q[4]}
                    </option>
                  ))}
                </Form.Control>
              </span>
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="summaryEndQtr"
              className="col-lg-3 col-sm-4 form-date"
            >
              <Form.Label>Ending Quarter</Form.Label>
              <span className="date-wrap">
                <Form.Control as="select">
                  {prevQuarters.map((q, index) => (
                    <option key={q} value={q} disabled={index > 5}>
                      {q.substring(0, 4)} Qtr {q[4]}
                    </option>
                  ))}
                </Form.Control>
              </span>
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="summaryMeasure"
              className="col-lg-3 col-sm-4"
            >
              <Form.Label>Measure</Form.Label>
              <Form.Control as="select" required>
                <option value="">Select Measure</option>
                <option value="trends">
                  GER (Generic Effective Rate) Trends
                </option>
                <option value="average">
                  Avg Generic Reimbursement Per Claim
                </option>
              </Form.Control>
            </Form.Group>
            <Col className="col-lg-1 col-sm-2 col-12 reset-wrap" style={{marginTop:
          "2.3%"}}>
              {/* <label>&nbsp;</label> */}
              <Button onClick={handleReset} className="reset-btn">
                <i className="fa fa-undo"></i> Reset
              </Button>
            </Col>
            <Col className="col-lg-2 col-sm-2 col-12" style={{marginTop:
          "2.3%"}}>
              <Form.Label>&nbsp;</Form.Label>
              <Button type="submit" className="results">
                Show Results
              </Button>
            </Col>
          </Form.Row>
        </Col>
        <Col className="col-lg-3 col-12">
          <p>
            Please contact your Account Representative if data from earlier than
            2 years ago is required.
          </p>
        </Col>
      </Form.Row>
    </Form>
  );
};

export default GERSummaryForm;
