import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { DataTable } from "mha-ui-kit-react-library/dist/index";
import { LoadingSpinner } from "./../../layout/LoadingSpinner";
import StatusModal from "../../StatusModal";
import useStatusModal from "../../../hooks/useStatusModal";
import UserContext from "../../context/UserContext";

const PaperworkDetailsModal = (props) => {
  const [areDetailsLoaded, setAreDetailsLoaded] = useState(false);
  const [paperworkDetails, setPaperworkDetails] = useState([]);
    const [showDisclaimer, setShowDisclaimer] = useState(false);
    const [showDisclaimerDocuSign, setShowDisclaimerDocuSign] = useState(false);
    const [showDisclaimerDocuSignSendforSignature, setShowDisclaimerDocuSignSendforSignature] = useState(false);
  const [currentPaperwork, setCurrentPaperwork] = useState(null);
  const { statusProps, handleErrorStatus } = useStatusModal();

    const { id, uri } = props;

    const userContext = useContext(UserContext);
    const currentUser = userContext.currentUser;
    const name = currentUser.Name;
    const email = currentUser.Email;
    const recipientemail ="";

  // Keep the state at this level and pass it down as needed.
  const [isVisible, setIsVisible] = React.useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);

  useEffect(() => {
    if (uri != null) {
      console.log("PaperworkDetailsModal - api call - ", uri);
      axios.get(uri).then(
        (result) => {
          setAreDetailsLoaded(true);
          setPaperworkDetails(result.data);
        },
        (error) => {
          console.error(error.message);
          handleErrorStatus("An error occured retrieving paperwork data.");
          setAreDetailsLoaded(true);
        }
      );
    }
  }, [uri]);

  const handleClose = () => {
    setAreDetailsLoaded(false);
    props.handleClose();
  };

  function handleFileClick(paperwork) {
    console.log(paperwork);
      setCurrentPaperwork(paperwork);

      if (paperwork.Docusignable)
      {
          setShowDisclaimerDocuSign(true);
      }
      else { setShowDisclaimer(true); }

  }

    function handleDisclaimerDocuSignClose() {
        setShowDisclaimerDocuSign(false);
    }

    function handleDisclaimerDocuSignSendforSignatureClose() {
        setShowDisclaimerDocuSignSendforSignature(false);
    }

  function handleDisclaimerClose() {
      setShowDisclaimer(false);
    }

    const DisclaimerDocuSignModal = (props) => {
        const [reportLoading, setReportLoading] = useState(false);

        function getDocument2(paperwork, action, recipientemail) {
            setReportLoading(true);

            const uri = paperwork.Docusignable
                ? `api/contracts/DocusignLink?templateId=${paperwork.TemplateId}&id=${id}&username=${name}&email=${email}&App=MHAPortal&action=${action}&recipientemail=${recipientemail}`
                : `api/contracts/Paperwork?fileName=${paperwork.SemiFullPath}`;

            console.log("PaperworkDetailsModal - getDocument - api call", uri);

            if (paperwork.Docusignable) {
                axios.get(uri).then(
                    (response) => {
                        setReportLoading(false);
                        window.open(response.data, "_blank");
                    },
                    (error) => {
                        console.error(error.message);
                        handleErrorStatus("Could not get Docusign link.");
                        setReportLoading(false);
                    }
                );
            } else {
                axios(uri, {
                    method: "GET",
                    responseType: "blob", //Force to receive data in a Blob Format
                }).then(
                    (response) => {
                        setReportLoading(false);
                        window.open(URL.createObjectURL(response.data));
                    },
                    (error) => {
                        console.error(error.message);
                        handleErrorStatus("Could not retrieve document.");
                        setReportLoading(false);
                    }
                );
            }
        }
        return (
            <React.Fragment>
                <Modal show={reportLoading} centered>
                    <Modal.Body>
                        <p>Please wait while the report is loading...</p>
                    </Modal.Body>
                </Modal>
                <Modal show={props.show} onHide={() => props.handleClose()} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                          Disclaimer
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Additional documents may be required which your PAR will contact
                                                you about.
                        </p>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            onClick={() => {
                                setShowDisclaimerDocuSign(false);
                                setShowDisclaimerDocuSignSendforSignature(true);
                            }}
                        >Send for Signature
                    </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                getDocument2(currentPaperwork, "ViewandSign");
                                setShowDisclaimerDocuSign(false);
                            }}
                        >View and Sign
                    </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    };

    const DisclaimerDocuSignModalSendforSignature = (props) => {
        const [reportLoading, setReportLoading] = useState(false);

        function getDocument3(paperwork, action, recipientemail) {
            setReportLoading(true);

            const uri = paperwork.Docusignable
                ? `api/contracts/DocusignLink?templateId=${paperwork.TemplateId}&id=${id}&username=${name}&email=${email}&App=MHAPortal&action=${action}&recipientemail=${recipientemail}`
                : `api/contracts/Paperwork?fileName=${paperwork.SemiFullPath}`;

            console.log("PaperworkDetailsModal - getDocument - api call", uri);

            if (paperwork.Docusignable) {
                axios.get(uri).then(
                    (response) => {
                        setReportLoading(false);
                        alert('The email has been sent to the recipient!');
                        //window.open(response.data, "_blank");
                    },
                    (error) => {
                        console.error(error.message);
                        handleErrorStatus("Could not get Docusign link.");
                        setReportLoading(false);
                    }
                );
            } else {
                axios(uri, {
                    method: "GET",
                    responseType: "blob", //Force to receive data in a Blob Format
                }).then(
                    (response) => {
                        setReportLoading(false);
                        window.open(URL.createObjectURL(response.data));
                    },
                    (error) => {
                        console.error(error.message);
                        handleErrorStatus("Could not retrieve document.");
                        setReportLoading(false);
                    }
                );
            }
        }
        return (
            <React.Fragment>
                <Modal show={reportLoading} centered>
                    <Modal.Body>
                        <p>Please wait while the report is loading...</p>
                    </Modal.Body>
                </Modal>
                <Modal show={props.show} onHide={() => props.handleClose()} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Disclaimer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Additional documents may be required which your PAR will contact
                                                you about.
                        </p>

                        <label>Recipient Email Address:</label>
                        <input
                            type="text"
                            id="txtrecipientemail"
                            className="form-control"
                        />
                        <Button
                            variant="primary"
                            onClick={() => {
                                let recipemail = document.getElementById("txtrecipientemail").value;
                                getDocument3(currentPaperwork, "SendforSignature", recipemail);
                                props.handleClose();
                            }}
                        >Send
                        </Button>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    };

    const DisclaimerModal = (props) => {
    const [reportLoading, setReportLoading] = useState(false);

      function getDocument(paperwork, action, recipientemail) {
      setReportLoading(true);

      const uri = paperwork.Docusignable
          ? `api/contracts/DocusignLink?templateId=${paperwork.TemplateId}&id=${id}&username=${name}&email=${email}&App=MHAPortal&action=${action}&recipientemail=${recipientemail}`
        : `api/contracts/Paperwork?fileName=${paperwork.SemiFullPath}`;

      console.log("PaperworkDetailsModal - getDocument - api call", uri);

      if (paperwork.Docusignable) {
        axios.get(uri).then(
          (response) => {
            setReportLoading(false);
            window.open(response.data, "_blank");
          },
          (error) => {
            console.error(error.message);
            handleErrorStatus("Could not get Docusign link.");
            setReportLoading(false);
          }
        );
      } else {
        axios(uri, {
          method: "GET",
          responseType: "blob", //Force to receive data in a Blob Format
        }).then(
          (response) => {
            setReportLoading(false);
            window.open(URL.createObjectURL(response.data));
          },
          (error) => {
            console.error(error.message);
            handleErrorStatus("Could not retrieve document.");
            setReportLoading(false);
          }
        );
      }
      }
    return (
      <React.Fragment>
        <Modal show={reportLoading} centered>
          <Modal.Body>
            <p>Please wait while the report is loading...</p>
          </Modal.Body>
        </Modal>
        <Modal show={props.show} onHide={() => props.handleClose()} centered>
          <Modal.Header closeButton>
            <Modal.Title>Disclaimer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Additional documents may be required which your PAR will contact
                                  you about.
            </p>
          </Modal.Body>
                <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                getDocument(currentPaperwork);
                props.handleClose();
              }}
            >
              Okay
            </Button>
            <Button variant="secondary" onClick={() => props.handleClose()}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
    };

  const columns = [
    {
      title: "Path",
      accessor: "FilePath",
      transform: (value, index, row) => {
        return (
          <button className="link-button" onClick={() => handleFileClick(row)}>
            {value}
          </button>
        );
      },
    },
    {
      title: "Description",
      accessor: "Description",
    },
    {
      title: "Type",
      accessor: "Type",
    },
    //{
    //  title: "Docusignable",
    //  accessor: "Docusignable",
    //  transform: (value) => value.toString(),
    //},
  ];

  const DetailsGrid = () => {
    if (!areDetailsLoaded) {
      return (
        <React.Fragment>
          <LoadingSpinner isDataLoading="true" />
          <DataTable
            id="tblDetails"
            columns={columns}
                  handleCellClick={() => { }}

          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
              <DisclaimerModal
                  show={showDisclaimer}
                  handleClose={handleDisclaimerClose}
              />
              <DisclaimerDocuSignModal
                  show={showDisclaimerDocuSign}
                  handleClose={handleDisclaimerDocuSignClose}

              />
              <DisclaimerDocuSignModalSendforSignature
                  show={showDisclaimerDocuSignSendforSignature}
                  handleClose={handleDisclaimerDocuSignSendforSignatureClose}

              />
          <DataTable
            id="tblDetails"
            rows={paperworkDetails}
            columns={columns}
            filterable
            pagination
                  handleCellClick={() => { }}
                  freezeheader
          />
        </React.Fragment>
      );
    }
  };

  return (
    <React.Fragment>
      <StatusModal statusProps={statusProps} />
      <Modal
        show={props.show}
        onHide={() => handleClose()}
        // dialogClassName="modal-90w"
        size="lg"
        centered
      >
        <Modal.Header >
          <Modal.Title>
            <div className="row">
                <div className="col-11">
                  Paperwork Details
                </div>
                <div className="col-1 modal-x-container">
                  <i className ="fa fa-times modal-x" onClick={props.handleClose} ></i>
                </div>
            </div>
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DetailsGrid />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default PaperworkDetailsModal;
