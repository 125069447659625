import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";

require("datejs");

const GERTrendsForm = (props) => {
  const [showBrand, setShowBrand] = useState(false);
  const [showGeneric, setShowGeneric] = useState(false);
  const [showOverall, setShowOverall] = useState(false);
  const [isBrandChecked, setIsBrandChecked] = useState(false);
  const [isGenericChecked, setIsGenericChecked] = useState(false);
  const [isOverallChecked, setIsOverallChecked] = useState(false);

  const currentQuarter = parseInt(Date.today().toString("q"));
  const prevQuarters = [];
  const numQtrs = 8 + currentQuarter;

  let i;
  for (i = 0; i < numQtrs; i++) {
    prevQuarters.push(
      Date.today()
        .add({ months: -3 * i })
        .toString("yyyyq")
    );
  }

  const [beginQuarter, setBeginQuarter] = useState(prevQuarters[5]);

  function handleSubmit(e) {
    e.preventDefault();

    const data = e.target.elements;

    let drugType = "";
    data.drugType.forEach((cb) => {
      drugType += cb.checked ? cb.value : "";
    });

    if (drugType === "") {
      alert("Please check at least one drug type.");
      return;
    }

    props.handleSubmit(
      data.trendBeginQtr.value,
      data.trendEndQtr.value,
      data.trendMeasure.value,
      drugType
    );
  }

  function handleReset(e) {
    setIsBrandChecked(false);
    setIsGenericChecked(false);
    setIsOverallChecked(false);
    setShowBrand(false);
    setShowGeneric(false);
    setShowOverall(false);

    const bq = prevQuarters[5];
    const endQuarterSelect = document.getElementById("trendEndQtr");
    const measureSelect = document.getElementById("trendMeasure");

    setBeginQuarter(bq);
    endQuarterSelect.selectedIndex = 0;
    measureSelect.selectedIndex = 0;

    Array.prototype.forEach.call(endQuarterSelect.options, (o) => {
      o.disabled = o.value < bq;
    });
  }

  function handleQuarterChange(e) {
    const bq = e.target.value;
    const endQuarterSelect = document.getElementById("trendEndQtr");

    setBeginQuarter(bq);

    if (endQuarterSelect.value < bq) {
      endQuarterSelect.value = bq;
    }

    Array.prototype.forEach.call(endQuarterSelect.options, (o) => {
      o.disabled = o.value < bq;
    });
  }

  function enableCheckBoxes(e) {
    const measure = e.target.value;
    switch (measure) {
      case "0":
        setShowBrand(false);
        setShowGeneric(false);
        setShowOverall(false);
        setIsBrandChecked(false);
        setIsGenericChecked(false);
        setIsOverallChecked(false);
        break;
      case "trend":
        setShowBrand(false);
        setShowGeneric(false);
        setShowOverall(false);
        setIsBrandChecked(false);
        setIsGenericChecked(true);
        setIsOverallChecked(false);
        break;
      case "discount":
        setShowBrand(false);
        setShowGeneric(false);
        setShowOverall(false);
        setIsBrandChecked(true);
        setIsGenericChecked(false);
        setIsOverallChecked(false);
        break;
      case "claim":
        setShowBrand(true);
        setShowGeneric(true);
        setShowOverall(false);
        setIsBrandChecked(false);
        setIsGenericChecked(false);
        setIsOverallChecked(false);
        break;
      default:
        setShowBrand(true);
        setShowGeneric(true);
        setShowOverall(true);
        setIsBrandChecked(false);
        setIsGenericChecked(false);
        setIsOverallChecked(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Col className="col-lg-9 col-12">
          <Form.Row>
            <Form.Group
              as={Col}
              controlId="trendBeginQtr"
              className="col-lg-3 col-sm-4 form-date"
            >
              <Form.Label>Beginning Quarter</Form.Label>
              <span className="date-wrap">
                <Form.Control
                  as="select"
                  value={beginQuarter}
                  onChange={handleQuarterChange}
                >
                  {prevQuarters.map((q) => (
                    <option key={q} value={q}>
                      {q.substring(0, 4)} Qtr {q[4]}
                    </option>
                  ))}
                </Form.Control>
              </span>
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="trendEndQtr"
              className="col-lg-3 col-sm-4 form-date"
            >
              <Form.Label>Ending Quarter</Form.Label>
              <span className="date-wrap">
                <Form.Control as="select">
                  {prevQuarters.map((q, index) => (
                    <option key={q} value={q} disabled={index > 5}>
                      {q.substring(0, 4)} Qtr {q[4]}
                    </option>
                  ))}
                </Form.Control>
              </span>
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="trendMeasure"
              className="col-lg-3 col-sm-4"
            >
              <Form.Label>Measure</Form.Label>
              <Form.Control as="select" required onChange={enableCheckBoxes}>
                <option value="">Select Measure</option>
                <option value="trend">GER Trend</option>
                <option value="discount">AWP Discount Trend</option>
                <option value="claim">Avg AWP per claim</option>
                <option value="reimbursement">Avg Reimbursement per claim</option>
                <option value="cost">Avg Acquisition Cost per claim</option>
                <option value="profit">Avg Gross Profit per claim</option>
                <option value="supply">Avg Days Supply per claim</option>
              </Form.Control>
            </Form.Group>
            <Col className="col-lg-1 col-sm-2 col-12 reset-wrap" style={{marginTop:
          "2.3%"}}>
              {/* <label>&nbsp;</label> */}
              <Button onClick={handleReset} className="reset-btn">
                <i className="fa fa-undo"></i> Reset
              </Button>
            </Col>
            <Col className="col-lg-2 col-sm-2 col-12" style={{marginTop:
          "2.3%"}}>
              <Form.Label>&nbsp;</Form.Label>
              <Button type="submit" className="results" >
                Show Results
              </Button>
            </Col>
          </Form.Row>
        </Col>
        <Col className="col-lg-3 col-12">
          <p>
            Please contact your Account Representative if data from earlier than
            2 years ago is required.
          </p>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Check
            inline
            disabled={!showBrand}
            checked={isBrandChecked}
            label="Brand"
            type="checkbox"
            id="drugBrand"
            name="drugType"
            value="Brand"
            onClick={() => setIsBrandChecked(!isBrandChecked)}
          />
          <Form.Check
            inline
            disabled={!showGeneric}
            checked={isGenericChecked}
            label="Generic"
            type="checkbox"
            id="drugGeneric"
            name="drugType"
            value="Generic"
            onClick={() => setIsGenericChecked(!isGenericChecked)}
          />
          <Form.Check
            inline
            disabled={!showOverall}
            checked={isOverallChecked}
            label="Overall"
            type="checkbox"
            id="drugOverall"
            name="drugType"
            value="Overall"
            onClick={() => setIsOverallChecked(!isOverallChecked)}
          />
        </Col>
      </Form.Row>
    </Form>
  );
};

export default GERTrendsForm;
