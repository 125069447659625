import React, { useState, useEffect } from "react";
import axios from 'axios';
import {ContentItem, Elements, TypeResolver, DeliveryClient} from '@kentico/kontent-delivery';
import PrimaryOnly from "./Models/PrimaryOnly";
import Vimeo from "./Models/Vimeo";
import CardRow from "./Models/CardRow";
import Card from "./Models/Card";
import Banner from "./Models/Banner";
import ContentLink from "./Models/ContentLink";

import {
    Link,
    Route,
    HashRouter,
    useLocation
} from "react-router-dom";
import "./Content.css";

const Content = (props) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [menufinal, setDynamicMenu] = useState([]);
    const [contentfinal, setContent] = useState([]);
    const [hash, setHash] = useState('');
    let data = useLocation();
    let url = data.pathname;
    const navItem = data.hash.split("/").pop();
    var pathCodename = url.substring(url.indexOf('{') + 1).split('/')[0].replace('}}', '').replace('{', '');
    //var isSecondaryASCD = url.substring(url.lastIndexOf('{') + 1).replace('}}', '');

    



    var secondaryindex = nthIndex(url, "{", 4)

    var ltcindex = nthIndex(url, "{", 6)

    function nthIndex(str, pat, n) {
        var L = str.length, i = -1;
        while (n-- && i++ < L) {
            i = str.indexOf(pat, i);
            if (i < 0) break;
        }
        return i;
    }

    const setPage = (e) => {
        const page = e.target.textContent;
        localStorage.setItem('content-page', page);
    }

    var isSecondaryASCD = url.substring(secondaryindex + 1).split('}')[0];

    var isLTC = url.substring(ltcindex + 1).split('}')[0];
    const COT  = isLTC === "false" ?  "ASCD" : "LTC";


    const userAccess = isSecondaryASCD  === "false" ? "Primary" : "Secondary";
    const params = {
        COT,
        userAccess,
        pathCodename
    }
    const deliveryClient = new DeliveryClient({
        projectId:'b12ddcf2-e08f-0041-a439-0060fc80eb9c',
        defaultLanguage: 'en-US',
        typeResolvers:[
            new TypeResolver('banner', (rawData) => new Banner()),
            new TypeResolver('cardrow', (rawData) => new CardRow()),
            new TypeResolver('card', (rawData) => new Card(params)),
            new TypeResolver('primaryonly', (rawData) => new PrimaryOnly(params)),
            new TypeResolver('vimeo', (rawData) => new Vimeo()),
            new TypeResolver('contentlink', (rawData) => new ContentLink(params)),
        ]
    })
    



    const  Content = ({ headertext, bodytext, type, codename }) => {

        const [text, setText] = useState(bodytext);

        
       
        useEffect( ()=>{
            if(type === "header_and_text__creative_copy___copy_"){
                let colNum = 2;
                const subscription =  deliveryClient.item(codename)
                .toObservable()
                .subscribe(response => {
                    let html = '';
                    if (COT === "ASCD" && isSecondaryASCD) {
                        if ( response.item.altsitebodytext.value !== "<p><br></p>" && response.item.altsitebodytext.value) {
                            html=response.item.altsitebodytext.resolveHtml();
                        }
                        else {
                            html=response.item.bodytext.resolveHtml();
                        }
                    }
                    else {
                        html=response.item.bodytext.resolveHtml();
                    }
                    html = html.replaceAll("<a", "<a target='_blank'")
                    setText(html);
                });
                return function cleanup() {
                    subscription.unsubscribe();
                }
            }            
        },[])
        return (
            <React.Fragment>
                < div className="contentbody" dangerouslySetInnerHTML={{ __html: text }}></div>
            </React.Fragment>
        );       
       
    };

    useEffect(() => {
        const abortController = new AbortController()
        
        axios

            // .get(process.env.REACT_APP_KENTICO_KONTENT_API_URL)
            .get("/api/content")

            .then(
                (result) => {
                    setIsLoaded(true);
                    const DEPTH_LIMIT = 1;
                    let menudynamic = [];
                    let contentdynamic = [];

                    var count = 0;
                    var result = result.data;

                    // Process the retrieved data using the itemsToList function and display it on the page.
                    function processData(data) {

                        for (var i = 0; i < data.items.length; i++) {

                            if (data.items[i].system.codename == pathCodename) {
                                //This will be the index of the page being passed in
                                let rootMenuItems = data.items[i].elements.relationships__pages.value;
                                
                                let currentpagecodename = data.items[i].system.codename;

                                let allLinkedContent = []
                                allLinkedContent = data.modular_content;

                                //Put current page on the menu items list
                                //Loop through the list items and push if it does not exist
                                var exists = 0;

                                for (var j = 0; j < rootMenuItems.length; j++) {
                                    if (rootMenuItems[j] == currentpagecodename) {
                                        exists = 1;
                                    }
                                }

                             

                                if (exists == 0) {
                                    rootMenuItems.unshift(currentpagecodename);
                                }
                                
                                itemsToListNavLink(rootMenuItems, allLinkedContent, 0, userAccess, COT, currentpagecodename);
                                
                            }
                        }
                    }

                    processData(result);

                    function cleanText(bodytext) {
                        bodytext = bodytext.replaceAll(
                            "<span><a target='_blank' class=\"disabledlink\"",
                            "<span title='Contact Your Account Representative'><a target='_blank' class=\"disabledlink\""
                        );

                        bodytext = bodytext.replaceAll(
                            "<span><a class=\"hidelink\"", "<span class=\"spanhidebr\"><a class=\"hidelink\""
                        );

                        bodytext = bodytext.replaceAll("https://https%3a%2f%2f", "https://")
                        bodytext = bodytext.replaceAll("%2f/", "/")

                        bodytext = bodytext.replaceAll("https://https%3a%2f%2f", "https://")
                        bodytext = bodytext.replaceAll("%2f/", "/")
                     
                        bodytext = bodytext.replaceAll("display:none;", "")
                        //p to div
                        // bodytext = bodytext.replaceAll("</p>","</div></div>")
                        return bodytext
                    }

                    function itemsToListNavLink(itemCodenames, linkedContent, currentDepth, userAccess, COT, currentpagecodename) {

                        if (currentDepth >= DEPTH_LIMIT) {
                            return "";
                        }

                        for (let itemCodename of itemCodenames) {
                            let headertext = linkedContent[itemCodename].elements.headertext.value;

                            if ((COT === "ASCD") & (headertext === "Long Term Care Resources")) {

                                headertext = "Alternate Site Resources";
                            }
                            let bodytext = "";

                            let type = linkedContent[itemCodename].system.type;
                            if (COT === "ASCD") {

                                if (linkedContent[itemCodename].elements.altsitebodytext.value !== "" && linkedContent[itemCodename].elements.altsitebodytext.value !== '<p><br></p>') {

                                    bodytext = linkedContent[itemCodename].elements.altsitebodytext.value;
                                    //if (headertext == "Long Term Care Resources") {
                                    //    headertext = "Alternate Site Resources";
                                    //}
                                }
                                else {
                                    bodytext = linkedContent[itemCodename].elements.bodytext.value;
                                }
                            }
                            else {
                                bodytext = linkedContent[itemCodename].elements.bodytext.value;
                            }
                          

                            //bodytext = bodytext.replaceAll("<br />","")
                            bodytext = bodytext.replaceAll("<a", "<span><a target='_blank'");
                            bodytext = bodytext.replaceAll("</a>", "</a></span>");
                           
                            

                            //Logic to replace submenu tags with actual URL with isLTC and isSecondaryASCD 
                            bodytext = bodytext.replaceAll("<div submenulink=\"", "<a target='_self' href=\"" + url.split('#/')[0] + "#/");

                            bodytext = cleanText(bodytext);
                            //console.log(bodytext);

                            //Logic for hiding cards when date expires
                            let re = /publishto=/g;

                            var match = /publishto=/.exec(bodytext);

                            while ((match = re.exec(bodytext)) != null) {
                                //console.log("match found at " + match.index);

                                var pdate = bodytext.charAt(match.index + 11) +
                                    bodytext.charAt(match.index + 12) +
                                    bodytext.charAt(match.index + 13) +
                                    bodytext.charAt(match.index + 14) +
                                    bodytext.charAt(match.index + 15) +
                                    bodytext.charAt(match.index + 16) +
                                    bodytext.charAt(match.index + 17) +
                                    bodytext.charAt(match.index + 18) +
                                    bodytext.charAt(match.index + 19) +
                                    bodytext.charAt(match.index + 20);

                                pdate = pdate.replace(/\"/g, "");

                                var publishto = new Date(pdate);

                                var currentdate = new Date();

                                if (currentdate > publishto) {
                                    bodytext = bodytext.replace("class=" + "\"d-flex align-items col-sm-6" + "\"" + " publishto=" + "\"" + pdate + "\"", "style='display:none'");
                                }
                            }

                            let name = linkedContent[itemCodename].system.name;
                            let codename = linkedContent[itemCodename].system.codename;
                            
                            const categories = linkedContent[codename].elements.categories.value.map(x=>x.name);
                            if (currentpagecodename === codename) {
                                if ((COT === "ASCD") & (name === "Long Term Care Resources")) {
                                    name = "Alternate Site Resources";
                                    document.getElementById("feature-title").innerHTML = "Alternate Site Resources";
                                }
                                else {
                                    document.getElementById("feature-title").innerHTML = name;
                                }
                                //menudynamic.push(<React.Fragment key={count}><Link to={`/${codename}`} className='enabledhllink'>Home</Link></React.Fragment>);
                                menudynamic.push(<React.Fragment key={count}><Link to={`/${codename}`} id={codename} className='enabledhllink'>Overview</Link></React.Fragment>);
                            }
                            else{
                                if(categories.length && !categories.includes(COT)){
                                    continue;
                                    //logic for secondary not set
                                }else if(userAccess !== "Primary" && 1==2){
                                    menudynamic.push(<React.Fragment key={count}><span title='Contact Your Account Representative'><Link to={`/${codename}`} id={codename} className='disabledllink'>{name}</Link></span></React.Fragment>);
                                } 
                                else{
                                    menudynamic.push(<React.Fragment key={count}><Link to={ {pathname:`/${codename}`}  } onClick={setPage}  id={codename} className='enabledllink'>{name}</Link></React.Fragment>);
                                }
                            };


                            contentdynamic.push(
                                <React.Fragment key={count}><Route path={`/${codename}`} component={() =>
                                    <Content name={name} headertext={headertext} bodytext={bodytext} type={type} codename={codename}/>} />
                                </React.Fragment>);
                            count += 1;
                        }

                    }

                    //console.log(menudynamic);
                    setDynamicMenu(menudynamic);

                    //console.log(contentdynamic);
                    setContent(contentdynamic);

                    document.getElementById(navItem) ? document.getElementById(navItem).classList.add("content-highlighted") : console.log(`no element found for: ${navItem}`);

                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );

        return function cleanup() {
            abortController.abort()
        }

    }, []);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

        return (
            <HashRouter>
                <div className="container custom" >
                    <div className="row">
                        <div className="col-3 min-vh-100 contentmenu" >
                            {menufinal}
                        </div>
                        <div className="col-9">
                            <div className="content">
                                {contentfinal}
                            </div>
                        </div>
                    </div>
                </div>

            </HashRouter>
        );
       
    }
};


export default Content;