import React, { useState } from "react";
import { Form, Col, Button, InputGroup } from "react-bootstrap";
import { DataTable } from "mha-ui-kit-react-library/dist/index";
import "mha-ui-kit-react-library/dist/styles.css";

export function MemberForm(props) {
  const {
    CurrentLocation: currentMember,
    PreviousLocation: previousMember,
    AllStates: states,
    AllSoftware: software,
  } = props.data;
  const { canEdit, handleSubmit, handleCancel } = props;
  const [form, setForm] = useState(currentMember);
  const highlight = (key) => {
    return previousMember && previousMember[key] !== currentMember[key]
      ? "highlight"
      : "";
  };

  const formatDate = (date) => {
    if (date) {
      var convertDate = new Date(date);
      var month =
        convertDate.getMonth() < 10
          ? "0" + (convertDate.getMonth() + 1)
          : convertDate.getMonth() + 1;
      var day =
        convertDate.getDate() < 10
          ? "0" + convertDate.getDate()
          : convertDate.getDate();
      //return convertDate.getFullYear() + "-" + month + "-" + day
      return month + "/" + day + "/" + convertDate.getFullYear();
    } else {
      return "";
    }
  };

  const formatPhone = (value) => {
    value = value.replace(/\D/g, "");
    if (value.length > 3 && value.length <= 6)
      value = value.slice(0, 3) + "-" + value.slice(3);
    else if (value.length > 6)
      value =
        value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6);

    return value;
  };

  const pharmacyHeaders = [
    {
      title: "ID",
      accessor: "ID",
    },
    {
      title: "License#",
      accessor: "LicenseNumber",
    },
    {
      title: "State",
      accessor: "State",
    },
    {
      title: "Exp Date",
      accessor: "ExpirationDate",
      transform: (value) => {
        return value == null
          ? null
          : new Date(Date.parse(value)).toString("MMM dd, yyyy");
      },
    },
    {
      title: "Mod Stamp",
      accessor: "ModStamp",
      transform: (value) => {
        return value == null
          ? null
          : new Date(Date.parse(value)).toString("MMM dd, yyyy");
      },
    },
    {
      title: "Mod User",
      accessor: "ModUser",
    },
  ];

  const disabled = (key) => {
    // return (previousMember && previousMember[key] !== currentMember[key]) || !canEdit ? {disabled:true} :{disabled:false}
    return { disabled: true };
  };

  const formValue = (attr) => {
    if (attr.includes("Date")) {
      return formatDate(form[attr] ?? "");
    }
    if (["Phone", "Fax"].includes(attr)) {
      return formatPhone(form[attr] ?? "");
    }
    return form[attr] ?? "";
  };

  const maxLength = (key) => {
    if (["Phone", "Fax", "Zip"].includes(key)) {
      return { maxLength: 14 };
    } else {
      return { maxLength: 255 };
    }
  };

  const wholesalersHeaders = [{ title: "Wholesaler Name", accessor: "Name" }];

  const handleChange = (event) => {
    let fieldName = event.target.name;
    let fieldVal = event.target.value;
    if (["Phone", "Fax"].includes(fieldName)) {
      fieldVal = event.target.value.replace(/\D/g, "");
    }
    setForm({ ...form, [fieldName]: fieldVal });
  };
  const submit = (event) => {
    event.preventDefault();
    handleSubmit(form);
  };

  const cancel = () => {
    setForm(currentMember);
    handleCancel();
  };

  const softwareCount = () => {
    const length = form["Softwares"]
      ? form["Softwares"].length
      : previousMember["Softwares"].length;
    return length > 1 ? `(${length})` : null;
  };

  return (
    <Form onSubmit={submit} className="profile-member">
      <Form.Row>
        <Form.Group as={Col} sm="5">
          <Form.Label>Name</Form.Label>
          <Form.Control
            name="Name"
            className={highlight("Name")}
            {...disabled("Name")}
            value={formValue("Name")}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} sm="2">
          <Form.Label>Phone</Form.Label>
          <Form.Control
            name="Phone"
            type="tel"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            className={highlight("Phone")}
            {...disabled("Phone")}
            value={formValue("Phone")}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} sm="2">
          <Form.Label>DEA</Form.Label>
          <Form.Control
            name="DEA"
            className={highlight("DEA")}
            {...disabled("DEA")}
            value={formValue("DEA")}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} sm="3">
          <Form.Label>DEA End Date</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <i className="fa fa-calendar"></i>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              name="DEADate"
              className={highlight("DEADate")}
              {...disabled("DEADate")}
              value={formValue("DEADate")}
              onChange={handleChange}
            />
          </InputGroup>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm="5">
          <Form.Label>d/b/a</Form.Label>
          <Form.Control
            name="AKA"
            className={highlight("AKA")}
            {...disabled("AKA")}
            value={formValue("AKA")}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} sm="2">
          <Form.Label>Fax</Form.Label>
          <Form.Control
            name="Fax"
            type="tel"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            className={highlight("Fax")}
            {...disabled("Fax")}
            value={formValue("Fax")}
            onChange={handleChange}
            {...maxLength("Fax")}
          />
        </Form.Group>
        <Form.Group as={Col} sm="2">
          <Form.Label>NCPDP</Form.Label>
          <Form.Control
            name="NCPDP"
            className={highlight("NCPDP")}
            {...disabled("NCPDP")}
            value={formValue("NCPDP")}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} sm="3">
          <Form.Label>NCPDP Start Date</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <i className="fa fa-calendar"></i>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              name="NCPDPDate"
              className={highlight("NCPDPDate")}
              {...disabled("NCPDPDate")}
              value={formValue("NCPDPDate")}
              onChange={handleChange}
            />
          </InputGroup>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm="5">
          <Form.Label>Corporate</Form.Label>
          <Form.Control
            name="Parent"
            className={highlight("Parent")}
            {...disabled("Parent")}
            value={formValue("Parent")}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} sm="2">
          <Form.Label>Medicare Id</Form.Label>
          <Form.Control
            name="MedicareId"
            className={highlight("MedicareId")}
            {...disabled("MedicareId")}
            value={formValue("MedicareId")}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} sm="2">
          <Form.Label>NPI</Form.Label>
          <Form.Control
            name="NPI"
            className={highlight("NPI")}
            {...disabled("NPI")}
            value={formValue("NPI")}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} sm="3">
          <Form.Label>NPI Start Date</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <i className="fa fa-calendar"></i>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              name="NPIDate"
              className={highlight("NPIDate")}
              {...disabled("NPIDate")}
              value={formValue("NPIDate")}
              onChange={handleChange}
            />
          </InputGroup>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm="5">
          <Form.Label>Address 1</Form.Label>
          <Form.Control
            name="Address1"
            className={highlight("Address1")}
            {...disabled("Address1")}
            value={formValue("Address1")}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} sm="2">
          <Form.Label>Beds #</Form.Label>
          <Form.Control
            name="NumBeds"
            className={highlight("NumBeds")}
            {...disabled("NumBeds")}
            value={formValue("NumBeds")}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} sm="2">
          <Form.Label>Software {softwareCount()}</Form.Label>
          <Form.Control
            name="Software"
            as="select"
            className={highlight("Software")}
            {...disabled("Software")}
            defaultValue={formValue("Software")}
            onChange={handleChange}
            {...maxLength("Name")}
          >
            {software.map((v, i) => {
              return <option key={i}>{v}</option>;
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} sm="3">
          <Form.Label>Software Start Date</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <i className="fa fa-calendar"></i>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className={highlight("SoftwareDate")}
              {...disabled("SoftwareDate")}
              value={formValue("SoftwareDate")}
              onChange={handleChange}
            />
          </InputGroup>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm="5">
          <Form.Label>Address 2</Form.Label>
          <Form.Control
            name="Address2"
            className={highlight("Address2")}
            {...disabled("Address2")}
            value={formValue("Address2")}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} sm="2">
          <Form.Label>Fed Tax Id</Form.Label>
          <Form.Control
            name="FedTaxId"
            className={highlight("FedTaxId")}
            {...disabled("FedTaxId")}
            value={formValue("FedTaxId")}
            onChange={handleChange}
          />
        </Form.Group>

        {/* {currentMember.COT !== "LTC/NURSING HOME PROVIDER" && currentMember.COT !== "OMNICARE" ? */}
        <Form.Group as={Col} sm="2">
          <Form.Label>HIN</Form.Label>
          <Form.Control
            name="HIN"
            className={highlight("HIN")}
            {...disabled("HIN")}
            value={formValue("HIN")}
            onChange={handleChange}
          />
        </Form.Group>
        {/* : null
                } */}

        {/* {currentMember.COT !== "LTC/NURSING HOME PROVIDER" && currentMember.COT !== "OMNICARE" && currentMember.HINDate == null ? */}

        <Form.Group as={Col} sm="3">
          <Form.Label>HIN Start Date</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <i className="fa fa-calendar"></i>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              name="HINDate"
              className={highlight("HINDate")}
              {...disabled("HINDate")}
              value={formValue("HINDate")}
              onChange={handleChange}
            />
          </InputGroup>
        </Form.Group>
        {/* </Form.Group> : null
                    //<Form.Group as={Col} sm="3">
                    //    <Form.Label>HIN Start Date</Form.Label>
                    //    <Form.Control name='HINDate' className={highlight('HINDate')} {...disabled('HINDate')} value={formValue('HINDate')} onChange={handleChange} type="date" />
                    //</Form.Group>
                } */}
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm="2">
          <Form.Label>City</Form.Label>
          <Form.Control
            name="City"
            className={highlight("City")}
            {...disabled("City")}
            value={formValue("City")}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} sm="2">
          <Form.Label>State</Form.Label>
          <Form.Control
            as="select"
            name="state"
            className={highlight("State")}
            {...disabled("State")}
            defaultValue={formValue("State")}
            onChange={handleChange}
          >
            {states.map((val, indx) => {
              return <option key={indx}>{val.Abbreviation}</option>;
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} sm="1">
          <Form.Label>Zip</Form.Label>
          <Form.Control
            name="Zip"
            className={highlight("Zip")}
            {...disabled("Zip")}
            value={formValue("Zip")}
            onChange={handleChange}
            {...maxLength("Name")}
          />
        </Form.Group>
        <Form.Group as={Col} sm="2">
          <Form.Group>
            <Form.Label>Medicaid ID</Form.Label>
            <Form.Control
              name="MedicaidId"
              className={highlight("MedicaidId")}
              {...disabled("MedicaidId")}
              value={formValue("MedicaidId")}
              onChange={handleChange}
            />
          </Form.Group>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm="6">
          <Form.Group>
            <Form.Label>URL</Form.Label>
            <Form.Control
              name="Http"
              className={highlight("Http")}
              {...disabled("Http")}
              value={formValue("Http")}
              onChange={handleChange}
            />
          </Form.Group>
          </Form.Group>
        <Form.Group as={Col} sm="6">
            <Form.Group>
                <Form.Label>COT</Form.Label>
                <Form.Control
                    name="COT"
                    className={highlight("COT")}
                    {...disabled("COT")}
                    value={formValue("COT")}
                    onChange={handleChange}
                />
            </Form.Group>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm="12">
          <Form.Label className="profile-member-grid-label">
            Pharmacy Licenses
          </Form.Label>
          <DataTable
            columns={pharmacyHeaders}
            rows={currentMember.PharmacyLicenses}
            id="pharmacyLicences"
                      handleCellClick={() => { }}
                      freezeheader
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm="6">
          <Form.Label className="profile-member-grid-label">
            Active Wholesalers
          </Form.Label>
          <DataTable
            columns={wholesalersHeaders}
            rows={currentMember.ActiveWholesalers}
            id="pharmacyLicences"
                      handleCellClick={() => { }}
                      freezeheader
          />
        </Form.Group>
      </Form.Row>
      <Form.Row className="justify-content-end">
        <Col sm="2">
          <Button onClick={cancel} className="w-100">
            Close
          </Button>
        </Col>
      </Form.Row>
      {/*
                <Form.Row className="justify-content-center">
                    <Col sm="4">
                        <Button onClick={cancel} className="cancel w-100">
                            Cancel
                    </Button>
                    </Col>
                    <Col sm="4">
                        <Button type="submit" className="w-100">
                            Save
                    </Button>
                    </Col>

                </Form.Row>
            */}
    </Form>
  );
}
