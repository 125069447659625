import React from "react";
import "../login.css";
import Logo from "../images/logo-mha.svg";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: "", errorInfo: "", hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    // You can also log error messages to an error reporting service here
    console.log("Error Boundary: ", { error, errorInfo });
    this.setState({ errorInfo });
  }

  handleHomeClick() {
    window.location = "/reset";
  }

  render() {
    const { hasError, error, errorInfo } = this.state;
    console.log("error boundary render - hasError", hasError);
    if (hasError) {
      console.log("Error Boundary: ", { error, errorInfo });
      // Error path
      return (
        <div id="div-login-container" className="text-center">
          <img src={Logo} alt="MHA logo" />
          <div className="text-left">
            <h2 className="mt-3">Application Error</h2>
            <p>
              There was an error loading this page. Please click below to return
              to the home page.
            </p>
            <div className="mt-3">
              <button
                className="btn btn-primary"
                onClick={this.handleHomeClick}
              >
                Return to Home Page
              </button>
            </div>
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
