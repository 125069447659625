import React, { useState, useEffect } from "react";
import Layout from "./../layout/Layout";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";

import "font-awesome/css/font-awesome.min.css";
import "./../Status.css";

export default function Unauthorized(props) {
  const [show, setShow] = useState(true);
  let history = useHistory();
  const handleHide = () => {
    setShow(false);
  };
  useEffect(() => {
    setTimeout(() => {
      history.goBack();
    }, 10000);
  }, [history]);
  return (
    <Layout>
      <Modal
        show={show}
        dialogClassName="unauthorized-modal-dialog"
        centered
        onHide={handleHide}
        backdrop="static"
        backdropClassName="unauthorized-modal-backdrop"
        className="unauthorized-modal"
      >
        <Modal.Header className="error-header">
          <i className="fa fa-times-circle"></i> Error
        </Modal.Header>
        <Modal.Body>
          <h3>HTTP ERROR 401: Unauthorized Access</h3>
          <p>
            You have attempted to access a page for which you are not
            authorized.
          </p>
          <br />
          <p>
            This page will automatically return to the previous page in 10
            seconds. But if you do not wish to wait, you can use the navigation
            provided to go to the page of your choice.
          </p>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}
