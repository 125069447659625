import React, {  useState, useEffect }from "react";
import {  Row,  Button, } from "react-bootstrap";
import "./Footer.css";
import "../../custom.css";
import "font-awesome/css/font-awesome.min.css";

import { CookieBanner } from "./CookieBanner"
import "./CookieBanner.css"


export function Footer(props) {

    const [cookieHasBeenAccepted, setCookieHasBeenAccepted] = useState(false);
    const [displayStyle, setDisplayStyle] = useState(false);
    

    function getCookie(key) {
        var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
        if (keyValue != null) {
            if (keyValue[0].includes("footerHidden")) {
                setCookieHasBeenAccepted(true);
            }
            else {
                setCookieHasBeenAccepted(false);
            }
        }
    }

    function updateFooter() {
        setCookie('footerHidden', 'true', 10);
        getCookie("footerHidden")
        setDisplayStyle(true);
    }


    function setCookie(key, value, expiry) {
        var expires = new Date();
        expires.setTime(expires.getTime() + expiry * 24 * 60 * 60 * 1000);
        document.cookie = key + '=' + value + ';path=/;expires=' + expires.toUTCString();
    }

  const footerLinks = [
    {
      name: "Terms & Conditions",
      link:
        "https://www.mhainc.com/About_MHA/Ethics_Privacy/#TermsAndConditions",
      icon: "fa fa-external-link",
    },
    {
      name: "Privacy Policy",
      link: "https://www.mhainc.com/About_MHA/PrivacyPolicy/",
      icon: "fa fa-external-link",
    },
   
  ];
  const renderedLinks = footerLinks.map((item, index) => {
     return (
      <div key={index} className="col-sm-auto action-link-with-icon footer-link">
          <a href={item.link} >
          <i className={item.icon} />
          {item.name}
          </a>
      </div>
    );
  });

    useEffect(() => {
       
        getCookie("footerHidden")
        
    }, [cookieHasBeenAccepted])

  return (
    <React.Fragment>

       <footer> 
          {cookieHasBeenAccepted ? (
              <Row className="w-100 no-gutters" id="footer">
                  {renderedLinks}
                  <div className="col-auto" id="copyright">
                      Copyright &copy; 2022 Managed Health Care Associates, Inc. All rights
                      reserved.
        </div>
              </Row>
          )
              : (
                  <div className="sticky" style={{ display: displayStyle ? 'none' : 'block' }}>
                          This website uses cookies. By proceeding navigating on this page, you agree to the use of the cookies according to the our <a target="_blank" href="https://www.mhainc.com/About_MHA/PrivacyPolicy/">Privacy Policy</a> and <a target="_blank" href="https://www.mhainc.com/About_MHA/Ethics_Privacy/#TermsAndConditions">Terms and Conditions.</a>

                          <Button type="button" value="Close" onClick={updateFooter} >Agree and close </Button> 
                  </div>
              )
             

}
    </footer>
    </React.Fragment>
     
  );
}

