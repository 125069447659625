import React, { useState, useContext } from "react";
import { Modal, Col, Row,  } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import axios from "axios";
import StatusModal from "../StatusModal";
import useStatusModal from "../../hooks/useStatusModal";
import "./reports.css";
import UserContext from "../context/UserContext";
import "font-awesome/css/font-awesome.min.css";

export default function QBRModal(props) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);

  const [dataLoaded, setDataLoaded] = useState(false);
  const { statusProps, handleErrorStatus } = useStatusModal();

  const { locId, locType, name, title, isLTC, isASCD, pageView} = props;

  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;

  const handleShow = () => {
    setShow(true);
    getRecentDocs();
  };

  const handleClose = () => setShow(false);

  const downloadReport = (prefix, key) => {
    const uri = `api/Documents/qbr?locId=${locId}&locType=${locType}&qbrType=${prefix}&key=${key}`;
    console.log("qbrmodal - downloadReport - api call", uri);
    axios
      .get(uri, {
        method: "GET",
        responseType: "blob",
      })
      .then((result) => {
        // pageView(`QBR/${prefix}/${key}`)
        pageView(`QBR`)
        window.open(URL.createObjectURL(result.data), "blank");
      })
      .catch((error) => {
        console.error(error.message);
        handleErrorStatus("An error occurred downloading the document.");
      });
  };

  const getRecentDocs = () => {
    const uri = `api/documents/qbr/recent?locId=${locId}&locType=${locType}&take=4`;
    console.log("QBR Modal - getRecentDocs - api call", uri);
    axios.get(uri).then(
      (result) => {
        var files = result.data;
        if (!isLTC) {
          files = files.filter(
            (value) => !value.prefix.toLowerCase().includes("ltc")
          );
        }
        if (!isASCD) {
          files = files.filter(
            (value) => !value.prefix.toLowerCase().includes("ascd")
          );
        }
        setData(files);
        setDataLoaded(true);
      },
      (error) => {
        console.error(error.message);
        handleErrorStatus("An error occurred retrieving the recent documents.");
        setShow(false);
      }
    );
  };

  return (
    <React.Fragment>
      <StatusModal statusProps={statusProps} />
      <button className="link-button" variant="primary" onClick={handleShow}>
        {name}
      </button>
      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header>
          <Modal.Title> 
            <div className="row">
              <div className="col-11">
              {title} 
              </div>
              <div className="col-1">
              <i className ="fa fa-times modal-x" onClick={handleClose} ></i>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row >
            {!dataLoaded ? (
              <Col> Checking for files </Col>
            ) : data.length ? (
              <Col >
                <ul className="ul-no-decor">
                  <li >
                    Click below to download documents to your downloads folder.
                  </li>
                  {data.map((file) => {
                    return (
                      <li key={`${file.prefix}_${file.key}`} className="modal-list-item">
                        <button
                          className="link-button"
                          onClick={() => downloadReport(file.prefix, file.key)}
                        >
                          <i className="fa fa-download backToFolders"></i>{" "}
                          {`${file.display}`}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </Col>
            ) : locType === "Member" ? (
              <Col>
                Member Business Reviews are only available at the corporate
                level.
              </Col>
            ) : (
              <Col>There are no files available.</Col>
            )}
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
