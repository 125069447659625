import React, { useContext, useState } from "react";
import UserContext from "../../../context/UserContext";
import { Button, Col, Form } from "react-bootstrap";
import "font-awesome/css/font-awesome.min.css";

const RebateStatusForm = (props) => {
  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;

  const locId = currentUser.Location.LOCID;
  const locType = currentUser.Location.Type;
  const shortType = locType === "ParentCompany" ? "PC" : locType[0];

  const [isPaidChecked, setIsPaidChecked] = useState(true);
  const [isUnpaidChecked, setIsUnpaidChecked] = useState(true);

  const [startQuarter, setStartQuarter] = useState(null);
  const [endQuarter, setEndQuarter] = useState(null);
  const [paidStatus, setPaidStatus] = useState(null);

  const { isGridLoaded } = props;

  const prevQuarters = [];
  const numQtrs = 8;

  let i;
  for (i = 0; i < numQtrs; i++) {
    prevQuarters.push(
      Date.today()
        .add({ months: -3 * i })
        .toString("yyyyq")
    );
  }

  const [beginQuarter, setBeginQuarter] = useState(prevQuarters[3]);

  function handleQuarterChange(e) {
    const bq = e.target.value;
    const endQuarterSelect = document.getElementById("statusEndQtr");

    setBeginQuarter(bq);

    if (endQuarterSelect.value < bq) {
      endQuarterSelect.value = bq;
    }

    Array.prototype.forEach.call(endQuarterSelect.options, (o) => {
      o.disabled = o.value < bq;
    });
  }

  function handleSubmit(e) {
    e.preventDefault();

    const form = e.target;
    const data = form.elements;

    let paidStatus = "";
    data.paidStatus.forEach((cb) => {
      paidStatus += cb.checked ? cb.value : "";
    });

    if (paidStatus === "") {
      alert("Please check at least one payment status.");
      return;
    }

    setStartQuarter(data.statusBeginQtr.value);
    setEndQuarter(data.statusEndQtr.value);
    setPaidStatus(paidStatus);

    props.handleSubmit(
      data.statusBeginQtr.value,
      data.statusEndQtr.value,
      paidStatus
    );
  }

  function handleReset(e) {
    const sq = prevQuarters[3];
    const endQuarterSelect = document.getElementById("statusEndQtr");

    setBeginQuarter(sq);

    endQuarterSelect.selectedIndex = 0;

    Array.prototype.forEach.call(endQuarterSelect.options, (o) => {
      o.disabled = o.value < sq;
    });

    setIsPaidChecked(true);
    setIsUnpaidChecked(true);
  }

  const ViewDetails = () =>
    isGridLoaded ? (
      <Col className="col-lg-2 col-sm-4 col-12">
        <label>&nbsp;</label>
        <Button onClick={handleDetailsClick} className="w-100">
          View Details
        </Button>
      </Col>
    ) : null;

  function handleDetailsClick() {
    const uri = `api/report/rebate/statusDetails?locId=${locId}&locType=${shortType}&quarterStart=${startQuarter}&quarterEnd=${endQuarter}&paidUnpaid=${paidStatus}`;
    const uriexport = `api/report/rebate/statusDetailsExport?locId=${locId}&locType=${shortType}&quarterStart=${startQuarter}&quarterEnd=${endQuarter}&paidUnpaid=${paidStatus}`;

    props.setShowModal(true);
    props.setModalUri(uri);
    props.setModalUriExport(uriexport);

  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Col className="col-lg-10 col-md-12 col-12">
          <Form.Row>
            <Form.Group
              as={Col}
              controlId="statusBeginQtr"
              className="col-lg-2 col-sm-4 col-5 form-date"
            >
              <Form.Label>Beginning Quarter</Form.Label>
              <span className="date-wrap">
                <Form.Control
                  as="select"
                  value={beginQuarter}
                  onChange={handleQuarterChange}
                >
                  {prevQuarters.map((q) => (
                    <option key={q} value={q}>
                      {q.substring(0, 4)} Qtr {q[4]}
                    </option>
                  ))}
                </Form.Control>
              </span>
             
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="statusEndQtr"
              className="col-lg-2 col-sm-4 col-6 form-date"
            >
              <Form.Label>Ending Quarter</Form.Label>
              <span className="date-wrap">
                <Form.Control as="select">
                  {prevQuarters.map((q, index) => (
                    <option key={q} value={q} disabled={index > 5}>
                      {q.substring(0, 4)} Qtr {q[4]}
                    </option>
                  ))}
                </Form.Control>
              </span>
            </Form.Group>
            <Col className="col-lg-1 col-sm-4 col-6" style={ {maxWidth: "8%",
    flex: "0 0 12.5%"}}>
              <Form.Label>Payment Status</Form.Label>
              <Form.Check
                checked={isPaidChecked}
                label="Paid"
                type="checkbox"
                id="statusPaid"
                name="paidStatus"
                value="paid"
                onChange={() => setIsPaidChecked(!isPaidChecked)}
              />
              <Form.Check
                checked={isUnpaidChecked}
                label="Unpaid"
                type="checkbox"
                id="statusUnpaid"
                name="paidStatus"
                value="unpaid"
                
                onChange={() => setIsUnpaidChecked(!isUnpaidChecked)}
              />
            </Col>
            <Col className="col-lg-1 col-sm-1 col-12 reset-wrap" style={{marginTop:
          "2.5%"}}>
              <label>&nbsp;</label>
              <Button onClick={handleReset} className="reset-btn">
                <i className="fa fa-undo"></i> Reset
              </Button>
            </Col>
            <Col className="col-lg-2 col-sm-2 col-12" style={{marginTop:
          "2.5%"}}>
              <Form.Label>&nbsp;</Form.Label>
              <Button type="submit" className="results">
                Show Results
              </Button>
            </Col>
            <ViewDetails />
          </Form.Row>
        </Col>
        <Col className="col-lg-2 col-md-12 col-12">
          <div>
            <p>
              Please contact your account representative if details for a check
              from more than two years ago are required.
            </p>
            <p>
              Unpaid rebates are estimates. Please contact your account
              representative if you have any questions.
            </p>
          </div>
        </Col>
      </Form.Row>
    </Form>
  );
};

export default RebateStatusForm;
