import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import ORProjectionReport from "./ORProjectionReport";
import { LoadingSpinner } from "../../../layout/LoadingSpinner";

const ORProjection = () => {
    const [isLoading, setIsLoading] = useState(false);

    function handleLoading(newValue) {
        setIsLoading(newValue);

    }

    return (
        <React.Fragment>
            <LoadingSpinner isDataLoading={isLoading} />
            <Tabs
                defaultActiveKey="vendor"
                id="tabsORProjection"
                className="m-2 border-bottom-0"
            >
                <Tab eventKey="vendor" title="Vendor" className="m-2 mt-4">
                    <ORProjectionReport
                        reportType="vendor"
                        //getColumnType={getColumnTypeVendor}
                        //getTitle={getTitleVendor}
                        setLoading={handleLoading}
                    />
                </Tab>
                <Tab
                    eventKey="facility"
                    title="Facility Name"
                    className="m-2 mt-4"
                >
                    <ORProjectionReport
                        reportType="facility"
                        //getColumnType={getColumnTypeFacility}
                        setLoading={handleLoading}
                    />
                </Tab>
            </Tabs>
        </React.Fragment>
    );
};

export default ORProjection;
