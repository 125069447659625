import React, { useState, useContext } from "react";
import { Modal, Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import axios from "axios";
import StatusModal from "../StatusModal";
import useStatusModal from "../../hooks/useStatusModal";
import "./reports.css";
import "font-awesome/css/font-awesome.min.css";
import UserContext from "../context/UserContext";


export function RebatePacketReport(props) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const handleClose = () => setShow(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { statusProps, handleErrorStatus } = useStatusModal();
  const { cotList, name, title, pageView } = props;

  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;

  const handleShow = () => {
    setShow(true);
    console.log(props);

    getRecentDocs();
  };

  const downloadReport = (cot, quarter) => {
    const encodedCOT = encodeURIComponent(cot);
    const uri = `api/Documents/RPR/Quarter?cot=${encodedCOT}&quarter=${quarter}`;

    axios
      .get(uri, {
        method: "GET",
        responseType: "blob",
      })
      .then((result) => {
        // pageView(`Rebate/Packet/${encodedCOT}/${quarter}`);
        pageView(`Rebate Packet`);
        window.open(URL.createObjectURL(result.data), "blank");
      })
      .catch((error) => {
        console.error(error.message);
        handleErrorStatus("An error occurred downloading the document.");
      });
  };

  const compareQuarters = (a, b) => {
    const keyA = parseInt(a.Key);
    const keyB = parseInt(b.Key);
    let comparison = 0;
    if (keyA > keyB) {
      comparison = -1;
    } else if (keyA < keyB) {
      comparison = 1;
    }
    return comparison;
  };

  const getRecentDocs = () => {
    if (!dataLoaded) {
      cotList.forEach((cot, index) => {
        const encodedCOT = encodeURIComponent(cot);
        const uri = `api/Documents/RPR?cot=${encodedCOT}&take=4`;
        axios.get(uri).then(
          (result) => {
            if (result.data.length > 0) {
              const cotQuarter = {
                cot: cot,
                quarters: result.data.sort(compareQuarters),
              };
              setData((oldArray) => [...oldArray, cotQuarter]);
            }
            setDataLoaded(true);
          },
          (error) => {
            console.error(error.message);
            handleErrorStatus(
              "And error occurred retrieving the recent documents."
            );
            setShow(false);
          }
        );
      });
    }
  };

  return (
    <React.Fragment>
      <StatusModal statusProps={statusProps} />
      <button className="link-button" variant="primary" onClick={handleShow}>
        {name}
      </button>
      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header>
          <Modal.Title>
            <div className="row">
              <div className="col-11">{title}</div>
              <div className="col-1">
                <i className="fa fa-times modal-x" onClick={handleClose}></i>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {!dataLoaded ? (
              <Col> Checking for files </Col>
            ) : !data.length ? (
              <Col> There are no files available.</Col>
            ) : (
              <React.Fragment>
                <div style={{ marginLeft: "15px", marginBottom: "5px" }}>
                  Click below to download documents to your downloads folder.
                </div>
                {data.map((cotQuarter, i) => {
                  return cotQuarter.quarters.length > 0 ? (
                    <Col key={i} className="col-sm-4">
                      <div className="RebatePacketHeader">{cotQuarter.cot}</div>
                      {cotQuarter.quarters.map((quarter, index) => {
                        return (
                          <div
                            //className="rpr-link"
                            key={index + "quarter"}
                            //onClick={() =>
                            //  downloadReport(cotQuarter.cot, quarter.Key)
                            //}
                          >
                            <button
                              className="link-button"
                              onClick={() =>
                                downloadReport(cotQuarter.cot, quarter.Key)
                              }
                            >
                              <i className="fa fa-download backToFolders"></i>{" "}
                              {quarter.Display}
                            </button>
                          </div>
                        );
                      })}
                    </Col>
                  ) : null;
                })}
              </React.Fragment>
            )}
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
