import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Sidebar } from "./Sidebar";
import { ContentHeader } from "./ContentHeader";
import UserInitializer from "../UserIntializer";
import {useLocation} from "react-router-dom";
import UserContext from "../context/UserContext";
import { useOktaAuth } from "@okta/okta-react";

export default function Layout(props) {
  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;
  const { oktaAuth, authState } = useOktaAuth();
  const link = useLocation();
  const mainContent = link.pathname.includes("/content") ? "main-content" : "main";
  const handleLogout = () => {
    currentUser.Info = null;
    userContext.onCurrentUserChanged({});
    localStorage.clear();
    oktaAuth.signOut('/');
  };

  var inactivityTime = function () {
    var time;
    var warning;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    document.onload = resetTimer;
    document.onmousedown = resetTimer; // touchscreen presses
    document.ontouchstart = resetTimer;
    document.onclick = resetTimer; // touchpad clicks
    document.onkeydown = resetTimer; // onkeypress is deprectaed
    document.addEventListener("scroll", resetTimer, true); // improved; see comments

    function logout() {
      alert("You are now logged out.");
      handleLogout();
    }

    function inactivityWarning() {
      alert(
        "You have been inactive for 30 minutes.  Your session will expire soon."
      );
    }

    function resetTimer() {
      clearTimeout(time);
      clearTimeout(warning);
      warning = setTimeout(inactivityWarning, 1800000);
      time = setTimeout(logout, 3600000);
      // 1000 milliseconds = 1 second
    }
  };

  window.onload = function () {
    inactivityTime();
  };

  return (
    <React.Fragment>
      <UserInitializer />

      <Container className="px-0 d-flex flex-column" fluid="true" id="content">
        <header>
          <Header />
          <ContentHeader featureTitle={props.featureTitle} />
        </header>
        <Row
          className="flex-fill d-flex no-gutters flex-nowrap"
          id="non-header"
        >
          <Sidebar />
          <Col className="overflow-auto">
            <Row className="d-flex no-gutters flex-column" id={mainContent}>
              <Col className="pb-5">{props.children}</Col>
            </Row>
            <Footer />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
