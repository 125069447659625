import React, { useEffect, useState, useContext } from "react";
import { Modal, Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import axios from "axios";
import StatusModal from "../StatusModal";
import useStatusModal from "../../hooks/useStatusModal";
import "./reports.css";
import "font-awesome/css/font-awesome.min.css";
import { HighlightOffOutlined } from "@material-ui/icons";
import UserContext from "../context/UserContext";

export default function ORReport(props) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const handleClose = () => setShow(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const { statusProps, handleErrorStatus } = useStatusModal();
  const { longId, type, id, email, isAdmin } = props;
  const locType =
        type === "Affiliate" ? "AFID" : type === "ParentCompany" ? "RPID" : "MMID";
    const userContext = useContext(UserContext);
    const currentUser = userContext.currentUser;
    const ORID = currentUser.Location ? currentUser.Location.ORID : null;
  // make sure or profile exists
  // make sure contacts
  // const getORProfiles = () =>{
  //   const uri = `api/members/orprofiles?id=${id}&locType=${type}`;
  //   const axios.get(uri).then(
  //     (result) => {
  //       console.log(result.data)
  //       setProfileData(result.data);
  //       setProfileLoaded(true);
  //     },
  //     (error) => {
  //       setProfileLoaded(true);
  //       handleError(error);
  //     }
  //   );
  // }

  useEffect(() => {
    if (id != null) {
      try {
        const locType =
          type === "Affiliate" ? "A" : type === "ParentCompany" ? "PC" : "M";
        const profileUri = `api/members/orprofiles?id=${longId}`;
        const contactsUri = `api/members/contacts?id=${id}&locType=${locType}`;

        //possible situations - affil-corp, affil-affil, corp-corp, corp-affil, corp-member and reverse
        (async () => {
          console.log("ORModal - api call - ", contactsUri);
          const contactsCall = await axios.get(contactsUri);
          if (
            (contactsCall.data.length > 0 &&
              contactsCall.data.some((r) => r.Email === email)) ||
            isAdmin
          ) {
            setShowButton(true);
            const profileCall = await axios.get(profileUri);
            const profileData = profileCall.data;
            if (profileData.length > 0) {
                setProfileData(profileData);
               
            }
          }
        })();
      } catch (error) {
        console.error(error.message);
        handleErrorStatus("An error occurred downloading the document.");
      }
    }
  }, []);

  const handleShow = () => {
    setShow(true);
      getRecentDocs();
      console.log(data);
  };

  async function getRecentDocs() {
    if (profileData.length > 0) {
      try {
        let reportUrls = [];
          //profileData.forEach((profile) => {
          let profile = profileData[0];
            console.log(profile)
          if (
            profile.AggregateID !== longId ||
            profile.Aggregation === profile.Reporting
          ) {
            const uri = `api/Documents/OR/recent?locid=${longId}&loctype=${type}`;
            const uriObj = { uri: uri };
            reportUrls.push(uriObj);
          } else {
            profile.ProfileMembers.forEach((member) => {
              const type =
                member.LocationType === "AFID"
                  ? "Affiliate"
                  : member.LocationType === "RPID"
                  ? "ParentCompany"
                  : "Member";
              const uri = `api/Documents/OR/recent?locid=${member.LocationId}&loctype=${type}`;
              const uriObj = { uri: uri, name: member.Name };
              reportUrls.push(uriObj);
            });
          };
        //});

        const arrayWithResults = await Promise.all(
          reportUrls.map((r) => axios.get(r.uri))
        );
          if (arrayWithResults.length > 0) {
            console.log(arrayWithResults)
          setData(
            arrayWithResults.map((result, i) => {
              return {
                reports: result.data,
                name: reportUrls[i].name ?? "",
              };
            })
          );
          setDataLoaded(true);
        }
      } catch (error) {
        console.error(error.message);
        handleErrorStatus("An error occurred retrieving the recent documents.");
      }
    } else {
      setDataLoaded(true);
    }
  }

  return (
    <React.Fragment>
      <StatusModal statusProps={statusProps} />
      {showButton && (
        <button className="link-button" variant="primary" onClick={handleShow}>
          OR Reports
        </button>
      )}
      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header>
          <Modal.Title>
            <div className="row">
              <div className="col-11">
                OR Reports 
              </div>
              <div className="col-1">
                <i className ="fa fa-times modal-x" onClick={handleClose} ></i>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            {!dataLoaded ? (
              <Col> Checking for files </Col>
            ) : !profileData.length > 0 || !data[0].reports.Quarters.length ? (
              <Col> There are no files available.</Col>
            ) : (
              <Col>
                {data.map((optionsData, i) => {
                  return (
                    <Col key={i}>
                      {optionsData.reports.Quarters.length > 0 && (
                              
                                  
                                  <b>{optionsData.name}</b>
                      
                      )}
                      <Options
                              data={optionsData.reports}
                              handleError={handleErrorStatus}
                              ORID={ORID}
                      />
                    </Col>
                  );
                })}
              </Col>
            )}
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

function Options(props) {
  const [option, setOption] = useState("quarters");
  const [currentQuarter, setCurrentQuarter] = useState({});
    const { data, handleError, ORID } = props;
    

  function selectQuarter(idx) {
    if (data) {
      setOption("quarter");
      setCurrentQuarter(data.Quarters[idx]);
    }
  }
  function downloadFile(file, path) {
    const uri = `api/Documents/OR?quarter=${currentQuarter.QuarterPath}&file=${file}&path=${path}&ORID=${ORID}`;
    axios
      .get(uri, {
        method: "GET",
          responseType: "blob",

      })
        .then((response) => {
            var FileSaver = require('file-saver');
            let blob = response.data;
            let fileName = file.substring(0, file.length - 5) + ".xls"
            console.log(file.replace(".xlsx", ".xls"));
            console.log(fileName);
            FileSaver.saveAs(blob, fileName);


        //const url = URL.createObjectURL(new Blob([response.data]));
        //const link = document.createElement("a");
        //link.href = url;
        //link.setAttribute("download", file);
        //document.body.appendChild(link);
        //link.click();
      })
      .catch((error) => {
        console.error(error.message);
        handleError("An error occurred downloading the file.");
      });
    }

    function base64toBlob(base64Data, contentType) {
        contentType = contentType || '';
        var sliceSize = 1024;
        var byteCharacters = atob(base64Data);
        var bytesLength = byteCharacters.length;
        var slicesCount = Math.ceil(bytesLength / sliceSize);
        var byteArrays = new Array(slicesCount);

        for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            var begin = sliceIndex * sliceSize;
            var end = Math.min(begin + sliceSize, bytesLength);

            var bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    }

  function goBack() {
    if (option === "quarter") {
      setOption("quarters");
    } else {
      setOption("quarter");
    }
  }
  return (
    <React.Fragment>
      {option !== "quarters" && (
        <div
          onClick={() => {
            goBack();
          }}
        >
          {" "}
                  <i class="fa fa-arrow-circle-left backToFolders" aria-hidden="true"></i><b className = "backToFolders"> Back to Available ORs</b>
        </div>
      )}
      {option === "quarters" ? (
        data.Quarters.map((val, i) => {
          return (
            <div key={i}>
              <div
                className="clickable-option"
                onClick={() => {
                  selectQuarter(i);
                }}
              >
                      <i class="fa fa-folder-open backToFolders"></i> {val.Quarter}
              </div>
            </div>
          );
        })
      ) : (
        <div>
          {currentQuarter.Files.map((val, i) => {
            return (
              <div key={i}>
                <div
                  className="clickable-option"
                  onClick={() => {
                    downloadFile(val.Name, val.Path);
                  }}
                >
                        <i class="fa fa-download backToFolders" ></i> {val.Name} 
                </div>
              </div>
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
}
