import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import UserContext from "../../context/UserContext";
import { Row, Modal, Button } from "react-bootstrap";
import StatusModal from "../../StatusModal";
import useStatusModal from "../../../hooks/useStatusModal";
import { LoadingSpinner } from "../../layout/LoadingSpinner";
import "./Profile.css";
import docTypes from "./DocumentTypes";
import { DocUploadForm } from "./DocUploadForm";
import { Redirect } from "react-router-dom";

export default function DocumentUpload(props) {
  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;
  const email = currentUser.Email ?? "";
  const ltc = ["LTC/NURSING HOME PROVIDER", "OMNICARE"];
  const type = currentUser.Location.Type.toLowerCase();
  const cots = currentUser.Location.Type !== "Member" ? currentUser.Location.COTs.map(i=>i.Description)
  : [currentUser.Location.COT];
  const contactStatus =
    currentUser.Info && currentUser.Info.Contacts
      ? currentUser.Info.Contacts.some((r) => r.Status === "Primary")
        ? "Primary"
        : "Secondary"
      : null;
  const phone = currentUser.Info  && currentUser.Info.Phone? currentUser.Info.Phone : "";
  const roles = currentUser.Info ? currentUser.Info.Roles : [];
  const name = currentUser.Name ?? "";
  const [form, setForm] = useState({});
  const [docTypeList, setDocTypeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    statusProps,
    handleErrorStatus,
    handleAlertStatus,
    handleSuccessStatus,
  } = useStatusModal();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  useEffect(() => {
    if (docTypeList.length === 0) {
      let list = [];
      if (currentUser) {
        if (type === "member") {
          list = currentUser.Location.Services.some((x) =>
            x.includes("LTC Network")
          )
            ? [...docTypes.types, ...docTypes.ltcTypes]
            : docTypes.types;
        } else {
          list = currentUser.Location.Services.some((x) =>
            x.Service.includes("LTC Network")
          )
            ? [...docTypes.types, ...docTypes.ltcTypes]
            : docTypes.types;
        }
      }
      setDocTypeList(list);
    }
  }, [docTypeList.length, currentUser, type]);

  const handleSubmit = (form) => {
    setForm(form);
    setShow(true);
  };
  const handleClick = () => {
    
    if(currentUser.Location.PAR){
      let uri = `api/documents`;
      const formData = new FormData();
      setIsLoading(true);
      form.Files.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });
      if(cots.some((r) => ltc.includes(r))){
        formData.append("LtcEmail", "LTCNetwork@mhainc.com" )
      }
      formData.append("DocType", form.DocType);
      formData.append("DocText", form.DocText);
      formData.append("Email", email);
      formData.append("PAR", currentUser.Location.PAR);
      formData.append("Location", currentUser.Location.Name);
      formData.append("LocationID", currentUser.Location.ID);
      formData.append("Name", name);
      formData.append("Phone", phone);
      formData.append("Type", type);
      axios
        .post(uri, formData, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then(
          (result) => {
            setShow(false);
            console.log(result.data);
  
            let message =
              result.length > 1
                ? result
                : "Document(s) have successfully been sent to your Account Representative";
  
            if (result.data.includes("problem")) {
              handleAlertStatus(message);
            } else {
              handleSuccessStatus(message);
            }
            setIsLoading(false);
          },
          (error) => {
            setShow(false);
            console.error(error.message);
            handleErrorStatus("An error occurred uploading the document(s).");
            setIsLoading(false);
          }
        );
    }else{
      handleErrorStatus("Current Location does not have a PAR attached");
      setShow(false);
    }
   
  };

  // return contactStatus === "Primary" || roles.includes("Internal Admin") ? 
  return(
    <React.Fragment>
      <StatusModal statusProps={statusProps} />
      <LoadingSpinner isDataLoading={isLoading} />
      <Row noGutters className="doc-upload-form">
        <DocUploadForm
          handleSubmit={handleSubmit}
          docTypes={docTypeList}
          email={email}
        />
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton className="upload-modal-header">
            <Modal.Title>Upload</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Submit Document?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClick}>
              Ok
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
    </React.Fragment>
  )
  // ) : (
  //   <Redirect
  //     to={{
  //       pathname: "/unauthorized",
  //       state: {
  //         message:
  //           "You must be an Internal Admin or Primary Contact to access this page.",
  //       },
  //     }}
  //   />
  // );
}
