import {ContentItem} from '@kentico/kontent-delivery';
export default class Banner extends ContentItem{

    constructor(){
        super({
            richTextResolver:(rawData) =>{
                const height = rawData.height.value ?? 300;
                const imageUrl = rawData.image.images[0].url
                ?? '' ;
                return `<div style="background-image: url('${imageUrl}');
                background-color: #cccccc;
                      background-position: bottom; 
                      background-repeat: no-repeat;
                      background-size: cover;
                      position: relative;
                      display: inline-block;
                      width: 100%;
                      height: ${height}px;">
                      </div>`     
                                
            }
        })
    }
}