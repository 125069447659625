import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import UserContext from "../../context/UserContext";
import { Row, Col, Modal } from "react-bootstrap";
import StatusModal from "../../StatusModal";
import useStatusModal from "../../../hooks/useStatusModal";
import { LoadingSpinner } from "../../layout/LoadingSpinner";
import { MemberForm } from "./MemberForm";
import { Link } from "react-router-dom";
import "./Profile.css";
import { NonMemberForm } from "./NonMemberForm";

export function Profile(props) {
  const { close } = props;
  const [isLoading, setIsLoading] = useState(false);
  const {
    statusProps,
    handleErrorStatus,
    handleSuccessStatus,
    handleAlertStatus,
  } = useStatusModal();
  const [canEdit, setEdit] = useState(false);
  const [data, setData] = useState(null);
  const userContext = useContext(UserContext);
  const currentUser = userContext.currentUser;
  const contactStatus =
    currentUser.Info && currentUser.Info.Contacts
      ? currentUser.Info.Contacts.some((r) => r.Status === "Primary")
        ? "Primary"
        : "Secondary"
      : "";
  const roles =
    currentUser.Info && currentUser.Info.Roles ? currentUser.Info.Roles : [];
  const locId = currentUser.Location.ID;
  const type = currentUser.Location.Type.toLowerCase();

  const handleSubmit = (form) => {
    let uri = type === "parentcompany" ? `api/${type}` : `api/${type}s`;
    setIsLoading(true);
    form.ModUser = currentUser.Name;
    axios.post(uri, JSON.stringify(form)).then(
      (result) => {
        handleSuccessStatus("Profile update successful");
        console.log(result.data);
        setIsLoading(false);
      },
      (error) => {
        console.error(error.message);
        handleErrorStatus("An error occurred updating the profile.");
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    if (roles.includes("Internal Admin") || contactStatus === "Primary") {
      setEdit(true);
    }
    if (data == null) {
      let uri =
        type === "parentcompany"
          ? `api/${type}/profile?id=${locId}`
          : `api/${type}s/profile?id=${locId}`;
      setIsLoading(true);
      axios.get(uri).then(
        (result) => {
          console.log(result);
          setIsLoading(false);
          if (result.data.CurrentLocation) {
            setData(result.data);
            console.log(result.data);
          } else {
            handleAlertStatus("Could not find member");
          }
        },
        (error) => {
          console.error(error.message);
          handleErrorStatus("An error occurred retrieving profile data.");
          setIsLoading(false);
        }
      );
    }
  }, [locId, type, contactStatus, roles, data]);

  return (
    <React.Fragment>
      <StatusModal statusProps={statusProps} />
      <LoadingSpinner isDataLoading={isLoading} />
      <Modal.Header>
        <Modal.Title>
          <Row>
              <div className="col-11 profile-name">
              {currentUser.Location.Name}
              </div>
              <div className="col-1 modal-x-container">
                <i className ="fa fa-times modal-x" onClick={close} ></i>
              </div>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="profile-doc-link">
          <Col>
              <Link to={ {pathname:"/DocumentUpload", state:{name:"Upload Document"}}}>
                <i className="fa fa-upload" aria-hidden="true"></i> Upload Document
              </Link>
          </Col>
        </Row>
        <Row noGutters className="profile-form">
          {data && type === "member" ? (
            <MemberForm
              data={data}
              canEdit={canEdit}
              handleSubmit={handleSubmit}
              handleCancel={close}
            />
          ) : data ? (
            <NonMemberForm
              data={data}
              type={type}
              canEdit={canEdit}
              handleSubmit={handleSubmit}
              handleCancel={close}
            />
          ) : null}
        </Row>
      </Modal.Body>
    </React.Fragment>
  );
}
