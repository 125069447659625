import {ContentItem} from '@kentico/kontent-delivery';

export default class CardRow extends ContentItem{

    constructor(){
        super({
            richTextResolver:(rawData) =>{
                return `<div class="row content-row card-deck">${rawData.row.value}</div>`;
            }
        })
    }
}