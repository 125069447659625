import React, { useContext, useState } from "react";
import { Col, Form } from "react-bootstrap";
import axios from "axios";
import UserContext from "../../../context/UserContext";
import { DataTable } from "mha-ui-kit-react-library/dist/index";
import "mha-ui-kit-react-library/dist/styles.css";
import StatusModal from "../../../StatusModal";
import useStatusModal from "../../../../hooks/useStatusModal";
import ORProjectionVendorForm from "./ORProjectionVendorForm";
import ORProjectionFacilityForm from "./ORProjectionFacilityForm";

const ORProjectionReport = (props) => {
    const userContext = useContext(UserContext);
    const currentUser = userContext.currentUser;

    const locId = currentUser.Location.LOCID;
    const locType = currentUser.Location.Type;
    let shortType = locType === "ParentCompany" ? "PC" : locType[0];

    if (shortType === "A") { shortType = "AF" }

    const {
        statusProps,
        handleErrorStatus,
        handleAlertStatus,
    } = useStatusModal();

    const [isReportLoaded, setIsReportLoaded] = useState(false);
    const [reportData, setReportData] = useState([]);
    const [columns, setColumns] = useState(null);
    const { reportType, setLoading } = props;

    const [ORPercent, setORPercent] = useState([]);
    const [ORRebate, setORRebate] = useState([]);
    const [ContractedSales, setAmount] = useState([]);
    const [RebatePercentNextTier, setRebatePercentNextTIer] = useState([]);

    const [QtrText, setQtrText] = useState([]);
    const [DateText, setDateText] = useState([]);

    const vendorColumns = [
        {
            title: "Vendor Name",
            accessor: "VendorName",
        },
        {
            title: "Estimate Sales",
            accessor: "Estimate_Sales",
            type: "money",
            showTotal: true,
        },
        {
            title: "Estimate Fees",
            accessor: "Estimate_fees",
            type: "money",
            showTotal: true,
        },
    ];

    const facilityColumns = [
        {
            title: "Facility Name",
            accessor: "FACILITY_NAME",
        },
        {
            title: "DEA Number",
            accessor: "DEANUM",
        },
        {
            title: "Estimate Sales",
            accessor: "Estimate_Sales",
            type: "money",
            showTotal: true,
        },
        {
            title: "Estimate Fees",
            accessor: "Estimate_fees",
            type: "money",
            showTotal: true,
        },
        {
            title: "Offeror Rebate %",
            accessor: "Percentage",
            type: "percent"
        },
        {
            title: "Rebate",
            accessor: "Rebate",
            type: "money",
            showTotal: true,
        },
    ];

    let ReportForm, handleLoadGrid;

    switch (reportType) {
        case "vendor":
            //setVendorFaciliySelection(reportType);
            ReportForm = ORProjectionVendorForm;
            handleLoadGrid = loadVendorGrid;
            break;
        case "facility":
            //setVendorFaciliySelection("facility");
            ReportForm = ORProjectionFacilityForm;
            handleLoadGrid = loadFacilityGrid;
            break;
        default:
            console.error("Invalid Rebate Report Type");
    }

    function loadVendorGrid(curprev) {
        const uri = `api/report/orprojection/vendor?locId=${locId}&locType=${shortType}&curprev=${curprev}`;
        setColumns(vendorColumns);
        console.log("OR Projection Report - vendor - api call", uri);
        loadGrid(uri);
        setDisclaimer();
    }

    function loadFacilityGrid(curprev) {
        const uri = `api/report/orprojection/facility?locId=${locId}&locType=${shortType}&curprev=${curprev}`;
        setColumns(facilityColumns);
        console.log("OR Projection Report - facility - api call", uri);
        loadGrid(uri);
        setDisclaimer();
    }

    function loadGrid(uri) {
        setLoading(true);
        axios.get(uri).then(
            (result) => {
                setIsReportLoaded(true);

                //let removerow = "false";

                for (var i = 0; i < result.data.length; i++) {
                    if (result.data[i].ToNextTierRow === "X") {
                        setRebatePercentNextTIer(result.data[i].Percentage);
                        setAmount(result.data[i].Estimate_fees.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'));
                        //removerow = "true";
                    }
                    else if (result.data[i].ToNextTierRow === "O") {
                        setORRebate(result.data[i].Rebate.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'));
                        setORPercent(result.data[1].Percentage);
                        //removerow = "true";
                    }
                    else {
                        setReportData(result.data.splice(i));
                    }

                }

                setLoading(false);
                if (result.data == null || result.data.length === 0) {
                    handleAlertStatus("No records were found for these report criteria");
                }
            },
            (error) => {
                console.error(error.message);
                handleErrorStatus("An error occurred retrieving the report data.");
                setLoading(false);
                setIsReportLoaded(true);
            }
        );
    }

    function setDisclaimer() {

        if (document.getElementById("tabsORProjection-tab-facility") != null) {

            if (document.getElementById("tabsORProjection-tab-facility").className.includes('active') === true) {

                var e = document.getElementById("facilityCurprev");
                var facilityCurprev = e.options[e.selectedIndex].value;

                //Previous Facility
                //    Hide Everything
                if (facilityCurprev === "PREVIOUS") {

                    if (document.getElementById("AddContractSales") != null) {
                        document.getElementById("AddContractSales").style.display = "none";
                    }
                    if (document.getElementById("Rebate%") != null) {
                        document.getElementById("Rebate%").style.display = "none";
                    }
                    if (document.getElementById("ORRebates%") != null) {
                        document.getElementById("ORRebates%").style.display = "none";
                    }
                    if (document.getElementById("ORRebates$") != null) {
                        document.getElementById("ORRebates$").style.display = "none";
                    }

                    var enddate = getCurrentPreviousQuarter("previous").EndDate;
                    var fullenddate = getCurrentPreviousQuarter("previous").FullEndDate;

                    var pyear = enddate.getFullYear();
                    var pquarter = Math.floor((enddate.getMonth() + 3) / 3);
                    var prevquarter = pquarter.toString() + "Q" + pyear.toString();

                    setQtrText(prevquarter);
                    setDateText(fullenddate);
                }

                //Current Facility

                //    Hide OR
                //    Hide OR%

                if (facilityCurprev === "CURRENT") {
                    if (document.getElementById("ORRebates%") != null) {
                        document.getElementById("ORRebates%").style.display = "none";
                    }
                    if (document.getElementById("ORRebates$") != null) {
                        document.getElementById("ORRebates$").style.display = "none";
                    }

                    var today = new Date();

                    const month = today.toLocaleString('default', { month: 'long' });
                    const date = today.getDate();
                    const year = today.getFullYear();

                    const fulldate = month + " " + date + ", " + year;

                    var quarter = Math.floor((today.getMonth() + 3) / 3);
                    var curquarter = quarter.toString() + "Q" + year.toString();

                    setQtrText(curquarter);
                    setDateText(fulldate);

                }

                if(facilityCurprev === "PREVIOUS2"){
                    if (document.getElementById("AddContractSales") != null) {
                        document.getElementById("AddContractSales").style.display = "none";
                    }
                    if (document.getElementById("Rebate%") != null) {
                        document.getElementById("Rebate%").style.display = "none";
                    }
                    if (document.getElementById("ORRebates%") != null) {
                        document.getElementById("ORRebates%").style.display = "none";
                    }
                    if (document.getElementById("ORRebates$") != null) {
                        document.getElementById("ORRebates$").style.display = "none";
                    }

                    var enddate = getCurrentPreviousQuarter("previous2").EndDate;
                    var fullenddate = getCurrentPreviousQuarter("previous2").FullEndDate;

                    var pyear = enddate.getFullYear();
                    var pquarter = Math.floor((enddate.getMonth() + 3) / 3);
                    var prevquarter = pquarter.toString() + "Q" + pyear.toString();

                    setQtrText(prevquarter);
                    setDateText(fullenddate);
                }

            }

        }

        //PREVIOUS Vendor
        if (document.getElementById("tabsORProjection-tab-vendor") != null) {
            //    Hide AddContractSales
            //    Hide RebatePercent
         
            if (document.getElementById("tabsORProjection-tab-vendor").className.includes('active') === true) {
                var e = document.getElementById("vendorCurprev");
                var vendorCurprev = e.options[e.selectedIndex].value;

                if (vendorCurprev === "PREVIOUS") {
                    if (document.getElementById("AddContractSales") != null) {
                        document.getElementById("AddContractSales").style.display = "none";
                    }
                    if (document.getElementById("Rebate%") != null) {
                        document.getElementById("Rebate%").style.display = "none";
                    }

                    var enddate = getCurrentPreviousQuarter("previous").EndDate;
                    var fullenddate = getCurrentPreviousQuarter("previous").FullEndDate;

                    var pyear = enddate.getFullYear();
                    var pquarter = Math.floor((enddate.getMonth() + 3) / 3);
                    var prevquarter = pquarter.toString() + "Q" + pyear.toString();

                    setQtrText(prevquarter);
                    setDateText(fullenddate);
                }

                if (vendorCurprev === "CURRENT") {

                    var today = new Date();

                    const month = today.toLocaleString('default', { month: 'long' });
                    const date = today.getDate();
                    const year = today.getFullYear();

                    const fulldate = month + " " + date + ", " + year;

                    var quarter = Math.floor((today.getMonth() + 3) / 3);
                    var curquarter = quarter.toString() + "Q" + year.toString();

                    setQtrText(curquarter);
                    setDateText(fulldate);
                }

                if(vendorCurprev === "PREVIOUS2"){
                    if (document.getElementById("AddContractSales") != null) {
                        document.getElementById("AddContractSales").style.display = "none";
                    }
                    if (document.getElementById("Rebate%") != null) {
                        document.getElementById("Rebate%").style.display = "none";
                    }

                    var enddate = getCurrentPreviousQuarter("previous2").EndDate;
                    var fullenddate = getCurrentPreviousQuarter("previous2").FullEndDate;

                    var pyear = enddate.getFullYear();
                    var pquarter = Math.floor((enddate.getMonth() + 3) / 3);
                    var prevquarter = pquarter.toString() + "Q" + pyear.toString();

                    setQtrText(prevquarter);
                    setDateText(fullenddate);
                }

            }
            //Current Vendor
            //    Dont Hide Anything
        }

    }

    function getCurrentPreviousQuarter(value) {
        var today = new Date(),
            quarter = Math.floor((today.getMonth() / 3)),
            startDate,
            endDate,
            fullEndDate

        switch (value) {
            case "previous":
                startDate = new Date(today.getFullYear(), quarter * 3 - 3, 1);
                endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);

                const month = endDate.toLocaleString('default', { month: 'long' });
                const date = endDate.getDate();
                const year = endDate.getFullYear();

                fullEndDate = month + " " + date + ", " + year;

                break;
            case "previous2":
                startDate = new Date(today.getFullYear(), quarter * 3 - 6, 1);
                endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);

                const prevMonth = endDate.toLocaleString('default', { month: 'long' });
                const prevDate = endDate.getDate();
                const prevYear = endDate.getFullYear();

                fullEndDate = prevMonth + " " + prevDate + ", " + prevYear;
                break;
            default:
                startDate = new Date(today.getFullYear(), quarter * 3, 1);
                endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
                break;
        }

        return {
            StartDate: startDate,
            EndDate: endDate,
            FullEndDate: fullEndDate
        };
    }


    const ReportTable = () => {

        return isReportLoaded ?
            (
                <React.Fragment>
                    <Form>
                        <Form.Row>
                            <Col className="col-lg-3 col-12">
                                <p>
                                    **Please note the following are estimates based on <span>{QtrText}</span> wholesaler data as of <span>{DateText}</span>.
                                    </p>
                            </Col>
                        </Form.Row>
                    </Form>
                    <DataTable
                        id="tblORProjectionReport"
                        rows={reportData}
                        columns={columns}
                        pagination
                        filterable
                        handleCellClick={() => { }}
                        totalFooter
                        exportable
                        freezeheader
                    />
                    <Form.Row id="ORRebates%">
                        <Form.Label>Offer Rebate %:</Form.Label>
                        <span style={{ marginLeft: '.5rem' }}>{ORPercent * 100}%</span>
                    </Form.Row>
                    <Form.Row id="ORRebates$">
                        <Form.Label>Offer Rebate $:</Form.Label>
                        <span style={{ marginLeft: '.5rem' }}>${ORRebate}</span>
                    </Form.Row>
                    <Form.Row id="AddContractSales">
                        <Form.Label>Additional Contracted Sales Needed to Reach Next Tier:</Form.Label>
                        <span style={{ marginLeft: '.5rem' }}>${ContractedSales}</span>
                    </Form.Row>
                    <Form.Row id="Rebate%">
                        <Form.Label>Rebate % at Next Tier:</Form.Label>
                        <span style={{ marginLeft: '.5rem' }}>{RebatePercentNextTier * 100}%</span>
                    </Form.Row>
                </React.Fragment>

            ) : null;
    };

    return (
        <React.Fragment>
            <StatusModal statusProps={statusProps} />
            <ReportForm
                handleSubmit={handleLoadGrid}
                isGridLoaded={isReportLoaded}
            />
            <ReportTable />
        </React.Fragment>

    );



};

export default ORProjectionReport;
