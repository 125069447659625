import React, { Component, } from "react";
import {Router, useHistory } from "react-router-dom";
import { Security } from "@okta/okta-react";
import UserContext from "./components/context/UserContext";
import Routes from "./components/Routes";
import ReactGA from "react-ga";
import ErrorBoundary from "./components/ErrorBoundary";
import { createBrowserHistory } from 'history';
import {pageView} from"./googleAnalyticsHelper";
import CacheBuster from 'react-cache-buster';
import {version} from "../package.json";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";

// import ReactGA from 'react-ga';
//const oktaAuth = new OktaAuth(config.oidc);

let oktaIssuer = process.env.REACT_APP_OKTA_ISSUER;
  // console.log(oktaIssuer);

  //NOTE: I think we might need to store this somewhere on the deployed environment? Figure this out eventually
  let oktaClientId = process.env.REACT_APP_OKTA_CLIENTID;
  // console.log(oktaClientId);

  const constructedOktaAuth = new OktaAuth({
    issuer: oktaIssuer,
    clientId: oktaClientId,
    // redirectUri: window.location.origin + '/login/callback'
    // redirectUri: "http://localhost:3000/testfile"
    redirectUri: window.location.origin + "/implicit/callback",
  });

const history = createBrowserHistory();

history.listen((location, action) => {
  if(location.state){
    let page = location.state && location.state.name ? location.state.name : (location.pathname + location.search).replaceAll("/", " ");
    pageView(page);
  }else{
    const contentPage = localStorage.getItem("content-page");
    pageView(contentPage);
  }
});

const HasAccessToRouter = () => {
  // example from react-router
  // const history = useHistory(); 

  const customAuthHandler = () => {
    history.push("/login");
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "", window.location.origin));
  };

  // const constructedRestoreOriginalUri = async (_oktaAuth, originalUri) => {
  //   navigate(toRelativeUrl(originalUri, window.location.origin), {
  //     replace: true,
  //   });
  // };

  return (
    // <Security
    //   issuer={process.env.REACT_APP_OKTA_ISSUER}
    //   //T1 Version
    //   //client_id="0oask28qpjTOF4Tho0h7"
    //   client_id={process.env.REACT_APP_OKTA_CLIENTID}
    //   redirect_uri={window.location.origin + "/implicit/callback"}
    //   pkce={true}
    //   scopes={["openid", "profile", "email", "groups"]}
    //   onAuthRequired={customAuthHandler}
    // >
    <Security
          oktaAuth={constructedOktaAuth}
          restoreOriginalUri={restoreOriginalUri}
          onAuthRequired={customAuthHandler}
        >
      <Routes />
    </Security>
  );
};


class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: JSON.parse(localStorage.getItem("currentUser")) ?? {},    
        };

        console.log("app constructor - currentUser", this.state.currentUser);
        this.initializeGA();
        //console.log(window.location);
    }

    handleCurrentUserChanged = (user) => {
        console.log("handleCurrentUserChanged", user);
        this.setState({ currentUser: user });
        localStorage.setItem("currentUser", JSON.stringify(user));
    };

    initializeGA = () => {
        const trackingId = "UA-7362416-10";
        ReactGA.initialize(trackingId);
        ReactGA.pageview({
            pathName: window.location.pathname,
            pageData: Window.location,
        });
    };

  render() {
    return (
      <ErrorBoundary>
        <CacheBuster
          currentVersion={version}
          isEnabled={true}
          isVerboseMode={false}
        >
          <UserContext.Provider
            value={{
              currentUser: this.state.currentUser,
              onCurrentUserChanged: this.handleCurrentUserChanged,
            }}
          >
            <Router history={history}>
              <HasAccessToRouter />
            </Router>
          </UserContext.Provider>
        </CacheBuster>
      </ErrorBoundary>
    );
  }
}


export default App;
