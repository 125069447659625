import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import GERReport from "./GERReport";
import { LoadingSpinner } from "../../../layout/LoadingSpinner";

const GER = () => {
  const [isLoading, setIsLoading] = useState(false);

  function handleLoading(newValue) {
    setIsLoading(newValue);
  }

  function getColumnTypeSummary(measure) {
    return measure === "trends" ? "percent" : "money";
  }

  function getColumnTypeTrend(measure) {
    switch (measure) {
      case "trend":
      case "discount":
        return "percent";
      case "claim":
      case "reimbursement":
      case "cost":
      case "profit":
        return "money";
      case "supply":
        return "number";
      default:
        return "text";
    }
  }

  function getTitleSummary(n) {
    if (n.length === 6) {
      const monthIndex = parseInt(n.substring(4)) - 1;
      return new Date(n.substring(0, 4), monthIndex).toString("MMM yyyy");
    } else return n;
  }

  function estPctMedHeader(measure, columnType) {
    if (measure === "trends") {
      return [
        {
          title: "EstimatedPctMed",
          accessor: "EstimatedPctMed",
          type: columnType,
        },
      ];
    }
  }

  return (
    <React.Fragment>
      <LoadingSpinner isDataLoading={isLoading} />
      <Tabs
        defaultActiveKey="summary"
        id="tabsGER"
        className="m-2 border-bottom-0"
      >
        <Tab eventKey="summary" title="GER Summary" className="m-2 mt-4">
          <GERReport
            reportType="summary"
            getColumnType={getColumnTypeSummary}
            getTitle={getTitleSummary}
            getAdditionalHeaders={estPctMedHeader}
            setLoading={handleLoading}
          />
        </Tab>
        <Tab
          eventKey="trend"
          title="Brand / Generic Trends"
          className="m-2 mt-4"
        >
          <GERReport
            reportType="trend"
            getColumnType={getColumnTypeTrend}
            setLoading={handleLoading}
          />
        </Tab>
        <Tab
          eventKey="year"
          title="Year-Over-Year Comparison"
          className="m-2 mt-4"
        >
          <GERReport reportType="year" setLoading={handleLoading} />
        </Tab>
      </Tabs>
    </React.Fragment>
  );
};

export default GER;
