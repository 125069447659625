import React, { useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import "./Autocomplete.css";
import axios from "axios";
import StatusModal from "./StatusModal";
import useStatusModal from "../hooks/useStatusModal";

export function Autocomplete(props) {
  const [activeOption, setActive] = useState(0);
  const [options, setOptions] = useState([]);
  const [showOptions, setOptionbool] = useState(false);
  const [loading, isLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const ref = useRef();
  const url = props.endpoint;
  const minLength = props.minLength;
  const { statusProps, handleErrorStatus } = useStatusModal();

  const onChange = (e) => {
    console.log("onChanges");
    const input = e.currentTarget.value;
    setUserInput(e.currentTarget.value);
    let searchTerm = { Type: props.type, Term: input };
    if (input.length >= minLength) {
      isLoading(true);
      axios({ method: "PUT", url: url, data: JSON.stringify(searchTerm) })
        .then(function (response) {
          setOptions(response.data);
          setOptionbool(true);
          setActive(0);
          isLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
          handleErrorStatus();
          isLoading(false);
        });
    }
  };

  const onClick = (e) => {
    setActive(0);
    setOptions([]);
    setOptionbool(false);
    setUserInput(e.currentTarget.innerText);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      setActive(0);
      setOptionbool(false);
      setUserInput(options[activeOption]);
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      setActive(activeOption - 1);
    } else if (e.keyCode === 40) {
      if (activeOption === options.length - 1) {
        console.log(activeOption);
        return;
      }
      setActive(activeOption + 1);
    }
  };

  useOutsideClick(ref, () => {
    setActive(0);
    setOptions([]);
    setOptionbool(false);
    //setUserInput("");
  });

  let optionList;
  if (showOptions && userInput) {
    if (options.length) {
      optionList = (
        <ul className="options" ref={ref}>
          {options.map((optionName, index) => {
            let className;
            if (index === activeOption) {
              className = "option-active";
            }
            return (
              <li className={className} key={optionName} onClick={onClick}>
                {optionName}
              </li>
            );
          })}
        </ul>
      );
    } else {
      optionList = null;
    }
  }
  return (
    <React.Fragment>
      <StatusModal statusProps={statusProps} />
      <Form.Control
        type="text"
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={userInput}
        placeholder={props.placeholder}
        id={props.id}
      />
      <span className={loading ? "loader" : ""}></span>
      {optionList}
    </React.Fragment>
  );
}

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};
